import { IImageData } from 'src/common'
import { IBooleanDynamicPrototype, IEventVenuesPayload, ILocationQuiz, IStylePreferenceImageOptions } from 'src/types'

export const convertStylePreferenceToImage = (list: IStylePreferenceImageOptions[]): IImageData[] => {
  return list.map((item) => ({ id: item.id, url: item.imageUrl }))
}

export const convertBooleanDynamicToArray = (list: IBooleanDynamicPrototype): string[] => {
  if (!list) {
    return []
  }

  const result = []

  for (const [key, value] of Object.entries(list)) {
    if (value) {
      result.push(key)
    }
  }

  return result
}

export const convertLocationQuizToEventVenues = (locations: ILocationQuiz[]): IEventVenuesPayload[] => {
  return <IEventVenuesPayload[]>locations.map((data) => {
    const { city, state, streetAddress, zipCode, placeId, country } = data

    let venueName = data.venue
    if (!city || !state) {
      venueName = data.place
    }

    return {
      type: data.type,
      venueName,
      city,
      state,
      streetAddress,
      zipCode,
      placeId,
      country,
    }
  })
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const encodeForm = (data: any) => {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&')
}
