import React from 'react'

import { DayPickerText } from 'src/common/components/picker'
import { Button, PhoneNumber, Radio } from 'src/common'
import calendarIcon from 'src/assets/icons/calendar-icon.svg'
import TextField from 'src/common/components/TextField'
import { REFERRAL_SOURCES, STAGES } from 'src/pages/shared'
import { Dropdown } from 'src/common/components/select/Dropdown'

import { CannyValleyLayout } from '../../../../common/components/Layout'
import { Location } from '../../components/Location'
import { useCoupleInfo } from './useCoupleInfo'
import './style.scss'

const disabledDays = new Date()
disabledDays.setDate(disabledDays.getDate() + 1)

export const CoupleInfo: React.FC = () => {
  const {
    coupleInfo,
    location,
    validateError,
    loading,
    weddingDate,
    stage,
    referralSource,
    handleChangeCoupleInfo,
    handleChangeDate,
    handleBlurInput,
    handleBlurPhoneNumber,
    handleChangePhoneNumber,
    handleNext,
    handleChangeStage,
    handleChangeReferralSource,
    handleBlurSelect,
  } = useCoupleInfo()
  const { handleChange, locationSelected, handleGGPlaceSelected, hasValue: locationHasValue } = location

  const renderYourNameSection = () => {
    // if (experiment.includes(QuizExperience.LAST_NAME_HIDDEN))
    //   return (
    //     <div className="grid-wrapper mt-16px">
    //       <div className="grid-item">
    //         <TextField
    //           id="firstNameId"
    //           variant="filled"
    //           value={coupleInfo.fullName ?? ''}
    //           type="text"
    //           name="fullName"
    //           onChange={handleChangeCoupleInfo}
    //           onBlur={handleBlurInput}
    //           label="Your name"
    //           isError={!!validateError.fullName}
    //           errorMsg={validateError.fullName}
    //         />
    //       </div>
    //       <div className="grid-item" />
    //     </div>
    //   )
    return (
      <div className="grid-wrapper mt-16px">
        <div className="grid-item">
          <TextField
            id="firstNameId"
            variant="filled"
            value={coupleInfo.firstName ?? ''}
            type="text"
            name="firstName"
            onChange={handleChangeCoupleInfo}
            onBlur={handleBlurInput}
            label="First name"
            isError={!!validateError.firstName}
            errorMsg={validateError.firstName}
          />
        </div>
        <div className="grid-item">
          <TextField
            id="lastNameId"
            variant="filled"
            value={coupleInfo.lastName ?? ''}
            type="text"
            name="lastName"
            onChange={handleChangeCoupleInfo}
            onBlur={handleBlurInput}
            label="Last name"
            isError={!!validateError.lastName}
            errorMsg={validateError.lastName}
          />
        </div>
      </div>
    )
  }

  const renderPartnerNameSection = () => {
    // if (experiment.includes(QuizExperience.LAST_NAME_HIDDEN))
    //   return (
    //     <div className="grid-wrapper mt-16px">
    //       <div className="grid-item">
    //         <TextField
    //           id="firstNameId"
    //           variant="filled"
    //           value={coupleInfo.partnerFullName ?? ''}
    //           type="text"
    //           name="partnerFullName"
    //           onChange={handleChangeCoupleInfo}
    //           onBlur={handleBlurInput}
    //           label="Your partner's name"
    //           isError={!!validateError.partnerFullName}
    //           errorMsg={validateError.partnerFullName}
    //         />
    //       </div>
    //       <div className="grid-item" />
    //     </div>
    //   )
    return (
      <div className="grid-wrapper mt-16px">
        <div className="grid-item">
          <TextField
            id="partnerFirstNameId"
            variant="filled"
            value={coupleInfo.partnerFirstName ?? ''}
            type="text"
            name="partnerFirstName"
            onChange={handleChangeCoupleInfo}
            onBlur={handleBlurInput}
            label="First name"
            isError={!!validateError.partnerFirstName}
            errorMsg={validateError.partnerFirstName}
          />
        </div>
        <div className="grid-item">
          <TextField
            id="partnerLastNameId"
            variant="filled"
            value={coupleInfo.partnerLastName ?? ''}
            type="text"
            name="partnerLastName"
            onChange={handleChangeCoupleInfo}
            onBlur={handleBlurInput}
            label="Last name"
            isError={!!validateError.partnerLastName}
            errorMsg={validateError.partnerLastName}
          />
        </div>
      </div>
    )
  }

  return (
    <CannyValleyLayout className="couple-info-page-wrapper">
      <div className="couple-info-page">
        <div className="page-title">First things first.</div>
        <div className="main-info">
          <div className="quiz-question-header">What’s your name?</div>
          {renderYourNameSection()}
          <div className="quiz-question-header mt-4px">What’s your partner’s name?</div>
          {renderPartnerNameSection()}

          <div className="quiz-question-header mt-4px">What’s your wedding date?</div>
          <div className="grid-wrapper mt-16px">
            <div className="grid-item">
              <div className={`floating-label-wrapper ${coupleInfo.weddingDate ? 'has-value' : ''}`}>
                <label className="label">Wedding date</label>
                <DayPickerText
                  placeholder=""
                  inputProps={{
                    id: 'wedding-date',
                    inputMode: 'none',
                    name: 'weddingDate',
                    className: `input w-100 ${validateError.weddingDate ? 'border-red' : ''}`,
                    onBlur: handleBlurInput,
                    icon: <img src={calendarIcon} />,
                  }}
                  dayPickerProps={{
                    disabledDays: {
                      before: disabledDays,
                    },
                  }}
                  defaultValue={weddingDate?.weddingDate ? new Date(weddingDate?.weddingDate || '') : ''}
                  onDayChange={handleChangeDate}
                  format="MM/dd/yyyy"
                />
                {validateError.weddingDate ? (
                  <span className="error-helper">{validateError.weddingDate}</span>
                ) : (
                  <div className="error-helper">&nbsp;</div>
                )}
              </div>
            </div>
            <div className="grid-item" />
          </div>

          <div className="quiz-question-header mt-4px">Where are you planning to get married?</div>
          <div className="grid-wrapper mt-16px">
            <div className="grid-item">
              <div className={`floating-label-wrapper ${locationHasValue ? 'has-value' : ''}`}>
                <label className="label">Wedding location</label>
                <Location
                  handleChange={handleChange}
                  locationSelected={locationSelected}
                  handleGGPlaceSelected={handleGGPlaceSelected}
                  inputProps={{
                    showLabel: false,
                    className: `input w-100 ${validateError.location ? 'border-red' : ''}`,
                    onBlur: handleBlurInput,
                  }}
                />
                {validateError.location ? (
                  <span className="error-helper">{validateError.location}</span>
                ) : (
                  <div className="error-helper">&nbsp;</div>
                )}
              </div>
            </div>
            <div className="grid-item" />
          </div>

          <div className="quiz-question-header mt-4px">What’s your email address?</div>
          <div className="grid-wrapper mt-16px">
            <div className="grid-item">
              <TextField
                id="emailAddress"
                variant="filled"
                value={coupleInfo.emailAddress ?? ''}
                type="text"
                name="emailAddress"
                onChange={handleChangeCoupleInfo}
                onBlur={handleBlurInput}
                label="Email address"
                isError={!!validateError.emailAddress}
                errorMsg={validateError.emailAddress}
              />
            </div>
            <div className="grid-item" />
          </div>

          <div className="quiz-question-header mt-4px">What’s your phone number?</div>
          <div className="grid-wrapper mt-16px">
            <div className="grid-item">
              <TextField
                variant="filled"
                label="Phone number"
                isError={!!validateError.phoneNumber}
                errorMsg={validateError.phoneNumber}
                value={coupleInfo.phoneNumber}
                inputComponent={
                  <PhoneNumber
                    id="phone-number"
                    country="US"
                    value={coupleInfo.phoneNumber}
                    onChange={handleChangePhoneNumber}
                    onBlur={handleBlurPhoneNumber}
                    inputMode="tel"
                    className={`text-field`}
                    name="phoneNumber"
                  />
                }
              />
            </div>
            <div className="grid-item" />
          </div>
        </div>

        <div className="quiz-question-header mt-4px">Where are you at in your wedding planning process?</div>
        <div className="quiz-guest-booked mt-4px">
          <div className="stage-wrapper">
            {STAGES.map((item) => (
              <Radio
                key={item.value}
                id={item.value}
                value={item.value}
                name="planning"
                classNames={{ container: 'ml-0px mr-20px', label: 'basic' }}
                checked={stage === item.value}
                onChange={handleChangeStage}
              >
                {item.label}
              </Radio>
            ))}
          </div>
          {validateError.stage ? (
            <div className="error-helper">Please select an option.</div>
          ) : (
            <div className="error-helper">&nbsp;</div>
          )}
        </div>

        <div className="quiz-question-header mt-4px mb-8px">How did you hear about us?</div>
        <div className="quiz-guest-booked">
          <Dropdown
            className="quiz-social-dropdown"
            options={REFERRAL_SOURCES}
            placeholder="Choose one"
            value={referralSource ?? undefined}
            handleChange={handleChangeReferralSource}
            handleBlur={handleBlurSelect}
          />
          {validateError.referralSource ? (
            <div className="error-helper">Please select an option.</div>
          ) : (
            <div className="error-helper">&nbsp;</div>
          )}
        </div>

        <div className="actions">
          <Button className={`button-stem ${loading ? 'loading' : ''}`} disabled={loading} onClick={handleNext}>
            DEFINE MY STYLE
          </Button>
        </div>
      </div>
    </CannyValleyLayout>
  )
}
