import { useContext, useEffect, useMemo, useState } from 'react'
import { QuizQuestionContext, SCREEN_QUIZ_QUESTION } from '../../contextAPI'
import { SegmentAction, segmentTracking } from 'src/helpers/segment'

export const useChoosePalette = () => {
  const { quizQuestionData, setQuizScreen, setQuizData, data } = useContext(QuizQuestionContext)
  const [currentPaletteIndex, setCurrentPaletteIndex] = useState(0)
  const colorPalettes = useMemo(
    () => (quizQuestionData?.colorPalettes || []).filter((c) => c.orbConfig && c.orbConfig.layout),
    [quizQuestionData?.colorPalettes]
  )

  const handleNextPalette = () => {
    setCurrentPaletteIndex((pre) => (pre + 1) % colorPalettes.length)
  }
  const handlePrePalette = () => {
    setCurrentPaletteIndex((pre) => (pre - 1 + colorPalettes.length) % colorPalettes.length)
  }

  const handleNext = async () => {
    try {
      setQuizData({ colorPalette: colorPalettes[currentPaletteIndex], colorPreference: {} })
      setQuizScreen(SCREEN_QUIZ_QUESTION.CUSTOM_PALETTE)
      segmentTracking(SegmentAction.SQ_CV_COLOR_PALETTE_SELECTED)
    } catch (error) {}
  }

  const handleBack = () => {
    setQuizScreen(SCREEN_QUIZ_QUESTION.COUPLE_INFO)
  }

  useEffect(() => {
    const index = colorPalettes.findIndex((c) => c.id === data.colorPalette?.id)
    setCurrentPaletteIndex(index > -1 ? index : 0)
  }, [colorPalettes, data.colorPalette?.id])
  useEffect(() => {
    segmentTracking(SegmentAction.SQ_CV_SERVICEABLE)
  }, [])

  return {
    colorPalettes,
    currentPaletteIndex,
    handleNextPalette,
    handlePrePalette,
    handleNext,
    handleBack,
  }
}
