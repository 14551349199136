/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react'
import Slider from 'rc-slider'
import 'rc-slider/assets/index.css'

interface ColorSliderProps {
  color: string
  value: number
  handleChangeColorPercent: (color: string, value: number) => void
}

export const ColorSlider: React.FC<ColorSliderProps> = ({
  color,
  value,
  handleChangeColorPercent,
}: ColorSliderProps) => {
  return (
    <div className="color-slider">
      <div className="slider-wrapper" style={{ '--color': color } as any}>
        <Slider
          step={1}
          min={0}
          max={100}
          value={value}
          trackStyle={{ background: color }}
          handleStyle={{ background: color }}
          onChange={(percent) => handleChangeColorPercent(color, percent)}
        />
      </div>
    </div>
  )
}
