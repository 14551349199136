import React from 'react'

import { GorgiasChat } from 'src/common/components/embed'
import { Footer } from '../results/Footer'
import { Header } from '../results/Header'

import { Calendly, Expect, ScheduleCallHeader } from './components'
import { ConversationForm } from './components/ConversationForm'
import { useScheduleCall } from './ScheduleCall.hook'
import phoneCall from 'src/assets/images/phone-call.png'
import { Helmet } from 'react-helmet'
import { ENV } from 'src/config/env'

import './ScheduleCall.scss'

export const ScheduleCall: React.FC = () => {
  const {
    customerFullInfo,
    urlCalendly,
    showCalendly,
    isConversation,
    isConversationFromCalendly,
    handleSubmitConversation,
    handleEventScheduled,
    handleAScheduleCall,
    handleCallbackToday,
    handleSendAMessage,
  } = useScheduleCall()

  if (!customerFullInfo) return <></>

  let showCalendlyOption = <></>

  if (showCalendly === null) {
    showCalendlyOption = <>Loading...</>
  } else if (showCalendly === true || !customerFullInfo.hotlineEnabled) {
    showCalendlyOption = (
      <div>
        <Calendly
          url={urlCalendly}
          name={customerFullInfo.customerData.name}
          email={customerFullInfo.customerData.email}
          handleEventScheduled={handleEventScheduled}
          handleSendAMessage={handleSendAMessage}
        />
      </div>
    )
  } else {
    showCalendlyOption = (
      <div className="column-6 w-col w-col-6 select-call-type">
        <h3>Request a Phone Call</h3>
        <div className="designer-card">
          <div className="left-image">
            <img src={phoneCall} alt="phone-call" />
          </div>
          <div className="right-content">
            <p className="title">Sophia S</p>
            <p className="subtitle">Los Angeles, CA</p>
            <p className="description">
              Sophia has been in the floral industry for over 10 years. She is a literal walking encyclopedia of rose
              varieties (there are thousands!)
            </p>
          </div>
        </div>
        <p>
          Want to talk today? Request a phone call and Sophia will give you a call later today to talk about your
          wedding flowers and answer all of your questions about how Poppy works.
        </p>
        <div className="text-align-center">
          <button className="button button-primary full-width" onClick={handleCallbackToday}>
            Call me today
          </button>
          <button className="button button-text full-width" onClick={handleAScheduleCall}>
            Schedule for Later
          </button>
        </div>
      </div>
    )
  }

  const showContent = isConversation ? <ConversationForm handleSubmit={handleSubmitConversation} /> : showCalendlyOption

  return (
    <div className="schedule-call-wrapper">
      <Helmet>
        {/* Event snippet for Engagement (On Site) conversion page In your html page, add the snippet and call gtag_report_conversion when someone clicks on the chosen link or button. */}
        <script>
          {`function gtag_report_conversion(url) {
            var callback = function () {
              if (typeof(url) != 'undefined') {
                window.location = url;
              }
            };
            gtag('event', 'conversion', {
                'send_to': '${ENV.REACT_APP_GOOGLE_AW_CONVERSION_2}/${ENV.REACT_APP_GOOGLE_AW_CONVERSION_CLICK_CTA_BTN_ID}',
                'event_callback': callback
            });
            return false;
          }`}
        </script>
      </Helmet>
      {/* <TopShoppingWedding /> */}
      <Header />
      <div className="content-wrapper">
        <ScheduleCallHeader
          isConversation={isConversation || false}
          isConversationFromCalendly={isConversationFromCalendly || false}
        />
        <div className="column-3 w-row container mt-0px-auto">
          <Expect />
          {showContent}
        </div>
      </div>
      {/* <ExpectMobile /> */}
      {/* <ScheduleCallFooter /> */}
      <Footer />
      <GorgiasChat />
    </div>
  )
}
