export interface ISearchObject {
  session: string
  shared: string
  [k: string]: string
}

export const convertSearchParamsToSearchObject = (search: string): ISearchObject | undefined => {
  try {
    return Object.fromEntries(new URLSearchParams(search)) as ISearchObject
  } catch (error) {
    return undefined
  }
}

export const formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',

  // These options are needed to round to whole numbers if that's what you want.
  //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
  //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
})
// formatter.format(2500); /* $2,500.00 */

export const formatCurrency = (val: number) => {
  let num = val
  if (typeof val === 'string') {
    num = parseFloat(val)
  }
  if (!val) {
    num = 0
  }
  return Intl.NumberFormat('en-IN', { style: 'currency', currency: 'USD' }).format((num / 100) as number)
}
