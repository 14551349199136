import { useContext, useEffect, useMemo, useState } from 'react'
import { QuizQuestionContext, SCREEN_QUIZ_QUESTION } from '../../contextAPI'
import { Stem, StemPreferenceValue } from 'src/types'
import { SegmentAction, segmentTracking } from 'src/helpers/segment'

export type Flower = Stem & { value?: StemPreferenceValue }

export const useSampleFlower = () => {
  const { setQuizScreen, data, quizQuestionData, setQuizData } = useContext(QuizQuestionContext)
  const [flowers, setFlowers] = useState<Flower[]>([])

  const excluding = useMemo(() => flowers.filter((f) => f.value === StemPreferenceValue.EXCLUDE), [flowers])

  const handleBack = () => {
    setQuizScreen(SCREEN_QUIZ_QUESTION.FLOWERS_INTERSTITIAL)
  }
  const handleNext = () => {
    setQuizData({ stemPreferences: flowers.map((f) => ({ stemId: f.stemId, value: f.value || null })) })
    setQuizScreen(SCREEN_QUIZ_QUESTION.CANNY_VALLEY_BUDGET)
    segmentTracking(SegmentAction.SQ_CV_STEMS_COMPLETED)
  }

  const handleSelectStemPreference = (stemId: number, value: StemPreferenceValue) => {
    setFlowers((pre) => {
      const newState = [...pre]
      const index = newState.findIndex((s) => s.stemId === stemId)
      if (index > -1) newState[index] = { ...newState[index], value }
      return newState
    })
  }

  useEffect(() => {
    const initPage = () => {
      if (!data.colorPalette) return
      const colorPaletteStems = quizQuestionData?.colorPaletteStems?.filter(
        (i) => i.colorPaletteId === data.colorPalette?.id
      )
      setFlowers(
        (colorPaletteStems ?? []).map((s) => ({
          ...s,
          value: data.stemPreferences?.find((sp) => sp.stemId === s.stemId)?.value as StemPreferenceValue,
        }))
      )
    }
    initPage()
  }, [data.colorPalette, quizQuestionData?.colorPaletteStems, data.stemPreferences])

  useEffect(() => {
    segmentTracking(SegmentAction.SQ_CV_STEMS_STARTED)
  }, [])

  return {
    handleBack,
    handleNext,
    handleSelectStemPreference,
    flowers,
    excluding,
  }
}
