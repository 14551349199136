import { keyframes, css } from 'styled-components'

export const rotateKeyframes = keyframes`
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
`

export const transition = (properties: string, type = 'linear', time = '0.25s') => css`
  transition: ${time} ${type};
  transition-property: ${properties};
  will-change: ${properties};
`

const rotate = (properties = 0) => keyframes`
  from {
    transform:scale(0) rotate(${properties - 10}deg);
  }

  80%{
    transform:scale(1.2) rotate(${properties + 5}deg);
  }

  to {
    transform: rotate(${properties}deg) scale(1) ;
  }
`

export const animationFloatingLogo = (numberDeg = 0) => css`
  transform: rotate(${numberDeg}deg);
  animation: ${rotate(numberDeg)} 0.6s ease;
`
