import React from 'react'
import { CannyValleyLayout } from 'src/common/components/Layout'
import { GuestCount } from './components/GuestCount'
import { Rituals } from './components/Rituals'
import { WeddingDetails } from './components/WeddingDetails'
import { ScheduleACall } from './components/ScheduleACall'
import { PinterestBoard } from './components/PinterestBoard'
import { useCallScheduler } from './useCallScheduler'

import './style.scss'

export const CallScheduler: React.FC = () => {
  useCallScheduler()

  return (
    <CannyValleyLayout className="schedule-a-call-page">
      <div className="schedule-a-call-wrapper">
        <div className="header-title">Schedule a Call</div>
        <p className="normal-text mt-16px">We just need a few details to get your call scheduled.</p>
        <PinterestBoard />
        <GuestCount />
        <WeddingDetails />
        <Rituals />
        <ScheduleACall />
      </div>
    </CannyValleyLayout>
  )
}
