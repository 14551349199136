import React, { useCallback, useEffect, useState } from 'react'
import { Counter } from 'src/common/components/Counter'

import { postHttp } from 'src/api'
import { PATH_API } from 'src/helpers'
import { IQuizData } from 'src/pages/quiz/contextAPI'
import { useAppContext } from 'src/helpers/app'
import './styles.scss'

type WeddingDetail = {
  bridalBouquet?: number
  groomBout?: number
  bridesmaidBouquets?: number
  otherBouts?: number
  centerpieces?: number
  ceremonyArch?: number
  sweetheartTable?: number
}

const DEFAULT_DETAIL: WeddingDetail = {
  bridalBouquet: 0,
  groomBout: 0,
  bridesmaidBouquets: 0,
  otherBouts: 0,
  centerpieces: 0,
  ceremonyArch: 0,
  sweetheartTable: 0,
}

export const WeddingDetails: React.FC = () => {
  const { sessionID, quizSession } = useAppContext()
  const [weddingDetails, setWeddingDetails] = useState<WeddingDetail>()

  const handleChangeDetail = useCallback(
    async (key: string, value: number) => {
      const newDetail = { ...weddingDetails, [key]: value }
      setWeddingDetails(newDetail)
      if (!sessionID) return
      await postHttp(PATH_API.postRecordQuizResponse, {
        sessionId: sessionID,
        quizResponses: [
          {
            key: 'QUANTITY_NEED',
            values: newDetail,
          },
        ],
        eventVenues: [],
      } as IQuizData)
    },
    [sessionID, weddingDetails]
  )

  useEffect(() => {
    const setQuantity = () => {
      if (quizSession)
        Object.keys(quizSession?.event.quantityNeed || {}).length
          ? setWeddingDetails(quizSession?.event.quantityNeed)
          : setWeddingDetails(DEFAULT_DETAIL)
    }
    setQuantity()
  }, [quizSession])

  if (!weddingDetails) return <></>

  return (
    <div className="wedding-detail-section mt-30px">
      <div className="section-title">Your Wedding Details</div>
      <p className="normal-text mt-16px">
        Which of the following do you need for your wedding? If you’re not sure yet, you can skip this.
      </p>
      <div className="wedding-detail mt-20px">
        <div className="detail">
          <div className="normal-text left">Bridal Bouquet</div>
          <div className="right">
            <Counter
              min={0}
              onChange={(value) => handleChangeDetail('bridalBouquet', value)}
              defaultValue={weddingDetails?.bridalBouquet}
              debounce={800}
            />
          </div>
        </div>
        <div className="detail">
          <div className="normal-text left">Groom’s Bout</div>
          <div className="right">
            <Counter
              min={0}
              onChange={(value) => handleChangeDetail('groomBout', value)}
              defaultValue={weddingDetails?.groomBout}
              debounce={800}
            />
          </div>
        </div>
        <div className="detail">
          <div className="normal-text left">Bridesmaid Bouquets</div>
          <div className="right">
            <Counter
              min={0}
              onChange={(value) => handleChangeDetail('bridesmaidBouquets', value)}
              defaultValue={weddingDetails?.bridesmaidBouquets}
              debounce={800}
            />
          </div>
        </div>
        <div className="detail">
          <div className="normal-text left">Other Bouts</div>
          <div className="right">
            <Counter
              min={0}
              onChange={(value) => handleChangeDetail('otherBouts', value)}
              defaultValue={weddingDetails?.otherBouts}
              debounce={800}
            />
          </div>
        </div>
        <div className="detail">
          <div className="normal-text left">Centerpieces</div>
          <div className="right">
            <Counter
              min={0}
              onChange={(value) => handleChangeDetail('centerpieces', value)}
              defaultValue={weddingDetails?.centerpieces}
              debounce={800}
            />
          </div>
        </div>
        <div className="detail">
          <div className="normal-text left">Ceremony Arch</div>
          <div className="right">
            <Counter
              min={0}
              onChange={(value) => handleChangeDetail('ceremonyArch', value)}
              defaultValue={weddingDetails?.ceremonyArch}
              debounce={800}
            />
          </div>
        </div>
        <div className="detail">
          <div className="normal-text left">Sweetheart Table</div>
          <div className="right">
            <Counter
              min={0}
              onChange={(value) => handleChangeDetail('sweetheartTable', value)}
              defaultValue={weddingDetails?.sweetheartTable}
              debounce={800}
            />
          </div>
        </div>
      </div>
    </div>
  )
}
