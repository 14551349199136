/* eslint-disable @typescript-eslint/no-explicit-any */
import axios, { AxiosRequestConfig } from 'axios'
import * as Sentry from '@sentry/react'
import { Severity } from '@sentry/react'

import { ENV } from '../config/env'
/*
 * You can customize Axios settings
 * Ver mais em https://github.com/axios/axios
 */
const http = axios.create({
  baseURL: ENV.REACT_APP_API_URL,
  headers: {
    'content-type': 'application/json',
  },
})

const zapHttp = axios.create({
  baseURL: ENV.REACT_APP_ZAP_URL,
  headers: {
    accept: 'application/json',
  },
})

// Add a response interceptor
http.interceptors.response.use(
  function (response) {
    // Do something with response data
    return response
  },
  function (error) {
    // Do something with response error
    Sentry.captureMessage(error?.config?.url ?? 'API', {
      level: Severity.Error,
      contexts: {
        description: {
          ...error,
          localStorage: JSON.stringify(localStorage.getItem('sessionID')),
          description: JSON.stringify(error),
        },
      },
    })
    return Promise.reject(error.response ?? error)
  }
)

// Add a response interceptor
zapHttp.interceptors.response.use(
  function (response) {
    // Do something with response data
    return response
  },
  function (error) {
    // Do something with response error
    Sentry.captureMessage(error?.config?.url ?? 'API', {
      level: Severity.Error,
      contexts: {
        description: {
          ...error,
          localStorage: JSON.stringify(localStorage.getItem('sessionID')),
          description: JSON.stringify(error),
        },
      },
    })
    return Promise.reject(error.response ?? error)
  }
)

export const getHttp = async (url: string, config?: AxiosRequestConfig | undefined) => {
  const response = await http.get(url, config)
  return response.data
}

export const postHttp = async (url: string, data?: any, config?: AxiosRequestConfig | undefined) => {
  const response = await http.post(url, data, config)
  return response.data
}

export const postZapHttp = async (url: string, data?: any, config?: AxiosRequestConfig | undefined) => {
  const response = await zapHttp.post(url, data, config)
  return response.data
}
