import React from 'react'
import { IOption } from 'src/types'
import Select, { ActionMeta } from 'react-select'

export const dropdownStyles = {
  /* eslint-disable  @typescript-eslint/no-explicit-any */
  control: (styles: any, { menuIsOpen }: any) => ({
    ...styles,
    backgroundColor: '#000',
    paddingTop: '10px',
    borderBottomLeftRadius: menuIsOpen ? '0 !important' : '4px',
    borderBottomRightRadius: menuIsOpen ? '0 !important' : '4px',
  }),
  indicatorContainer: (styles: any) => ({
    ...styles,
    fill: ' #444041',
  }),
  valueContainer: (styles: any) => ({
    ...styles,
    paddingLeft: '14px',
  }),
  /* eslint-disable  @typescript-eslint/no-explicit-any */
  option: (styles: any, { isDisabled, isSelected, isFocused }: any) => {
    return {
      ...styles,
      backgroundColor: '#F2F1EA',
      fontFamily: 'Brandon Grotesque',
      fontSize: '16px',
      fontStyle: 'normal',
      fontWeight: '390',
      lineHeight: '24px',
      color: '#231F20',
      cursor: isDisabled ? 'not-allowed' : 'default',
      position: 'relative',
      height: '55px',
      paddingLeft: '20px',
      margin: '0 7px 0 4px',
      width: 'calc(100% - 11px)',
      display: 'flex',
      alignItems: 'center',
      borderTop: '1px solid #BDBCBC',
      ':before': {
        display: !isDisabled && (isSelected || isFocused) ? 'block' : 'none',
        content: '""',
        width: '4px',
        height: '100%',
        position: 'absolute',
        top: '0',
        left: '-4px',
        backgroundColor: '#FF9977',
      },
      ':first-child': {
        border: 'none',
      },
    }
  },
  /* eslint-disable  @typescript-eslint/no-explicit-any */
  menu: (styles: any) => {
    return {
      ...styles,
      top: '103%',
      backgroundColor: '#F2F1EA',
      margin: '0',
      boxShadow: 'none',
      border: '1px solid #BDBCBC',
      borderRadius: '4px',
      borderTopLeftRadius: '0',
      borderTopRightRadius: '0',
      paddingRight: '2px',
    }
  },
  menuList: (styles: any) => {
    return {
      ...styles,
      padding: '0',
      borderRadius: '4px',
      borderTopLeftRadius: '0',
      borderTopRightRadius: '0',
      // -webkit-overflow-scrolling: touch;
      scrollbarWidth: 'thin',
      scrollbarColor: '#c4c4c4 white',
      ':-webkit-scrollbar': {
        height: '5px',
        width: '5px',
        borderRadius: '25px',
      },
    }
  },
}

export interface IDropdownProps {
  id?: string
  options?: IOption[]
  value?: IOption
  handleChange?: (value: IOption | null, actionMeta: ActionMeta<IOption>) => void
  handleBlur?: React.FocusEventHandler
  className?: string
  placeholder?: string
}

export const Dropdown = (props: IDropdownProps) => {
  const { id, options, value, handleChange, handleBlur, className, placeholder } = props

  return (
    <Select
      id={id}
      isSearchable={true}
      components={{ IndicatorSeparator: () => null }}
      value={value ?? null}
      classNamePrefix="react-select"
      className={`select ${className ?? ''}`}
      onChange={handleChange}
      onBlur={handleBlur}
      maxMenuHeight={230}
      menuPlacement="bottom"
      placeholder={placeholder}
      options={options}
      styles={dropdownStyles}
    />
  )
}
