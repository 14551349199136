import { useContext, useMemo, useState } from 'react'
import { recordDuplicateReason } from 'src/api/customer'
import { useAppContext } from 'src/helpers/app'
import { QuizQuestionContext, SCREEN_QUIZ_QUESTION } from '../../contextAPI'
import { PATH_URL, getQueryParameters } from 'src/helpers'
import { useHistory } from 'react-router-dom'

const REASON = [
  { id: 'looking_proposal', label: 'I’m looking for my proposal', isSelected: false },
  { id: 'different_colors', label: 'I want to see different colors', isSelected: false },
  { id: 'schedule_new_event', label: 'I want to schedule a new event', isSelected: false },
]

export const useMetBefore = () => {
  const history = useHistory()
  const { setQuizScreen, recordResponse } = useContext(QuizQuestionContext)
  const { sessionID } = useAppContext()
  const [reason, setReason] = useState(REASON)
  const [additionalNotes, setAdditionalNotes] = useState('')

  const handleNext = async () => {
    setQuizScreen(SCREEN_QUIZ_QUESTION.END_QUIZ)
    const searchParams = getQueryParameters()
    history.push({ pathname: PATH_URL.QUIZ_END, search: searchParams.toString() })
    recordDuplicateReason({
      sessionId: sessionID,
      reason: reason.filter((r) => r.isSelected).map((r) => r.label),
      additionalNotes,
      proposalId: recordResponse?.proposal?.proposalId,
    })
  }

  const handleSelectedReason = (reasonId: string) => {
    setReason((pre) => {
      const newState = [...pre]
      const index = newState.findIndex((s) => s.id === reasonId)
      if (index !== -1) newState[index] = { ...newState[index], isSelected: !newState[index].isSelected }
      return newState
    })
  }

  const nextDisabled = useMemo(() => reason.every((r) => !r.isSelected), [reason])

  return {
    handleNext,
    reason,
    handleSelectedReason,
    additionalNotes,
    setAdditionalNotes,
    nextDisabled,
  }
}
