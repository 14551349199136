import React from 'react'
import { Helmet } from 'react-helmet'

import { InputBase } from 'src/common/components/input/Input'
import { PhoneNumber } from 'src/common/components/input/PhoneNumber'
import { ENV } from 'src/config/env'
import { QuizQuestionWrapper } from '../QuizQuestionWrapper'

import { useAnything, FIELD_NAMES } from './Anything.hook'

export const Anything = () => {
  const { otherInformation, pinterest, customerHelpers, handleSubmit, handleBack, handleChange } = useAnything()

  const {
    checkFullName,
    checkPhoneNumber,
    customerData,
    submitDisabled,
    handleBlurInput,
    handleBlurPhoneNumber,
    handleChangePhoneNumber,
    handleFocusInput,
  } = customerHelpers

  return (
    <>
      <Helmet>
        <script>
          {`function gtag_report_conversion(url) { var callback = function () { if (typeof(url) != 'undefined') { window.location = url; } };gtag('event', 'conversion', { 'send_to': '${ENV.REACT_APP_GOOGLE_AW_CONVERSION}/${ENV.REACT_APP_GOOGLE_AW_CONVERSION_COMPLETE_QUIZ_EVENT_ID}', 'event_callback': callback }); return false; }`}
        </script>
      </Helmet>
      <QuizQuestionWrapper
        title="final_details"
        nextAction={{
          disabled: true,
        }}
        backAction={{
          onAction: handleBack,
        }}
        customCss={{
          title: 'title-bold',
        }}
        step={8}
        total={8}
      >
        <div className="quiz-guest-anything">
          <div className="anything-content">
            <p className="fz-15rem anything-title">How can we get in touch?</p>
            <div className="mt-10px name-wrapper">
              <div className="floating-label-wrapper">
                <InputBase
                  id="fullNameId"
                  className={`w-100 ${checkFullName ? 'border-red' : ''}`}
                  type="text"
                  placeholder=""
                  name="fullName"
                  onChange={handleFocusInput}
                  onBlur={handleBlurInput}
                />
                <label className={customerData.fullName && 'filled'} htmlFor="fullNameId">
                  Full Name
                </label>
              </div>
              <div>{checkFullName && <div className="error">Invalid name</div>}</div>
            </div>
            <div className="mt-10px">
              <div className="floating-label-wrapper">
                <PhoneNumber
                  id="phoneNumberId"
                  country="US"
                  value={customerData.phoneNumber}
                  onChange={handleChangePhoneNumber}
                  onBlur={handleBlurPhoneNumber}
                  inputMode="tel"
                  className={`input w-100 ${checkPhoneNumber ? 'border-red' : ''}`}
                  placeholder=""
                  name="phoneNumber"
                />
                <label className={customerData.phoneNumber && 'filled'} htmlFor="phoneNumberId">
                  Phone Number
                </label>
              </div>
              {checkPhoneNumber && <div className="error">Invalid Phone Number</div>}
            </div>
            <p className="fz-15rem anything-title">Do you have a wedding Pinterest board?</p>
            <div className="floating-label-wrapper">
              <input
                id="pinterestId"
                type="url"
                onChange={handleChange}
                value={pinterest}
                name={FIELD_NAMES.PINTEREST}
                className="input basic field-pinterest w-100"
                placeholder=""
              />
              <label className={pinterest && 'filled'} htmlFor="pinterestId">
                Pinterest Board Link (Optional)
              </label>
            </div>

            <p className="fz-15rem anything-title">How did you hear about us?</p>
            <div className="floating-label-wrapper">
              <InputBase
                id="attributionSourceId"
                className="w-100"
                type="text"
                placeholder=""
                name="attributionSource"
                onChange={handleFocusInput}
                onBlur={handleBlurInput}
              />
              <label className={customerData.attributionSource && 'filled'} htmlFor="attributionSourceId">
                Source (Optional)
              </label>
            </div>

            <p className="fz-15rem anything-title">Any other flower dreams to share?</p>
            <div className="floating-label-wrapper">
              <InputBase
                id="otherInformationId"
                className="w-100"
                type="text"
                value={otherInformation}
                placeholder=""
                name={FIELD_NAMES.OTHER_INFORMATION}
                onChange={handleChange}
              />
              <label className={otherInformation && 'filled'} htmlFor="otherInformationId">
                Anything Else? (Optional)
              </label>
            </div>
            <button disabled={submitDisabled} className="button button-primary submit" onClick={handleSubmit}>
              SUBMIT
            </button>
          </div>
          <p className="anything-compliance">
            By signing up via text, you agree to receive recurring automated marketing text messages from Poppy Flowers
            at the cell number used when signing up. Consent is not a condition of any purchase. Msg & data rates may
            apply. Unsubscribe at anytime. See Terms & Privacy.
          </p>
        </div>
      </QuizQuestionWrapper>
    </>
  )
}
