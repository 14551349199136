import React from 'react'
import { useNormalResult } from '../../ResultsPage.hook'
import './FlowerGallery.scss'

export const FlowerGallery: React.FC = () => {
  const { customerFullInfo } = useNormalResult()
  const images = customerFullInfo?.moodboardImages || []

  images.sort((i1, i2) => i1.position - i2.position)
  const imagesToRender = images.slice(0, 12)
  const totalFlower = imagesToRender.length
  for (let i = 0; i < 12 - totalFlower; i++) {
    imagesToRender.push({
      position: totalFlower + i + 1,
      imageUrl: '',
      id: totalFlower + i,
      sourceType: 'COLOR_PALETTE',
    })
  }

  return (
    <div className="flower-gallery mt-24px">
      <div className="gallery-wrapper container mt-0px-auto">
        {imagesToRender.map((image) => (
          <figure key={`${image.id}_${image.position}`} className={`gallery-item gallery-item--${image.position}`}>
            {image.imageUrl && <img src={image.imageUrl} className="gallery-img" alt={`Image ${image.position}`}></img>}
          </figure>
        ))}
      </div>
    </div>
  )
}
