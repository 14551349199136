import { useContext, useState } from 'react'
import { ITableOption } from 'src/common/components/select'
import { QuizQuestionContext, SCREEN_QUIZ_QUESTION } from '../../contextAPI'

export const useBouquetShape = () => {
  const { data, setQuizScreen, setQuizData } = useContext(QuizQuestionContext)
  const [bouquetShapeSelected, setBouquetShapeSelected] = useState<string>(data.bouquetShape || '')

  const handleNext = () => {
    setQuizData({ bouquetShape: bouquetShapeSelected })
    setQuizScreen(SCREEN_QUIZ_QUESTION.ANYTHING)
  }

  const handleBack = () => {
    setQuizScreen(SCREEN_QUIZ_QUESTION.COLORS_FLOWERS)
  }

  const nextDisabled = !bouquetShapeSelected

  const handleSelectImage = (item: ITableOption) => {
    setBouquetShapeSelected(item.id)
  }

  return {
    bouquetShapeSelected,
    nextDisabled,
    handleSelectImage,
    handleBack,
    handleNext,
  }
}
