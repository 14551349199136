import React, { useMemo } from 'react'

import { StemPreferenceValue } from 'src/types'
import { useAppContext } from 'src/helpers/app'
import './styles.scss'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const rankOrder: any = {
  [StemPreferenceValue.LOVE]: 0,
  [StemPreferenceValue.LIKE]: 1,
  null: 2,
}
export const FlowerPreference: React.FC = () => {
  const { quizSession } = useAppContext()

  const stems = useMemo(
    () =>
      (quizSession?.stemPreferences || [])
        .filter((sp) =>
          [StemPreferenceValue.LIKE, StemPreferenceValue.LOVE, null].includes(sp.value as StemPreferenceValue)
        )
        .sort((a, b) => rankOrder[a.value || 'null'] - rankOrder[b.value || 'null']),
    [quizSession?.stemPreferences]
  )

  const excluding = useMemo(
    () => (quizSession?.stemPreferences || []).filter((sp) => sp.value === StemPreferenceValue.EXCLUDE),
    [quizSession?.stemPreferences]
  )

  return (
    <div className="flower-preference-section">
      <div className="container">
        <div className="flower-preference">
          <div className="flower-preference-wrapper">
            <div className="content">
              <div className="section-title text-white">FLOWER PREFERENCES</div>
              <div className="section-subtitle text-white mt-16px">
                The flowers you love will inspire your customized designs.
              </div>
              <p className="description text-white mt-16px">
                Your specialist will keep these preferences in mind when recommending arrangements for you.
              </p>
            </div>
            <div className="preference">
              <div className="list-stems">
                {stems[0] && (
                  <div className="item">
                    <div className="stem">
                      <img src={stems[0].stem?.imageUrl} />
                    </div>
                    <p className="stem-name text-white mt-10px">{stems[0].stem?.typeName || stems[0].stem?.name}</p>
                  </div>
                )}
                {stems[1] && (
                  <div className="item">
                    <div className="stem">
                      <img src={stems[1].stem?.imageUrl} />
                    </div>
                    <p className="stem-name text-white mt-10px">{stems[1].stem?.typeName || stems[1].stem?.name}</p>
                  </div>
                )}
              </div>
              {excluding.length && (
                <div className="flower-excluding">
                  <p className="stem-name text-white">
                    Excluding: {excluding.map((e) => e.stem?.typeName || e.stem?.name).join(', ')}
                  </p>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
