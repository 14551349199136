import React from 'react'

import arrowLeftIcon from 'src/assets/images/cannyValley/arrow-left.svg'
import closeIcon from 'src/assets/images/cannyValley/close.svg'
import { UploadDropzone } from 'src/common/components/UploadDropzone'
import { Button, HeartCircleIcon, ModalBase } from 'src/common'
import { CannyValleyLayout } from '../../../../common/components/Layout'
import { QuizStep } from '../QuizStep'
import { useInspiration } from './useInspiration'

import './style.scss'
import { isMobile } from 'src/helpers'

const ImageFile = React.memo((props: { file: File }) => <img src={URL.createObjectURL(props.file)} />)
ImageFile.displayName = 'ImageFile'

export const Inspiration: React.FC = () => {
  const {
    modalUpload,
    uploading,
    inspirationPhotos,
    handleUploadInspirationImage,
    closeModalUpload,
    handleBack,
    handleNext,
    handleChangeCation,
    uploadImageToS3,
    selectImageOption,
  } = useInspiration()
  return (
    <CannyValleyLayout className="inspiration-page-layout">
      <div className="inspiration-page">
        <div className="step-header">
          <QuizStep item={1} step={3} />
        </div>
        <div className="inspiration-content-wrapper">
          <div className="inspiration-content">
            <div className="top-actions desktop">
              <div className="back-action" onClick={handleBack}>
                <img src={arrowLeftIcon} />
                BACK
              </div>
            </div>

            <div className="inspiration-header">
              <div className="step-title-header">
                <div className="top-actions mobile">
                  <div className="back-action" onClick={handleBack}>
                    <img src={arrowLeftIcon} />
                  </div>
                </div>
                <h3 className="step-title">Save photos for inspiration</h3>
              </div>
              <p className="step-description mt-16px">
                Browse real Poppy Wedding photos and save your favorites to inspire your proposal. You can also upload
                your own inspiration images
              </p>
            </div>

            <div className="desktop mt-40px">
              <Button className="button-stem" onClick={handleNext}>
                SAVE & CONTINUE
              </Button>
            </div>
          </div>

          {isMobile ? (
            <div className="inspiration-photo-flex">
              <div className="left">
                <UploadDropzone
                  noClick
                  noKeyboard
                  accept={{ 'image/*': [] }}
                  className="dropzone-inspiration grid-item"
                  handleUploadImages={handleUploadInspirationImage}
                >
                  {({ open }) => (
                    <div className="upload-inspiration">
                      <div className="title">Show us what you like!</div>
                      <div className="description">Drag and drop files to add to your inspiration or</div>
                      <div className="upload-btn" onClick={open}>
                        Upload photos
                      </div>
                    </div>
                  )}
                </UploadDropzone>
                {inspirationPhotos.map((photo, index) => {
                  return (
                    <div key={photo.imageUrl} className="item" onClick={() => selectImageOption(index)}>
                      <div className="image-wrapper">
                        <img src={photo.imageUrl} />
                      </div>
                      <div className="image-footer">
                        <div className="icon-action">
                          <HeartCircleIcon color={photo.selected ? '#FF9977' : ''} />
                        </div>
                        <div className="caption">{photo.label}</div>
                      </div>
                    </div>
                  )
                })}
              </div>
            </div>
          ) : (
            <div className="inspiration-photo-flex">
              <div className="left">
                <UploadDropzone
                  noClick
                  noKeyboard
                  accept={{ 'image/*': [] }}
                  className="dropzone-inspiration grid-item"
                  handleUploadImages={handleUploadInspirationImage}
                >
                  {({ open }) => (
                    <div className="upload-inspiration">
                      <div className="title">Show us what you like!</div>
                      <div className="description">Drag and drop files to add to your inspiration or</div>
                      <div className="upload-btn" onClick={open}>
                        Upload photos
                      </div>
                    </div>
                  )}
                </UploadDropzone>
                {inspirationPhotos.map((photo, index) => {
                  if (index % 2 === 0) return <React.Fragment key={index} />
                  return (
                    <div key={photo.imageUrl} className="item" onClick={() => selectImageOption(index)}>
                      <div className="image-wrapper">
                        <img src={photo.imageUrl} />
                      </div>
                      <div className="image-footer">
                        <div className="icon-action">
                          <HeartCircleIcon color={photo.selected ? '#FF9977' : ''} />
                        </div>
                        <div className="caption">{photo.label}</div>
                      </div>
                    </div>
                  )
                })}
              </div>
              <div className="right">
                {inspirationPhotos.map((photo, index) => {
                  if (index % 2 !== 0) return <React.Fragment key={index} />
                  return (
                    <div key={photo.imageUrl} className="item" onClick={() => selectImageOption(index)}>
                      <div className="image-wrapper">
                        <img src={photo.imageUrl} />
                      </div>
                      <div className="image-footer">
                        <div className="icon-action">
                          <HeartCircleIcon color={photo.selected ? '#FF9977' : ''} />
                        </div>
                        <div className="caption">{photo.label}</div>
                      </div>
                    </div>
                  )
                })}
              </div>
            </div>
          )}

          <div className="mobile">
            <Button className="button-stem" onClick={handleNext}>
              SAVE & CONTINUE
            </Button>
          </div>
        </div>
      </div>
      <ModalBase
        isOpen={modalUpload.open}
        handleClose={closeModalUpload}
        classModal="modal-base modal-upload-wrapper"
        hiddenClose
      >
        <div className="modal-upload-container">
          <span className="modal-close" onClick={closeModalUpload}>
            <img src={closeIcon} />
          </span>
          <div className="upload-image-content">
            {modalUpload.images.map((image, index) => (
              <div className="upload-image" key={`${image.file.name}_${index}`}>
                <div className="image-wrapper">
                  <div className="image-item">
                    <ImageFile file={image.file} />
                  </div>
                </div>
                <div className="form-group">
                  <div className="form-item">
                    <input
                      className="input w-100"
                      type="text"
                      placeholder="Add a caption to this photo"
                      value={image.caption}
                      onChange={(e) => handleChangeCation(index, e.target.value)}
                    />
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div className="modal-footer">
            <div className="actions">
              <Button
                className={`button-stem ${uploading ? 'loading' : ''}`}
                onClick={uploadImageToS3}
                disabled={uploading}
              >
                Save
              </Button>
            </div>
          </div>
        </div>
      </ModalBase>
    </CannyValleyLayout>
  )
}
