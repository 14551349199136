import React, { useEffect } from 'react'
import { useAppContext } from 'src/helpers/app'

export const GorgiasChat = () => {
  const { appData } = useAppContext()
  const { customerFullInfo } = appData

  useEffect(() => {
    const script = document.createElement('script')
    script.id = 'gorgias-chat-widget-install'
    script.type = 'text/javascript'
    script.async = true
    script.innerHTML =
      '!function(_){_.GORGIAS_CHAT_APP_ID="1161",_.GORGIAS_CHAT_BASE_URL="us-east1-898b.production.gorgias.chat",_.GORGIAS_API_BASE_URL="config.gorgias.chat";var e=new XMLHttpRequest;e.open("GET","https://config.gorgias.chat/applications/1161",!0),e.onload=function(t){if(4===e.readyState)if(200===e.status){var n=JSON.parse(e.responseText);if(!n.application||!n.bundleVersion)throw new Error("Missing fields in the response body - https://config.gorgias.chat/applications/1161");if(_.GORGIAS_CHAT_APP=n.application,_.GORGIAS_CHAT_BUNDLE_VERSION=n.bundleVersion,n&&n.texts&&(_.GORGIAS_CHAT_TEXTS=n.texts),n&&n.sspTexts&&(_.GORGIAS_CHAT_SELF_SERVICE_PORTAL_TEXTS=n.sspTexts),!document.getElementById("gorgias-chat-container")){var o=document.createElement("div");o.id="gorgias-chat-container",document.body.appendChild(o);var r=document.createElement("script");r.setAttribute("defer",!0),r.src="https://client-builds.production.gorgias.chat/{bundleVersion}/static/js/main.js".replace("{bundleVersion}",n.bundleVersion),document.body.appendChild(r)}}else console.error("Failed request GET - https://config.gorgias.chat/applications/1161")},e.onerror=function(_){console.error(_)},e.send()}(window||{});'
    document.body.appendChild(script)

    return () => {
      document.body.removeChild(script)
    }
  }, [])

  useEffect(() => {
    const gorgiasChatInterval = window.setInterval(function () {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const windowGlobal = window as any
      if (
        windowGlobal.GorgiasChat &&
        windowGlobal.GorgiasChat.hasOwnProperty('captureUserEmail') &&
        customerFullInfo?.customerData.email
      ) {
        window.clearInterval(gorgiasChatInterval) // this line breaks out of the loop - make sure it's not deleted.
        windowGlobal.GorgiasChat.captureUserEmail(customerFullInfo.customerData.email)
      }
    }, 100)

    return () => {
      window.clearInterval(gorgiasChatInterval)
    }
  }, [customerFullInfo?.customerData.email])

  return <></>
}
