import React, { useRef, useState } from 'react'
import { isMobile } from 'src/helpers'
import useOnClickOutside from 'src/helpers/hooks/useOnClickOutside'
import { ReactComponent as QuestionIcon } from 'src/assets/icons/question_icon.svg'
import { ReactComponent as CloseXIcon } from 'src/assets/icons/close-x-icon.svg'

interface WeddingDetailTooltipProps {
  title: string
  text: string
}

export const WeddingDetailTooltip = ({ title, text }: WeddingDetailTooltipProps) => {
  const [open, setOpen] = useState<boolean>(false)
  const onOpen = () => {
    setOpen(true)
  }
  const onClose = () => {
    setOpen(false)
  }
  const popupRef = useRef<HTMLDivElement>(null)
  useOnClickOutside([popupRef], () => onClose())
  return (
    <>
      {!isMobile && (
        <div className="question-info">
          <QuestionIcon />
        </div>
      )}
      {isMobile && (
        <div className="question-info-mobile" onClick={onOpen}>
          <QuestionIcon />
        </div>
      )}
      {isMobile && open && (
        <div ref={popupRef} className="wedding-detail-tooltip is-mobile">
          <CloseXIcon className="btn-close" onClick={onClose} />
          <h4 className="title">{title}</h4>
          {text && <p className="text">{text}</p>}
        </div>
      )}
      {!isMobile && (
        <div ref={popupRef} className="wedding-detail-tooltip">
          <CloseXIcon className="btn-close" onClick={onClose} />
          <h4 className="title">{title}</h4>
          {text && <p className="text">{text}</p>}
        </div>
      )}
    </>
  )
}
