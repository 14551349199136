import React from 'react'
import { ImageWithLogoLazy } from 'src/common'
import { IImageFireWork } from 'src/config/fireworkConfig'

interface IGalleryMobileProps {
  imageFirework: IImageFireWork
}

export const GalleryMobile: React.FC<IGalleryMobileProps> = (props: IGalleryMobileProps) => {
  const {
    mobileBouquetLeft,
    mobileBouquetTop,
    mobileBouquetBottom,
    mobileCeremonyLeft,
    mobileCeremonyRight,
    mobileReceptionTop,
    mobileReceptionBottom,
    mobileReceptionMain,
  } = props.imageFirework

  return (
    <div className="gallery gallery-mobile">
      <div className="w-layout-grid grid-21">
        <div className="div-block-161 area">
          <ImageWithLogoLazy
            src={mobileBouquetLeft}
            loading="lazy"
            id="mobileBouquetLeft"
            alt=""
            className="image-88"
          />
        </div>
        <div className="div-block-161 start-end-span-1">
          <ImageWithLogoLazy src={mobileBouquetTop} loading="lazy" id="mobileBouquetTop" alt="" className="image-88" />
        </div>
        <div className="div-block-161">
          <ImageWithLogoLazy
            src={mobileBouquetBottom}
            loading="lazy"
            id="mobileBouquetBottom"
            alt=""
            className="image-88"
          />
        </div>
      </div>
      <div className="w-layout-grid grid-21">
        <div className="div-block-161">
          <ImageWithLogoLazy
            src={mobileCeremonyLeft}
            loading="lazy"
            id="mobileCeremonyLeft"
            alt=""
            className="image-88"
          />
        </div>
        <div className="div-block-161">
          <ImageWithLogoLazy
            src={mobileCeremonyRight}
            loading="lazy"
            id="mobileCeremonyRight"
            alt=""
            className="image-88"
          />
        </div>
      </div>
      <div className="w-layout-grid grid-21-copy">
        <div className="div-block-161">
          <ImageWithLogoLazy
            src={mobileReceptionTop}
            loading="lazy"
            id="mobileReceptionTop"
            alt=""
            className="image-88"
          />
        </div>
        <div className="div-block-161">
          <ImageWithLogoLazy
            src={mobileReceptionBottom}
            loading="lazy"
            id="mobileReceptionBottom"
            alt=""
            className="image-88"
          />
        </div>
        <div className="div-block-161 area-2">
          <ImageWithLogoLazy
            src={mobileReceptionMain}
            loading="lazy"
            id="mobileReceptionMain"
            alt=""
            className="image-88"
          />
        </div>
      </div>
    </div>
  )
}
