import React from 'react'
import { H2, Button, StarRating } from 'src/common'
import { ModalStyle } from 'src/common/components/modal/ModalStyle'

import messageLove from 'src/assets/images/message_love.png'

import { EXPERIENCE_POPUP_TYPE, useExperiencePopup } from './ExperiencePopup.hook'

import './ExperiencePopup.scss'

export interface IExperiencePopupProps {
  triggerElmId: string
  templateId?: number
}

export const ExperiencePopup = (props: IExperiencePopupProps) => {
  const {
    showExperiencePopup,
    popupType,
    messageRef,
    handleSubmit,
    handleSelectRating,
    handleClosePopup,
  } = useExperiencePopup(props)

  if (!showExperiencePopup) {
    return <></>
  }

  const classNamePopup = popupType === EXPERIENCE_POPUP_TYPE.RATING ? 'budget-warning' : popupType

  return (
    <ModalStyle
      className={`experience-popup ${classNamePopup}`}
      isOpen={showExperiencePopup}
      handleClose={handleClosePopup}
    >
      {popupType === EXPERIENCE_POPUP_TYPE.RATING && (
        <div className="warning-wrapper">
          <div className="pin-logo">
            <img src={messageLove} alt="message_love" />
          </div>
          <div className="warning-content">
            <H2>Hey You!</H2>
            <div className="fz-15rem mb-20rem">Do you like your Poppy wedding flowers?</div>
            <div className="">
              <StarRating onChange={handleSelectRating} />
            </div>
          </div>
        </div>
      )}
      {popupType === EXPERIENCE_POPUP_TYPE.MESSAGES && (
        <div className="warning-wrapper">
          <div className="warning-content">
            <H2>Tell Us More.</H2>
            <div className="fz-15rem mb-20rem">
              <textarea className="message-field" placeholder="What do you wish was different?" ref={messageRef} />
            </div>
            <div className="">
              <Button className="button button-basic button-submit mlr-10rem mb-10rem" onClick={() => handleSubmit()}>
                submit
              </Button>
            </div>
          </div>
        </div>
      )}
      {popupType === EXPERIENCE_POPUP_TYPE.RESULT && (
        <div className="warning-wrapper">
          <div className="warning-content">
            <H2>Thank You.</H2>
            <div className="fz-15rem mb-20rem">Thanks for your feedback.</div>
          </div>
        </div>
      )}
    </ModalStyle>
  )
}
