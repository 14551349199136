import React from 'react'
import './LoadingSpinner.scss'

type LoadingSpinnerProps = {
  className?: string
  size?: 'xs' | 'sm' | 'md' | 'lg'
}

export const LoadingSpinner: React.FC<LoadingSpinnerProps> = ({ className, size = 'xs' }: LoadingSpinnerProps) => {
  return (
    <div className={`loading-spinner ${className ?? ''} ${size ?? ''}`}>
      <div className="loading-icon" />
    </div>
  )
}
