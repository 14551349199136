import React, { useState } from 'react'

import { CSSTransition } from 'react-transition-group'

import highResLogo from 'src/assets/images/high-res-logo.png'
import menuIcon from 'src/assets/images/menu-icon.png'
import highResLogo500 from 'src/assets/images/high-res-logo-p-500.png'
import highResLogo800 from 'src/assets/images/high-res-logo-p-800.png'
import logoM from 'src/assets/images/logo-m.svg'

import './NavigationHeader.scss'

const homeRedirect = 'https://www.poppyflowers.com'

export const NavigationHeader = () => {
  const [collapse, setCollapse] = useState(false)

  const handleCollapse = () => {
    setCollapse(!collapse)
  }

  return (
    <div data-collapse="medium" className="navigation w-nav">
      <div className="navigation-wrap">
        <a href={`${homeRedirect}/`} className="logo-link w-nav-brand" aria-label="home">
          <img
            src={highResLogo}
            sizes="190px"
            srcSet={`${highResLogo500} 500w, ${highResLogo800} 800w, ${highResLogo} 1368w`}
            alt=""
            className="logo-image"
          />
          <img src={logoM} loading="lazy" alt="" />
        </a>
        <div className="menu">
          <nav
            role="navigation"
            className="navigation-items w-nav-menu"
            style={{ transform: 'translateY(0px) translateX(0px)' }}
          >
            <a href={`${homeRedirect}/`} className="navigation-item w-nav-link">
              Home
            </a>
            <a href={`${homeRedirect}/weddings`} className="navigation-item w-nav-link">
              Weddings
            </a>
            <a href={`${homeRedirect}/weddings-faq`} className="navigation-item w-nav-link">
              FAQs
            </a>
            <a href={`${homeRedirect}/looks`} className="navigation-item w-nav-link">
              Lookbook
            </a>
            <a href={`${homeRedirect}/about`} className="navigation-item w-nav-link">
              About
            </a>
          </nav>
          <div
            className="menu-button w-nav-button"
            style={{ WebkitUserSelect: 'text' }}
            aria-label="menu"
            role="button"
            tabIndex={0}
            onClick={handleCollapse}
            aria-controls="w-nav-overlay-0"
            aria-haspopup="menu"
            aria-expanded="false"
          >
            <img src={menuIcon} width={22} alt="" className="menu-icon inverted" />
          </div>
        </div>
      </div>
      <div className={`w-nav-overlay open`} data-wf-ignore id="w-nav-overlay-0">
        <CSSTransition
          in={collapse}
          timeout={300}
          classNames="navigation-collapse"
          unmountOnExit
          onEnter={() => setCollapse(true)}
          onExited={() => setCollapse(false)}
        >
          <nav role="navigation" className="navigation-items w-nav-menu" data-nav-menu-open>
            <a href={`${homeRedirect}/`} className="navigation-item w-nav-link w--nav-link-open">
              Home
            </a>
            <a href={`${homeRedirect}/weddings`} className="navigation-item w-nav-link w--nav-link-open">
              Weddings
            </a>
            <a href={`${homeRedirect}/weddings-faq`} className="navigation-item w-nav-link w--nav-link-open">
              FAQs
            </a>
            <a href={`${homeRedirect}/looks`} className="navigation-item w-nav-link w--nav-link-open">
              Lookbook
            </a>
            <a href={`${homeRedirect}/about`} className="navigation-item w-nav-link w--nav-link-open">
              About
            </a>
          </nav>
        </CSSTransition>
      </div>
    </div>
  )
}
