/* eslint-disable @typescript-eslint/no-explicit-any */
import moment from 'moment'
import { useEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom'

import { convertSearchParamsToSearchObject, PATH_URL } from 'src/helpers'
import { ICustomerFullInfo, useAppContext } from 'src/helpers/app'
import { getCustomerInfo } from 'src/api/customer'
import { SegmentAction, segmentTracking } from 'src/helpers/segment'

const renderUrgencyText = (customerFullInfo: ICustomerFullInfo) => {
  const date = `${moment(customerFullInfo?.customerData.eventDate).format('M/DD/YYYY')}`

  const { countMarketBook, marketName } = customerFullInfo?.customerData

  const textCustomer = countMarketBook > 1 ? `${countMarketBook} customers are` : `${countMarketBook} customer is`

  const result = countMarketBook
    ? `${textCustomer} looking at ${date} in the ${marketName} area.`
    : 'Wedding dates are booking up fast!'

  return result
}

export const useNormalResult = () => {
  const location = useLocation()
  const history = useHistory()
  const { appData, handleChangeAppData } = useAppContext()
  const { customerFullInfo } = appData

  const handleScheduleACall = (segmentParams: any = {}) => {
    segmentTracking(SegmentAction.SQ_SCHEDULE_CALL_CLICKED, segmentParams)
    handleChangeAppData({ isConversation: false })
    history.push(`${PATH_URL.SCHEDULE_YOUR_CONSULTATION}${location.search}`)
  }

  const handleGetInTouch = (segmentParams: any = {}) => {
    segmentTracking(SegmentAction.SQ_SEND_MESSAGE_CLICKED, segmentParams)
    handleChangeAppData({ isConversation: true })
    history.push(`${PATH_URL.SCHEDULE_YOUR_CONSULTATION}${location.search}`)
  }

  let infoProposal
  if (customerFullInfo) {
    infoProposal = {
      nameTitle: `Congratulations, ${customerFullInfo?.customerData.name ?? 'Guest'}!`,
      styleText: customerFullInfo.customerData.styleText,
      templateConfig: customerFullInfo.templateConfig,
      urgencyText: renderUrgencyText(customerFullInfo),
    }
  }

  return {
    handleScheduleACall,
    handleGetInTouch,
    customerFullInfo: appData.customerFullInfo,
    infoProposal,
    isCustomer1000: appData.isCustomer1000,
  }
}

export const useResultsPage = () => {
  const location = useLocation<{ from: string }>()
  const { appData, handleChangeAppData, setSessionID } = useAppContext()
  const searchObject = convertSearchParamsToSearchObject(location.search)
  const { handleGetInTouch, handleScheduleACall, infoProposal } = useNormalResult()

  useEffect(() => {
    const initFireworkConfig = async () => {
      if (!appData.customerFullInfo && searchObject?.session) {
        if (location?.state?.from !== 'quiz-question') {
          handleChangeAppData({ countLoading: 1 })
        }
        try {
          const customerInfoResponse = await getCustomerInfo(searchObject.session)
          handleChangeAppData({
            customerFullInfo: customerInfoResponse,
            isCustomer1000: customerInfoResponse.customerData.budget <= 1000,
          })
          setSessionID(searchObject.session)
          const ctaConfigs = customerInfoResponse?.ctaConfigs || ['START_CONVERSATION']
          segmentTracking(SegmentAction.SQ_RESULTS_LOADED, {
            primaryCta: customerInfoResponse.primaryCTAType === 'BOOK_NOW' ? 'BOOK_NOW' : ctaConfigs[0],
          })
        } catch (error) {
          console.error('initFireworkConfig', error)
        }
        if (location?.state?.from === 'quiz-question') {
          handleChangeAppData({ countLoadingTestimonial: -1 })
        } else {
          handleChangeAppData({ countLoading: -1 })
        }
      }
    }

    initFireworkConfig()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return {
    handleScheduleACall,
    handleGetInTouch,
    searchObject,
    customerFullInfo: appData.customerFullInfo,
    infoProposal,
    isCustomer1000: appData.isCustomer1000,
  }
}
