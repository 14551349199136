import React, { useEffect, useState } from 'react'

import { useDebounce } from 'src/helpers/hooks/useDebounce'
import './styles.scss'

type CounterProps = {
  min?: number
  max?: number
  defaultValue?: number
  onChange?: (value: number) => void
  debounce?: number
}

export const Counter: React.FC<CounterProps> = ({ min, max, defaultValue, debounce = 0, onChange }: CounterProps) => {
  const [counter, setCounter] = useState<string>(defaultValue?.toString() || '')
  const debouncedCounter = useDebounce(counter, debounce)

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCounter(event.target.value)
  }
  const increment = () => {
    setCounter((pre) => {
      const newValue = typeof max === 'number' ? Math.min(+pre + 1, max) : +pre + 1
      return newValue.toString()
    })
  }
  const decrement = () => {
    setCounter((pre) => {
      const newValue = typeof min === 'number' ? Math.max(+pre - 1, min) : +pre - 1
      return newValue.toString()
    })
  }
  const handleBlur = () => {
    if (typeof min === 'number' && +counter < min) setCounter(min.toString())
    if (typeof max === 'number' && +counter > max) setCounter(max.toString())
  }

  useEffect(() => {
    if (+counter !== defaultValue) onChange?.(+counter)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedCounter])

  return (
    <div className="counter">
      <div className="action" onClick={decrement}>
        -
      </div>
      <div className="counter-input">
        <input type="number" value={counter} onChange={handleChange} onBlur={handleBlur} />
      </div>
      <div className="action" onClick={increment}>
        +
      </div>
    </div>
  )
}
