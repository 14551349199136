import React from 'react'

import Input from 'react-phone-number-input/input'
import { PhoneInputProps, isValidPhoneNumber } from 'react-phone-number-input'

export type IPhoneNumberProps = Partial<PhoneInputProps> & {
  value?: string
  withCountryCallingCode?: boolean
}

export const PhoneNumber: React.FC<IPhoneNumberProps> = (props: IPhoneNumberProps) => {
  return <Input {...props} />
}

export { isValidPhoneNumber }
