import FacebookPixel, { AdvancedMatching } from 'react-facebook-pixel'

import { ENV } from 'src/config/env'

export enum EVENTS_FB_PIXEL {
  QUIZ2COMPLETE = 'quiz2Complete',
}

const advancedMatching = {} as AdvancedMatching // optional, more info: https://developers.facebook.com/docs/facebook-pixel/advanced/advanced-matching

const options = {
  autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
  debug: false, // enable logs
}

console.log('initFbPixel')
console.log(ENV.REACT_APP_FACEBOOK_PIXEL_ID)

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const fbEvent = (title: EVENTS_FB_PIXEL, data?: any) => {
  // For tracking custom events. More info about custom events: https://developers.facebook.com/docs/facebook-pixel/implementation/conversion-tracking#custom-events
  FacebookPixel.trackCustom(title, data)
}

export const init = () => {
  console.log('initCode')
  FacebookPixel.init(ENV.REACT_APP_FACEBOOK_PIXEL_ID, advancedMatching, options)
  FacebookPixel.pageView()
}

export default FacebookPixel
