import React from 'react'
import { GalleryDesktop, GalleryMobile } from 'src/common/components/gallery'
// import { AsSeenIn } from '../results/AsSeenIn'
import { useCallScheduled } from './CallScheduled.hook'

// import logoImg from 'src/assets/images/logo.png'

import './CallScheduled.scss'
import { GorgiasChat } from 'src/common/components/embed'
import { SalesPerson } from './components/SalesPerson'
import { Header } from '../results/Header'
import { Footer } from '../results/Footer'
import { REDIRECT_PATH } from 'src/helpers'

export const CallScheduled: React.FC = () => {
  const { infoProposal, textShared, isRegularPage, scheduledEventInfo, handleCopy } = useCallScheduled()

  if (!infoProposal) return <></>

  const { nameTitle, styleText, templateConfig } = infoProposal

  return (
    <div className="call-scheduled">
      <Header />
      {scheduledEventInfo && <SalesPerson salesPerson={scheduledEventInfo?.salesPerson} />}
      <div className={`section-42 ${isRegularPage ? '' : 'pt-0px pb-0px'}`}>
        <div className="container-31 w-container">
          {/* <img src={logoImg} loading="lazy" alt="" className="image-87" /> */}
          {isRegularPage && (
            <>
              <div id="wellTalkSoonHeader" className="div-block-172">
                <h1 className="heading-85">We'll Talk Soon</h1>
                <div className="text-block-95">
                  {window.location.href.includes('&msg') ? (
                    `Our floral experts have received your email! Our team usually replies within a few hours.`
                  ) : (
                    <>
                      <p>
                        Our floral experts have everything they need to get ready for your call. Our team usually calls
                        back within an hour.
                      </p>
                      <p>Be on the lookout for a call from a 202 phone number.</p>
                    </>
                  )}
                </div>
                <div className="div-block-166" />
              </div>
            </>
          )}
        </div>
      </div>
      <div className="section-37">
        <div className="sneak-peek-desktop container mt-0px-auto">
          <div className="div-block-152">
            {/* <img src={logoImg} loading="lazy" alt="" className="image-87" /> */}
            <h2 id="nameHeading" className="heading-79">
              {nameTitle}
            </h2>
            <div id="styleText" className="text-block-91 mobile-hidden">
              {styleText}
            </div>
            {isRegularPage && (
              <button
                id="shareButtonDesktop"
                type="button"
                className="button button-basic button-copy left-align hidden-on-mobile w-button"
                onClick={handleCopy}
              >
                {textShared}
              </button>
            )}
          </div>
          <GalleryDesktop imageFirework={templateConfig} />
        </div>
        <div className="lookbook-wrapper container  mt-0px-auto">
          <a className="lookbook" target="_blank" rel="noreferrer" href={REDIRECT_PATH.LOOK_BOOK}>
            See past Poppy Weddings in our Lookbook
          </a>
        </div>
      </div>
      <div className="section-38">
        <div className="container-29 container mt-0px-auto">
          <h2 id="mobileNameHeading" className="heading-79">
            {nameTitle}
          </h2>
          <div id="mobileStyleText" className="hidden-on-desktop">
            {styleText}
          </div>
          <GalleryMobile imageFirework={templateConfig} />
          {isRegularPage && (
            <button
              id="shareButtonMobile2"
              type="button"
              className="button button-basic button-copy left-align w-button"
              onClick={handleCopy}
            >
              {textShared}
            </button>
          )}

          <div>
            <a className="lookbook" target="_blank" rel="noreferrer" href={REDIRECT_PATH.LOOK_BOOK}>
              See past Poppy Weddings in our Lookbook
            </a>
          </div>
        </div>
      </div>

      {/* <AsSeenIn /> */}
      <Footer />
      <GorgiasChat />
    </div>
  )
}
