import { ENV } from 'src/config/env'
import { ICustomerFullInfo } from 'src/helpers/app'
import { EVENT_LOCATION_TYPE, IObjectDynamicPrototype } from 'src/types'
import { IProductOptions } from 'src/types/product'
import { ICart } from './context'

export enum NAME_OPTION_CODE {
  BOUTS = 'BOUTS',
  BRIDESMAIDS_BOUQUET = 'BRIDESMAIDS_BOUQUET',
  BRIDAL_BOUQUET = 'BRIDAL_BOUQUET',
}

export const nameOption: IObjectDynamicPrototype = {
  [NAME_OPTION_CODE.BOUTS]: 'Boutonniere',
  [NAME_OPTION_CODE.BRIDESMAIDS_BOUQUET]: 'Bridesmaid Bouquet',
  [NAME_OPTION_CODE.BRIDAL_BOUQUET]: 'Bridal Bouquet',
}

export const handleShowNameOption = (unitType: string) => {
  const name = nameOption[unitType]
  return name ?? unitType
}

export const sortProduct = (products: IProductOptions): IProductOptions => {
  return {
    [NAME_OPTION_CODE.BRIDAL_BOUQUET]: products[NAME_OPTION_CODE.BRIDAL_BOUQUET],
    [NAME_OPTION_CODE.BRIDESMAIDS_BOUQUET]: products[NAME_OPTION_CODE.BRIDESMAIDS_BOUQUET],
    [NAME_OPTION_CODE.BOUTS]: products[NAME_OPTION_CODE.BOUTS],
  }
}

export interface IExtraDataCheckout {
  pickupInfo: string
}

export const getURLCheckout = (carts: ICart, customerInfo?: ICustomerFullInfo, extraData?: IExtraDataCheckout) => {
  const cartArr = Object.values(carts)

  const productCheckoutArr: string[] = []

  cartArr.forEach((item) => {
    if (item.quantity > 0) {
      productCheckoutArr.push(`${item.product.shopifyVariantId}:${item.quantity}`)
    }
  })

  const customerDataArr: string[] = []
  if (customerInfo) {
    customerDataArr.push(`checkout[email]=${customerInfo.customerData.email}`)
    customerDataArr.push(`checkout[shipping_address][last_name]=${customerInfo.customerData.lastName}`)
    customerDataArr.push(`checkout[shipping_address][first_name]=${customerInfo.customerData.name}`)
    customerDataArr.push(`checkout[shipping_address][phone]=${customerInfo.customerData.phoneNumber}`)

    const location = customerInfo.customerData.locations.find((item) => item.type === EVENT_LOCATION_TYPE.CEREMONY)

    if (location) {
      customerDataArr.push(`checkout[shipping_address][province]=${location.state}`)
      customerDataArr.push(`checkout[shipping_address][city]=${location.city}`)
    }

    if (extraData) {
      customerDataArr.push(`attributes[pickup_info]=${extraData.pickupInfo}`)
    }
  }

  return `${ENV.REACT_APP_SHOPIFY_URL}/cart/${productCheckoutArr.join(',')}?${customerDataArr.join('&')}`
}
