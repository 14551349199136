import React from 'react'

import textLogoBlack from 'src/assets/images/text_logo_black.png'
import tweeterIcon from 'src/assets/images/tweeter_icon.png'
import instagramIcon from 'src/assets/images/instagram_icon.png'
import facebookIcon from 'src/assets/images/facebook_icon.png'
import linkedinIcon from 'src/assets/images/linkedin_icon.png'
import './FooterStyle.scss'
import { REDIRECT_PATH } from 'src/helpers'

export const Footer = () => {
  return (
    <div className="section-footer">
      <div className="section-footer-wrapper container mt-0px-auto">
        <div className="section-left">
          <div className="footer-logo">
            <img src={textLogoBlack} alt="footer logo" />
          </div>
          <div className="footer-menu">
            <div className="menu-1">
              <ul>
                <li>
                  <a href={REDIRECT_PATH.WEDDINGS}>Book Poppy Weddings</a>
                </li>
                <li>
                  <a href={REDIRECT_PATH.SHOP}> Shop Poppy at Home </a>
                </li>
                <li>
                  <a href={REDIRECT_PATH.DIY}>Tips for Arranging at Home </a>
                </li>
                <li>
                  <a href={REDIRECT_PATH.FAQS}>FAQs</a>
                </li>
                <li>
                  <a href={REDIRECT_PATH.FLORIST}>For Florists</a>
                </li>
                <li>
                  <a href={REDIRECT_PATH.FLORIST}>For Wedding Planners</a>
                </li>
              </ul>
            </div>
            <div className="menu-2">
              <ul>
                <li>
                  <a href={REDIRECT_PATH.PRESS}>Press</a>
                </li>
                <li>
                  <a href={REDIRECT_PATH.CORPORATE}>Corporate Events</a>
                </li>
                <li>
                  <a href={REDIRECT_PATH.PRIVACY}>Privacy Policy</a>
                </li>
                <li>
                  <a href={REDIRECT_PATH.REFUNDS}>Shipping and Refund Policy</a>
                </li>
                <li>
                  <a href={REDIRECT_PATH.TERMS}>Terms of Service</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="section-right">
          <h3 className="footer-title">Keep in Touch</h3>
          <p className="footer-para">
            Sign up to win a free Poppy At Home Kit! We’re giving away a free Poppy At Home kit every month because
            flowers make people happy.
          </p>
          <div className="subscribe-box">
            <input type="text" placeholder="Your email address" />
            <button>subscribe</button>
          </div>
          <div className="follow-icons">
            <a>
              <img src={tweeterIcon} alt="tweeter icon" />
            </a>
            <a>
              <img src={instagramIcon} alt="instagram icon" />
            </a>
            <a>
              <img src={facebookIcon} alt="facebook icon" />
            </a>
            <a>
              <img src={linkedinIcon} alt="linkedin icon" />
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}
