import { useMemo, useRef, useState } from 'react'

import { validate } from 'src/helpers'
import { IBooleanDynamicPrototype, ICustomer } from 'src/types'

import { isValidPhoneNumber } from 'src/common'

export const useCustomer = () => {
  const [customerData, setCustomerData] = useState<ICustomer>({
    fullName: '',
    firstName: '',
    lastName: '',
    emailAddress: '',
    phoneNumber: '',
    attributionSource: '',
  })
  const phoneRef = useRef('')
  const [focused, setFocused] = useState<IBooleanDynamicPrototype>({})

  const handleChangeValueInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target

    setCustomerData((data) => ({ ...data, [name]: value }))
  }

  const handleFocusInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target
    setCustomerData((data) => ({ ...data, [name]: value }))
  }

  const handleBlurInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name } = event.target

    setFocused((data) => ({ ...data, [name]: true }))
  }

  const handleChangePhoneNumber = (value: string) => {
    setCustomerData((data) => ({ ...data, phoneNumber: value }))
  }

  const handleBlurPhoneNumber = () => {
    setFocused((data) => ({ ...data, phoneNumber: true }))
  }

  const isEmailError = useMemo(() => !validate.email((customerData.emailAddress ?? '').trim()), [
    customerData.emailAddress,
  ])
  const isPhoneNumberError = useMemo(() => !isValidPhoneNumber((customerData.phoneNumber ?? '').trim()), [
    customerData.phoneNumber,
  ])
  const isFullNameError = !validate.leastOneLetter(customerData.fullName || '')
  const isCustomerError = isFullNameError

  const submitDisabled = isCustomerError || isPhoneNumberError

  const checkEmail = focused['emailAddress'] && isEmailError
  const checkPhoneNumber = focused['phoneNumber'] && isPhoneNumberError
  const checkFullName = focused['fullName'] && isFullNameError

  return {
    isEmailError,
    isPhoneNumberError,
    isFullNameError,
    checkEmail,
    checkPhoneNumber,
    checkFullName,
    customerData,
    submitDisabled,
    phoneRef,
    focused,
    handleBlurInput,
    handleBlurPhoneNumber,
    handleChangePhoneNumber,
    handleChangeValueInput,
    handleFocusInput,
  }
}
