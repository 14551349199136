import { useContext } from 'react'
import { ENV } from 'src/config/env'
import { QuizQuestionContext } from '../../contextAPI'

export const useGuineaPig = () => {
  const { redirectUrl: url } = useContext(QuizQuestionContext)
  const handleNext = async (type: number) => {
    if (type === 1) {
      window.location.href = ENV.REACT_APP_GUINEA_PIG_REDIRECT_URL
    } else {
      window.location.href = url || 'https://www.poppyflowers.com'
    }
  }

  return {
    handleNext,
    nextDisabled: false,
  }
}
