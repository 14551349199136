import React from 'react'
import { ColorPalette } from 'src/types'
import './paletteImage.scss'

interface PaletteImageProps {
  palette?: ColorPalette
}

/**
 * layoutA, layoutB: 2 circle color
 * layoutC, layoutD: 4 circle color
 * layoutE, layoutF: 3 circle color
 * layoutG, layoutH: 6 circle color
 */

export const PaletteImage: React.FC<PaletteImageProps> = ({ palette }: PaletteImageProps) => {
  const renderColorCircle = (index: number) => {
    switch (palette?.orbConfig?.layout) {
      case 'layoutA':
      case 'layoutB':
        if (index === 1) return palette.orbConfig.orb_colors?.[0]
        if (index === 3) return palette.orbConfig.orb_colors?.[1]
        return 'none'
      case 'layoutC':
      case 'layoutD':
        if (index === 1) return palette.orbConfig.orb_colors?.[0]
        if (index === 3) return palette.orbConfig.orb_colors?.[1]
        if (index === 5) return palette.orbConfig.orb_colors?.[2]
        if (index === 6) return palette.orbConfig.orb_colors?.[3]
        return 'none'
      case 'layoutE':
      case 'layoutF':
        if (index === 1) return palette.orbConfig.orb_colors?.[0]
        if (index === 3) return palette.orbConfig.orb_colors?.[1]
        if (index === 5) return palette.orbConfig.orb_colors?.[2]
        return 'none'
      case 'layoutG':
      case 'layoutH':
        return palette.orbConfig.orb_colors?.[index - 1]
      default:
        return ''
    }
  }

  if (!palette) return <></>

  return (
    <div className={`palette-image-container ${palette?.orbConfig?.layout || 'layoutA'}`}>
      <div className="palette-image">
        <div className="circle image --01">
          <img src={palette?.orbConfig.imageUrls?.[0]} />
        </div>
        <div className="circle image --02">
          <img src={palette?.orbConfig.imageUrls?.[1]} />
        </div>
        <div className="circle image --03">
          <img src={palette?.orbConfig.imageUrls?.[2]} />
        </div>
        <div className="circle image --04">
          <img src={palette?.orbConfig.imageUrls?.[3]} />
        </div>
        <div className="circle color --01" style={{ backgroundColor: renderColorCircle(1) }} />
        <div className="circle color --02" style={{ backgroundColor: renderColorCircle(2) }} />
        <div className="circle color --03" style={{ backgroundColor: renderColorCircle(3) }} />
        <div className="circle color --04" style={{ backgroundColor: renderColorCircle(4) }} />
        <div className="circle color --05" style={{ backgroundColor: renderColorCircle(5) }} />
        <div className="circle color --06" style={{ backgroundColor: renderColorCircle(6) }} />
      </div>
    </div>
  )
}
