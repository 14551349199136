/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useRef, useState } from 'react'
import DayPickerInput from 'react-day-picker/DayPickerInput'
import { DateUtils, DayModifiers, DayPickerInputProps } from 'react-day-picker'
import dateFnsFormat from 'date-fns/format'
import dateFnsParse from 'date-fns/parse'

import 'react-day-picker/lib/style.css'

import { CalenderIcon } from 'src/common'
import { INTERACTIONS, DIRECTIONS } from 'src/helpers'

import { Button } from '../button'
import { InputIcon } from '../input'

function parseDate(str: string, format: string, locale: any) {
  const parsed = dateFnsParse(str, format, new Date(), { locale })
  if (DateUtils.isDate(parsed)) {
    return parsed
  }
  return undefined
}

function formatDate(date: Date, format: string, locale: any) {
  return dateFnsFormat(date, format, { locale })
}

const FORMAT = 'MMMM d, y'

export type IDayPicker = DayPickerInputProps & {
  defaultValue?: string | Date
}

export const DayPickerButton: React.FC<IDayPicker> = (props: IDayPicker) => {
  return (
    <div className="w-100 day-picker-wrapper">
      <DayPickerInput
        component={(props: any) => {
          return (
            <Button className="button button-icon button-day_picker w-100" {...props}>
              <span className="mr-12px ml-5px w-28px h-28px">
                <CalenderIcon className="w-100 h-100" />
              </span>
              {props.value || props.placeholder}
            </Button>
          )
        }}
        format={FORMAT}
        formatDate={formatDate}
        parseDate={parseDate}
        placeholder="Select Date"
        {...props}
      />
    </div>
  )
}

const DayInput = (props: any) => {
  return <InputIcon className="basic" type="text" {...props} />
}

export const DayPickerText: React.FC<IDayPicker> = (props: IDayPicker) => {
  const { inputProps, defaultValue, value, onDayChange, ...otherProps } = props
  const inputInstance = useRef<any>()
  const [internalValue, setInternalValue] = useState(defaultValue || value)

  const handleDayChange = (day: Date, dayModifiers: DayModifiers, dayPickerInput: DayPickerInput) => {
    onDayChange?.(day, dayModifiers, dayPickerInput)
  }

  useEffect(() => {
    if (value && !defaultValue) setInternalValue(value)
  }, [defaultValue, value])

  const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    const { key, shiftKey } = e

    if (!shiftKey && (key === INTERACTIONS.TAB || key === DIRECTIONS.DOWN)) {
      setTimeout(() => {
        inputInstance.current.showDayPicker()
        setTimeout(() => {
          const dayPickerWrapper = document.getElementsByClassName('DayPicker-wrapper')[0] as any
          return dayPickerWrapper ? dayPickerWrapper.focus() : ''
        }, 40)
      }, 10)
    }
  }

  const handleHiddenPicker = (event: React.KeyboardEvent<HTMLDivElement>) => {
    const { key, shiftKey } = event

    const checkExist =
      document.getElementsByClassName('DayPicker-Body')[0] &&
      document.getElementsByClassName('DayPicker-Body')[0].contains(event.target as Node)

    if (!shiftKey && key === INTERACTIONS.TAB && checkExist) {
      inputInstance.current.hideDayPicker()
    }
  }

  return (
    <div className="w-100 day-picker-wrapper" onKeyDown={handleHiddenPicker}>
      <DayPickerInput
        ref={inputInstance}
        keepFocus={true}
        component={DayInput}
        format={FORMAT}
        formatDate={formatDate}
        parseDate={parseDate}
        placeholder="Select Date"
        inputProps={{ ...inputProps, ref: null, onKeyDown: handleKeyDown }}
        value={internalValue}
        onDayChange={handleDayChange}
        {...otherProps}
      />
    </div>
  )
}
