import React from 'react'

import { ImageOptionSelect } from 'src/common/components/select'

import asymmetricalAiry from 'src/assets/images/asymmetrical_airy.jpg'
import classic from 'src/assets/images/classic.jpg'
import lushGarden from 'src/assets/images/lush_garden.jpg'

import './BouquetShape.scss'

import { QuizQuestionWrapper } from '../QuizQuestionWrapper'

import { useBouquetShape } from './BouquetShape.hook'

export const BouquetShape = () => {
  const { bouquetShapeSelected, nextDisabled, handleNext, handleBack, handleSelectImage } = useBouquetShape()

  return (
    <>
      <QuizQuestionWrapper
        title="bouquets"
        nextAction={{
          disabled: nextDisabled,
          onAction: handleNext,
        }}
        backAction={{
          onAction: handleBack,
        }}
        step={4}
        total={4}
      >
        <div className="quiz-guest-bouquet-shape">
          <p className="quiz-question-header title-bouquet mw-50-rem">What style and shape bouquet do you like?</p>
          <ImageOptionSelect
            options={[
              {
                id: 'CLASSIC',
                img: classic,
                label: <>Classic</>,
              },
              {
                id: 'LUSH_GARDEN',
                img: lushGarden,
                label: <>Lush Garden</>,
              },
              {
                id: 'ASYMMETRICAL_&_AIRY',
                img: asymmetricalAiry,
                label: <>Asymmetrical + Airy</>,
              },
            ]}
            onSelect={handleSelectImage}
            defaultActive={bouquetShapeSelected}
          />
          <div className="mt-20px m-center">
            <button disabled={nextDisabled} className="button button-primary mt-14px" onClick={handleNext}>
              See my style
            </button>
          </div>
        </div>
      </QuizQuestionWrapper>
    </>
  )
}
