import React, { useEffect, useState } from 'react'

import introImg from 'src/assets/images/intro_right_image.png'

interface IImageWithLogoLazyProps
  extends React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement> {
  src: string
}

export const ImageWithLogoLazy = (props: IImageWithLogoLazyProps) => {
  const [urlImage, setUrlImage] = useState(introImg)

  useEffect(() => {
    const img = new Image()
    img.onload = function () {
      setUrlImage(props.src)
    }
    img.src = props.src
  }, [props.src])

  return <img {...props} src={urlImage} />
}
