import React, { InputHTMLAttributes, useEffect, useState } from 'react'
import { MinusIcon, PlusIcon } from 'src/common'

export type IInputBaseProps = React.InputHTMLAttributes<HTMLInputElement>

export const InputBase: React.FC<IInputBaseProps> = (props: IInputBaseProps) => {
  const { className, ...inputTextProps } = props
  return <input className={`input ${className ?? ''}`} {...inputTextProps} />
}

export type IInputIconProps = React.InputHTMLAttributes<HTMLInputElement> & {
  icon: React.ReactNode
}

export const InputIcon: React.FC<IInputIconProps> = (props: IInputIconProps) => {
  const { className, icon, ...inputTextProps } = props

  return (
    <div className="input-icons w-100">
      <span className="icon" onClick={inputTextProps.onClick}>
        {icon}
      </span>
      <input className={`input-field w-100 ${className ?? ''}`} {...inputTextProps} />
    </div>
  )
}

export enum TYPE_CHANGE_QUANTITY {
  INCREASE = 'increase',
  DECREASE = 'decrease',
}

export interface IQuantitySelectorCoreProps {
  value: number
  className?: string
}

export type IQuantitySelectorProps = IQuantitySelectorCoreProps & {
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
  onUpDownValue: (type: TYPE_CHANGE_QUANTITY) => void
}

export const QuantitySelector: React.FC<IQuantitySelectorProps> = (props: IQuantitySelectorProps) => {
  const { onChange, value, onUpDownValue, className } = props

  return (
    <div className={`input-quantity ${className ?? ''}`}>
      <button className="quantity-action" onClick={() => onUpDownValue(TYPE_CHANGE_QUANTITY.DECREASE)}>
        <MinusIcon />
      </button>
      <input type="number" inputMode="tel" className="quantity-value" value={value} onChange={onChange} />
      <button className="quantity-action" onClick={() => onUpDownValue(TYPE_CHANGE_QUANTITY.INCREASE)}>
        <PlusIcon />
      </button>
    </div>
  )
}

export type IQuantitySelectorBaseProps = IQuantitySelectorCoreProps & {
  onChange?: (value: number) => void
}

export const QuantitySelectorBase: React.FC<IQuantitySelectorBaseProps> = (props: IQuantitySelectorBaseProps) => {
  const { onChange, value, ...otherProps } = props

  const handleUpDownValue = (type: TYPE_CHANGE_QUANTITY) => {
    const valueInput = type === TYPE_CHANGE_QUANTITY.INCREASE ? value + 1 : value - 1
    onChange && onChange(valueInput)
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value.trim() !== '') {
      const formatValue = parseInt(event.target.value)
      event.target.value = Number.isNaN(formatValue) ? '' : `${formatValue}`
    }

    // eslint-disable-next-line eqeqeq
    if (((props.value as unknown) as string) != event.target.value) {
      props.onChange && props.onChange(+event.target.value)
    }
    // onChange && onChange(+event.target.value)
  }

  return <QuantitySelector value={value} onChange={handleChange} onUpDownValue={handleUpDownValue} {...otherProps} />
}

interface OutlinedTextFieldProps extends InputHTMLAttributes<HTMLInputElement> {
  name?: string
  label?: string
  isError?: boolean
  errorMsg?: string
  wrapperClass?: string
  inputClass?: string
}

export const OutlinedTextField: React.FC<OutlinedTextFieldProps> = (props: OutlinedTextFieldProps) => {
  const [hasValue, setHasValue] = useState(false)

  useEffect(() => {
    setHasValue(!!props.value)
  }, [props.value])

  return (
    <div className={`input-wrapper ${props.wrapperClass || ''}`}>
      <div className={`floating-label-wrapper ${hasValue ? 'has-value' : ''}`}>
        <label className="label">{props.label}</label>
        <input
          className={`input ${props.isError ? 'border-red' : ''} ${props.inputClass || ''}`}
          id={props.id}
          name={props.name}
          value={props.value}
          type={props.type}
          placeholder={props.placeholder}
          onChange={props.onChange}
          onBlur={props.onBlur}
        />
      </div>
      {props.isError ? <div className="error">{props.errorMsg}</div> : <div className="error">&nbsp;</div>}
    </div>
  )
}
