import React from 'react'
import './Introduce.scss'
import { useNormalResult } from '../../ResultsPage.hook'
import { SegmentAction, segmentTracking } from 'src/helpers/segment'
import { CartIcon } from 'src/common'
import { ACTION_APP, ga4EventApp, gaEventApp } from 'src/helpers/googleAnalyticsService'

interface BookNowCardProps {
  bookNowUrl?: string
}

const BookNowCard: React.FC<BookNowCardProps> = ({ bookNowUrl }: BookNowCardProps) => {
  const bookNowAction = () => {
    if (bookNowUrl) {
      segmentTracking(SegmentAction.SQ_BOOK_NOW_CLICKED)
      gaEventApp(ACTION_APP.BOOK_NOW_CLICKED)
      ga4EventApp(ACTION_APP.BOOK_NOW_CLICKED)
      window.location.href = bookNowUrl
    }
  }
  return (
    <div className="book-now-card">
      <h5 className="text-urgency">YOUR DATE IS POPULAR!</h5>
      <h4 className="book-now-title">Book now for $149!</h4>
      <p className="description">
        Save the date with Poppy and customize your flowers up to 60 days before your wedding.
      </p>
      <div className="action">
        <button className="button button-basic w-button book-now-btn" onClick={bookNowAction}>
          <CartIcon style={{ fontSize: 15, marginRight: '15px' }} />
          Book Now
        </button>
      </div>
    </div>
  )
}

export const Introduce: React.FC = () => {
  const { infoProposal, customerFullInfo, handleScheduleACall, handleGetInTouch, isCustomer1000 } = useNormalResult()

  const ctaConfigs = customerFullInfo?.ctaConfigs || ['START_CONVERSATION']
  const isAvailableScheduleACall = ctaConfigs.includes('SCHEDULE_CALL')

  const handleSecondaryBtn = () => {
    if (isAvailableScheduleACall) {
      handleScheduleACall({ ctaLocation: 'STYLE_TEXT' })
    } else {
      handleGetInTouch({ ctaLocation: 'STYLE_TEXT' })
    }
  }
  const handleSeeProposal = () => {
    if (!customerFullInfo?.proposalUrl) return
    segmentTracking(SegmentAction.SQ_SEE_PROPOSAL_CLICKED)
    window.location.href = customerFullInfo?.proposalUrl
  }

  const jumpToPackages = () => {
    document.getElementById('packages')?.scrollIntoView({ behavior: 'smooth' })
  }

  return (
    <div className="introduce-wrapper">
      <div className="result-introduce">
        <div className="container mt-0px-auto introduce-content">
          <div className="content">
            <h3 className="title">{isCustomer1000 ? 'Your Poppy Packages' : 'Your Poppy Moodboard'}</h3>
            <div className="subtitle">
              {isCustomer1000
                ? 'Poppy has options to fit your vision and budget. Start customizing your order now.'
                : infoProposal?.styleText}
            </div>
            <div className="actions mt-41px">
              {isCustomer1000 ? (
                <>
                  <button onClick={handleSeeProposal} className={`button button-basic w-button`}>
                    Start Customizing
                  </button>
                  <button onClick={jumpToPackages} className={`button button-secondary w-button`}>
                    VIEW PACKAGES
                  </button>
                </>
              ) : (
                <button onClick={handleSecondaryBtn} className={`button button-basic w-button`}>
                  {isAvailableScheduleACall ? 'schedule a call' : 'start a conversation'}
                </button>
              )}
            </div>
          </div>
          <div className="book-now-session desktop">
            <BookNowCard bookNowUrl={customerFullInfo?.bookNowUrl} />
          </div>
        </div>
      </div>
      <div className="book-now-session mobile">
        <BookNowCard bookNowUrl={customerFullInfo?.bookNowUrl} />
      </div>
    </div>
  )
}
