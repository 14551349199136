import React from 'react'
import { Accordion } from 'src/common/components/Accordion'
import './FAQs.scss'

import flower1 from 'src/assets/images/faq_flower_1.png'
import flower2 from 'src/assets/images/faq_flower_2.png'
import { useNormalResult } from './ResultsPage.hook'
import { SegmentAction, segmentTracking } from 'src/helpers/segment'

const Questions = [
  {
    title: 'When do I meet my designer?',
    content:
      'After you book, our team matches your wedding with an experienced, vetted Day-Of Florist. We handle all of the in-between details to make sure that your wedding flowers are simple and stress-free. Your Day-Of Florist arrives to install your custom wedding flowers on your big day. Some customers meet their Day-Of Florist during the delivery and some customers let a day-of coordinator or a family member handle the delivery. We’re flexible.',
  },
  {
    title: 'How much do wedding flowers cost? ',
    content:
      'Most couples spend about 8-10% of their total wedding budget on flowers. If you work with a local florist, you’ll come up against high minimums (pricing usually starts around $5,000) and black out dates. The price of flowers for most Poppy weddings is between $1,000 and $5,000 all-in, including white glove delivery and installation, and we can work with any date. We recognize that, like everything for your big day, wedding florals are a big investment, and the details really matter. Poppy works with you to understand your highest priorities and always presents you with ideas and solutions at multiple price points, so you can choose the option that fits your budget without ever compromising on quality or style.',
  },
  {
    title: `What wedding flowers are available at different points of the year?`,
    content:
      'Everyone has their favorite wedding flowers—from peonies to garden roses to dahlias to magnolias, we hear requests for all types of showy blooms. Of course, most flowers are only available for a few months of each year. Since all Poppy flowers are delivered to your wedding straight from one of our farms, we focus on sourcing and designing with a variety of stems that are fresh and most beautiful right at the time of your wedding. No matter the time of year, we guarantee beautiful, show-stopping stems for your big day. In the winter, you might like Paperwhites, Poinsettias, Amaryllis, or Hellebores. For a spring wedding, try Peonies, Poppies, Orchids, Tulips. In the summer, look for Hydrangeas, Sunflowers, Gardenias, Zinnias. A classic fall wedding might include Dahlias, Cosmos, Sunflower, Aster. Some flowers are available year-round like Roses, Ranunculus, and Anemone.',
  },
  {
    title: 'When should you order wedding flowers?',
    content:
      'As soon as you’ve selected a venue and a date for your wedding, it’s time to start planning your wedding flowers. Poppy recommends designing and ordering florals with us between 5 and 7 months before your wedding date.',
  },
]

const FAQs: React.FC = () => {
  const { handleGetInTouch, isCustomer1000, customerFullInfo } = useNormalResult()
  const handleSeeProposal = () => {
    if (!customerFullInfo?.proposalUrl) return
    segmentTracking(SegmentAction.SQ_SEE_PROPOSAL_CLICKED)
    window.location.href = customerFullInfo?.proposalUrl
  }

  return (
    <div className="faqs-section">
      <div className="faqs-section-wrapper container mt-0px-auto">
        <div className="faq-header mt-54px">
          <div className="header-content">
            <h2 className="center title">FAQs</h2>
            {/* <p className="center subtile">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et
              dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod.
            </p> */}
          </div>
        </div>
        <div className="faqs-content mt-32px">
          {Questions.map((q) => (
            <Accordion key={q.title} title={q.title} content={q.content} />
          ))}
        </div>
        <div className="center action">
          {isCustomer1000 ? (
            <button onClick={handleSeeProposal} className={`button button-basic w-button`}>
              Start Customizing
            </button>
          ) : (
            <button className="button button-basic" onClick={() => handleGetInTouch({ ctaLocation: 'FAQ' })}>
              Start a conversation
            </button>
          )}
        </div>
      </div>

      <img className="flower flower-1" src={flower1} alt="flower" />
      <img className="flower flower-2" src={flower2} alt="flower" />
    </div>
  )
}

export default FAQs
