import React from 'react'

import { Cart } from './Cart'
import { CheckoutPopup } from './CheckoutPopup'
import { ProductList } from './ProductList'

export const Products = () => {
  return (
    <div id="products-section" className="product">
      <ProductList />
      <Cart />
      <CheckoutPopup />
    </div>
  )
}
