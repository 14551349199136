/* eslint-disable react/prop-types */
/* eslint-disable react/display-name */
import React, { PropsWithChildren, ReactNode } from 'react'
import styled from 'styled-components'

export const ButtonBase = styled.button``

export type IButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement> &
  PropsWithChildren<
    Partial<{
      iconReverse: boolean
      icon: ReactNode
    }>
  >

export const Button = React.forwardRef<HTMLButtonElement, IButtonProps>((props, ref) => {
  const { iconReverse, disabled, icon, children, ...propsButton } = props
  return (
    <button ref={ref} disabled={disabled} {...propsButton}>
      {iconReverse && icon}
      {children}
      {!iconReverse && icon}
    </button>
  )
})

export const ButtonWithIcon = React.forwardRef<HTMLButtonElement, IButtonProps>((props, ref) => {
  const { className, ...propsButton } = props
  return <Button ref={ref} {...propsButton} className={`button button-icon ${className ?? ''}`} />
})
