import React, { useEffect, useState } from 'react'
import closeIcon from 'src/assets/images/cannyValley/close.svg'
import { Button } from 'src/common'
import { DEFAULT_COLORS, ListColor } from 'src/helpers/colors'

interface AddPopColorProps {
  onClose: () => void
  colors: string[]
  addPopColor: (colors: string[]) => void
}

export const AddPopColor: React.FC<AddPopColorProps> = ({ onClose, colors, addPopColor }: AddPopColorProps) => {
  const [listColors, setListColors] = useState<ListColor[]>([])

  const handleSelectAColor = (id: string) => {
    setListColors((pre) => pre.map((c) => ({ ...c, selected: c.id === id ? !c.selected : c.selected })))
  }

  const handleAddPopColor = () => {
    addPopColor(listColors.filter((c) => c.selected).map((c) => c.code))
    onClose()
  }

  useEffect(() => {
    setListColors(DEFAULT_COLORS.map((i) => ({ ...i, selected: colors.includes(i.code) })))
  }, [colors])

  return (
    <div className="pop-color-wrapper">
      <div className="pop-color-header">
        <h3>Add a Pop of Color</h3>
        <span className="close-icon" onClick={onClose}>
          <img src={closeIcon} />
        </span>
      </div>
      <div className="pop-color-content">
        <p className="choose-color-title">Choose a color to add to your palette.</p>
        <div className="list-colors">
          {listColors.map((color) => (
            <div
              key={color.id}
              className={`color-item ${color.selected ? 'active' : ''}`}
              onClick={() => handleSelectAColor(color.id)}
            >
              <span
                style={{ background: color.code }}
                className={`color-circle ${color.hasBorder ? 'has-border' : ''}`}
              ></span>
              <span className="color-name">{color.name}</span>
            </div>
          ))}
        </div>
      </div>
      <div className="pop-color-footer">
        <Button className="button-stem secondary" onClick={onClose}>
          nevermind
        </Button>
        <Button className="button-stem" onClick={handleAddPopColor}>
          add the pop
        </Button>
      </div>
    </div>
  )
}
