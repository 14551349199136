import { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { getHttp, getScheduledEventInfo, postHttp } from 'src/api'
import { PATH_API } from 'src/helpers'
import { useAppContext } from 'src/helpers/app'
import { API, IQuizSessionsResponse } from 'src/types'

export const usePostMoodBoard = () => {
  const { sessionId } = useParams<{ sessionId: string }>()
  const { handleChangeAppData, setSessionID, setQuizSession, quizSession, appData } = useAppContext()

  useEffect(() => {
    const initPage = async () => {
      if (!sessionId) return

      // Trigger complete quiz
      postHttp(PATH_API.completeQuiz(sessionId))

      if (quizSession && appData.scheduledEventInfo) return

      handleChangeAppData({ countLoading: 1 })
      try {
        const sessionResult: API<IQuizSessionsResponse> = await getHttp(PATH_API.getSessionId(sessionId))
        setQuizSession(sessionResult.data)

        if (sessionResult.data.proposal?.calendlyEventId) {
          const event = await getScheduledEventInfo(sessionResult.data.proposal?.calendlyEventId)
          handleChangeAppData({ scheduledEventInfo: event })
        }
        setSessionID(sessionId)
      } catch (error) {
        console.error('initPage', error)
      }
      handleChangeAppData({ countLoading: -1 })
    }

    initPage()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sessionId])

  return {
    calendlyEvent: appData.scheduledEventInfo?.calendlyEvent,
    owner: quizSession?.event.owner,
    proposal: quizSession?.proposal,
  }
}
