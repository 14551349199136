import React from 'react'
import { ImageWithLogoLazy } from 'src/common'
import { IImageFireWork } from 'src/config/fireworkConfig'

import './Gallery.scss'

interface IGalleryDesktopProps {
  imageFirework: IImageFireWork
}

export const GalleryDesktop = (props: IGalleryDesktopProps) => {
  const {
    topRightTop,
    topRightBottom,
    topRightRight,
    topLeft,
    bottomRight,
    bottomLeftRight,
    bottomLeftLeft,
  } = props.imageFirework

  return (
    <div className="gallery gallery-desktop">
      <div className="w-layout-grid grid-20">
        <div className="div-block-158 area-6">
          <ImageWithLogoLazy src={topRightTop} loading="lazy" id="topRightTop" alt="" className="image-84" />
        </div>
        <div className="div-block-159 area-7">
          <ImageWithLogoLazy src={topRightBottom} loading="lazy" id="topRightBottom" alt="" className="image-85" />
        </div>
        <div className="area-2">
          <ImageWithLogoLazy src={topRightRight} loading="lazy" id="topRightRight" alt="" className="image-63" />
        </div>
        <div className="div-block-157 area">
          <ImageWithLogoLazy src={topLeft} loading="lazy" id="topLeft" alt="" className="image-63" />
        </div>
        <div className="area-5">
          <ImageWithLogoLazy src={bottomRight} loading="lazy" id="bottomRight" alt="" className="image-63" />
        </div>
        <div className="area-4">
          <ImageWithLogoLazy src={bottomLeftRight} loading="lazy" id="bottomLeftRight" alt="" className="image-63" />
        </div>
        <div className="area-3">
          <ImageWithLogoLazy src={bottomLeftLeft} loading="lazy" id="bottomLeftLeft" alt="" className="image-63" />
        </div>
      </div>
    </div>
  )
}
