import { PATH_API } from 'src/helpers'
import { postHttp } from './apiLayer'

function fileReader(file: File): Promise<string> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.onload = (e) => {
      if (!e.target) {
        return reject(new Error('File Reader failed!'))
      }
      resolve(e.target.result as string)
    }

    reader.readAsDataURL(file)
  })
}

const convertFileToBase64 = async (file: File) => {
  const qa = await fileReader(file)

  const binary = atob(qa.split(',')[1])
  const array = []
  for (let i = 0; i < binary.length; i++) {
    array.push(binary.charCodeAt(i))
  }

  return new Blob([new Uint8Array(array)], { type: file.type })
}

export const getS3SignedURL = async (fileName: string) => {
  const result = await postHttp(PATH_API.uploadFileToS3, { fileName })

  return result?.data
}

export const uploadFileToS3 = async (file: File): Promise<string | null> => {
  const resultSignedUrl = await getS3SignedURL(file.name)

  const blodData = await convertFileToBase64(file)

  const response = await fetch(resultSignedUrl.signedUrl, { method: 'PUT', body: blodData })

  if (!response.ok) {
    return null
  }

  return resultSignedUrl.cloudfrontUrl // get url file
}
