/* eslint-disable react/prop-types */
/* eslint-disable react/display-name */
import React, { PropsWithChildren, ReactNode, useState } from 'react'
import './Accordion.scss'

export type AccordionProps = React.ButtonHTMLAttributes<HTMLDivElement> &
  PropsWithChildren<
    Partial<{
      expanded: boolean
      title: string | ReactNode
      content: string | ReactNode
    }>
  >

export const Accordion = React.forwardRef<HTMLDivElement, AccordionProps>((props, ref) => {
  const { expanded, title, content, ...accordionProps } = props

  const [isActive, setIsActive] = useState(expanded)
  return (
    <div className="accordion" ref={ref} onClick={() => setIsActive(!isActive)} {...accordionProps}>
      <div className="accordion-title">
        <div className="accordion-icon">{isActive ? '-' : '+'}</div>
        <div className="accordion-text">
          <h4>{title}</h4>
        </div>
      </div>
      <div className={`accordion-content ${isActive ? 'active' : ''}`}>
        <p>{content}</p>
      </div>
    </div>
  )
})
