import React from 'react'
import { Calendly } from 'src/common/components/Calendly'
import { useScheduleACall } from './useScheduleACall'
import './styles.scss'

export const ScheduleACall: React.FC = () => {
  const { customer, urlCalendly, loading, handleEventScheduled } = useScheduleACall()

  if (!customer) return <></>

  return (
    <div className="schedule-a-call-section mt-30px">
      <Calendly
        url={urlCalendly}
        email={customer?.emailAddress}
        name={customer?.name}
        handleEventScheduled={handleEventScheduled}
        loading={loading}
      />
    </div>
  )
}
