import React from 'react'

interface IWorkingProcessProps {
  className: string
}

export const WorkingProcess: React.FC<IWorkingProcessProps> = (props: IWorkingProcessProps) => {
  return (
    <div className={`working-process-wrapper ${props.className}`}>
      <div className="working-process">
        <div className="process-item">
          <div className="item-no">Step One</div>
          <div className="item-content">Book the perfect flowers for your special day.</div>
          <div className="line-red mobile" />
        </div>
        <div className="process-item">
          <div className="item-no">Step Two</div>
          <div className="item-content">
            We pair you with one of our expert floral designers to bring your flowers to life.
          </div>
          <div className="line-red mobile" />
        </div>
        <div className="process-item">
          <div className="item-no">Step Three</div>
          <div className="item-content">Your wedding flowers are designed and delivered to you on your big day. </div>
          <div className="line-red mobile" />
        </div>
      </div>
    </div>
  )
}
