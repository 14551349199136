import React from 'react'

// import logo from 'src/assets/images/logo.png'

export const LoaderFullScreen = () => {
  return (
    <>
      <div className="loader_full_screen">
        {/* <img src={logo} className="icon-logo" /> */}
        <div className="loader"></div>
      </div>
    </>
  )
}
