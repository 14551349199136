import React from 'react'

import { ImageWithLogoLazy, QuantitySelectorBase } from 'src/common'

import { Tick } from 'src/common/components/select/Tick'
import { CenterModeCarousel } from 'src/common/components/Carousel'
import { formatCurrency } from 'src/helpers'

import { useCustomerExperienceContext } from '../context'
import { handleShowNameOption } from '../helper'
import { IProduct } from 'src/types/product'

interface IProductItemProps {
  product: IProduct
  checked: boolean
  handleSelectProduct?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>, item: IProduct) => void
}

const ProductItem = (props: IProductItemProps) => {
  const { product, checked, handleSelectProduct } = props
  return (
    <div
      key={product.shopifyVariantId}
      className="option-item"
      onClick={(e) => handleSelectProduct && handleSelectProduct(e, product)}
    >
      <ImageWithLogoLazy src={product.imageUrl} alt="item-img" />
      <div className="item-info tooltip">
        <div className="tooltip-text">{product.name}</div>
        <div className="name-price-wrapper">
          <div className="item-name ">{product.name}</div>
          <div className="item-price">{formatCurrency(product.price)}</div>
        </div>
        <div className="checked">
          <Tick checked={checked} />
        </div>
      </div>
    </div>
  )
}

export const ProductList = () => {
  const { products, cart, setCart } = useCustomerExperienceContext()

  const productsObject = Object.keys(products)

  const handleChangeQuantity = (key: string, quantity: number) => {
    setCart((value) => {
      value[key].quantity = quantity >= 0 ? +quantity * 1 : 0

      return { ...value }
    })
  }

  const handleSelectProduct = (event: React.MouseEvent<HTMLDivElement, MouseEvent>, item: IProduct) => {
    event.preventDefault()
    const { unitType } = item

    setCart((value) => {
      value[unitType].product = item
      return { ...value }
    })
  }

  const handleSelectProductMobile = (productsOfOption: IProduct[], current: number) => {
    const productSelect = productsOfOption[current]

    setCart((value) => {
      value[productSelect.unitType].product = productSelect
      return { ...value }
    })
  }

  return (
    <>
      <div className="product-list desktop">
        {productsObject.map((key) => {
          const product = products[key]
          return (
            <div key={key} className="product-option">
              <div className="option-title">
                <div className="title-name"> {handleShowNameOption(key)} </div>
                <div className="option-quantity">
                  <QuantitySelectorBase
                    value={cart[key] ? +cart[key].quantity : 0}
                    className="quantity-input ml-12px"
                    onChange={(value) => handleChangeQuantity(key, value)}
                  />
                </div>
              </div>
              <div className="option-list">
                {product &&
                  product.map((item) => {
                    return (
                      <ProductItem
                        key={item.shopifyVariantId}
                        product={item}
                        checked={cart[key] && cart[key].product?.shopifyVariantId === item.shopifyVariantId}
                        handleSelectProduct={handleSelectProduct}
                      />
                    )
                  })}
              </div>
            </div>
          )
        })}
      </div>

      {/* Mobile */}
      <div className="product-list mobile">
        <div className="swipe-note">Swipe to make your selections.</div>
        {productsObject.map((key) => {
          const option = products[key]
          return (
            <div key={key} className="product-option">
              <div className="option-title">
                <div className="title-name"> {handleShowNameOption(key)} </div>
              </div>
              <div className="option-list container">
                <CenterModeCarousel onChange={(index) => handleSelectProductMobile(option, index)}>
                  {option &&
                    option.map((item) => {
                      return (
                        <ProductItem
                          key={item.shopifyVariantId}
                          product={item}
                          checked={cart[key] && cart[key].product?.shopifyVariantId === item.shopifyVariantId}
                        />
                      )
                    })}
                </CenterModeCarousel>
              </div>
              <div className="option-quantity-wrapper">
                <div className="option-quantity">
                  <QuantitySelectorBase
                    value={cart[key] ? +cart[key].quantity : 0}
                    className="quantity-input ml-12px"
                    onChange={(value) => handleChangeQuantity(key, value)}
                  />
                </div>
              </div>
            </div>
          )
        })}
      </div>
    </>
  )
}
