import React from 'react'
import { QuizQuestionWrapper } from '../QuizQuestionWrapper'
import { useMetBefore } from './MetBefore.hook'
import metBeforeBg from 'src/assets/images/met-before-bg.png'
import flowerBg from 'src/assets/images/Lupine_Curved_Grayscale_2.png'
import './style.scss'

export const MetBefore: React.FC = () => {
  const { handleNext, reason, handleSelectedReason, additionalNotes, setAdditionalNotes, nextDisabled } = useMetBefore()
  return (
    <QuizQuestionWrapper titleStep="Getting Started" className="met-before" backAction={{ disabled: true }}>
      <div className="met-before-wrapper">
        <div className="left-part">
          <img src={metBeforeBg} alt="Hey, we've met before" className="bg-image" />
        </div>
        <div className="right-part">
          <div>
            <h2 className="title">Hey, we’ve met before</h2>
            <p className="description">
              We already have your email or phone number in our system. Good to see you back!
            </p>
            <div className="reason-group mt-15px">
              {reason.map((r) => (
                <div
                  key={r.id}
                  className={`card ${r.isSelected ? 'active' : ''}`}
                  onClick={() => handleSelectedReason(r.id)}
                >
                  <span className="reason-text">{r.label}</span>
                </div>
              ))}
            </div>
            <div className="mt-30px">
              <div className="floating-label-wrapper">
                <input
                  id="reasonNoteId"
                  className="input w-100"
                  value={additionalNotes}
                  type="text"
                  name="reasonNote"
                  placeholder="Additional notes (optional)"
                  onChange={(e) => setAdditionalNotes(e.target.value)}
                />
              </div>
            </div>
            <div className="action-box-wrapper mt-30px mb-90px">
              <button disabled={nextDisabled} className="button white-space-wrap" onClick={handleNext}>
                Continue
              </button>
            </div>
          </div>
        </div>
        <img src={flowerBg} alt="flower bg" className="flower-bg" />
      </div>
    </QuizQuestionWrapper>
  )
}
