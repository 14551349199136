import { useContext, useEffect, useState } from 'react'
import { QuizQuestionContext, SCREEN_QUIZ_QUESTION } from '../../contextAPI'
import { uploadFileToS3 } from 'src/api/uploadFile'
import { SegmentAction, segmentTracking } from 'src/helpers/segment'

type ModalUpload = {
  open: boolean
  images: { file: File; caption: string }[]
}

type InspirationPhoto = {
  imageUrl: string
  imageOptionId?: number
  label: string
  selected: boolean
}

export const useInspiration = () => {
  const { setQuizScreen, setQuizData, data, quizQuestionData } = useContext(QuizQuestionContext)
  const [inspirationPhotos, setInspirationPhotos] = useState<InspirationPhoto[]>([])
  const [uploading, setUploading] = useState<boolean>(false)
  const [modalUpload, setModalUpload] = useState<ModalUpload>({
    open: false,
    images: [],
  })

  const handleBack = () => {
    setQuizScreen(SCREEN_QUIZ_QUESTION.CUSTOM_PALETTE)
  }

  const handleNext = () => {
    try {
      setQuizData({
        inspirationPhotos: inspirationPhotos
          .filter((i) => i.selected)
          .map((i) => ({
            imageUrl: i.imageUrl,
            label: i.label,
            styleImagePreferenceOptionId: i.imageOptionId,
          })),
      })
      setQuizScreen(SCREEN_QUIZ_QUESTION.FLOWERS_INTERSTITIAL)
      segmentTracking(SegmentAction.SQ_CV_INSPIRATION_COMPLETED)
    } catch (error) {}
  }

  const handleUploadInspirationImage = (files: File[]) => {
    setModalUpload(() => ({
      open: true,
      images: files.map((f) => ({ file: f, caption: '' })),
    }))
  }

  const closeModalUpload = () => {
    setModalUpload({ open: false, images: [] })
  }

  const handleChangeCation = (imageIndex: number, caption: string) => {
    setModalUpload((pre) => {
      const newState = { ...pre }
      newState.images[imageIndex].caption = caption
      return newState
    })
  }

  const uploadImageToS3 = async () => {
    try {
      setUploading(true)
      const uploadFiles: Promise<unknown>[] = []
      modalUpload.images.forEach((i) => {
        uploadFiles.push(
          uploadFileToS3(i.file).then((res) => {
            if (res)
              setInspirationPhotos((pre) => {
                const newState = [...pre]
                newState.unshift({ imageUrl: res, label: i.caption, selected: true })
                return newState
              })
          })
        )
      })
      await Promise.all(uploadFiles)
      segmentTracking(SegmentAction.SQ_CV_INSPIRATION_UPLOADED)
    } catch (error) {
    } finally {
      setUploading(false)
      setModalUpload({ open: false, images: [] })
    }
  }

  const selectImageOption = (imageIndex: number) => {
    setInspirationPhotos((pre) => {
      const newState = [...pre]
      newState[imageIndex] = { ...newState[imageIndex], selected: !newState[imageIndex].selected }
      if (newState[imageIndex].selected) segmentTracking(SegmentAction.SQ_CV_INSPIRATION_SELECTED)
      return newState
    })
  }

  useEffect(() => {
    const initPage = () => {
      if (!data.colorPalette) return

      const imageOptions = quizQuestionData?.imagePreferenceOptions?.filter(
        (i) => i.colorPaletteId === data.colorPalette?.id
      )
      setInspirationPhotos(() => {
        const options: InspirationPhoto[] =
          imageOptions?.map((o) => ({
            imageUrl: o.imageUrl,
            label: o.label,
            imageOptionId: o.id,
            selected: !!data.inspirationPhotos?.find((ip) => ip.styleImagePreferenceOptionId === o.id),
          })) || []
        const uploaded = data.inspirationPhotos?.filter((ip) => !ip.styleImagePreferenceOptionId) || []
        return uploaded
          .map((o) => ({
            imageUrl: o.imageUrl,
            label: o.label,
            selected: true,
          }))
          .concat(options)
      })
    }
    initPage()
  }, [data.colorPalette, data.inspirationPhotos, quizQuestionData?.imagePreferenceOptions])

  return {
    modalUpload,
    uploading,
    inspirationPhotos,
    handleUploadInspirationImage,
    closeModalUpload,
    handleChangeCation,
    handleBack,
    handleNext,
    uploadImageToS3,
    selectImageOption,
  }
}
