import React from 'react'
import { NumericFormat } from 'react-number-format'

import arrowLeftIcon from 'src/assets/images/cannyValley/arrow-left.svg'
import stemFillArrow from 'src/assets/images/cannyValley/stem-fill-arrow.svg'
import { Button } from 'src/common'
import { ImageGallery } from 'src/common/components/ImageGallery'
import { Dropdown } from 'src/common/components/select'
import { CannyValleyLayout } from 'src/common/components/Layout'

import { QuizStep } from '../QuizStep'
import { useBudget } from './useBudget'

import './style.scss'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const NumberFormatCustom = React.forwardRef((props: any, ref: any) => {
  const { onChange, ...other } = props

  return (
    <NumericFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        })
      }}
      thousandSeparator
      valueIsNumericString
      prefix="$"
      suffix={props.value >= 7500 ? '+' : ''}
    />
  )
})
NumberFormatCustom.displayName = 'NumberFormatCustom'

export const CannyValleyBudget: React.FC = () => {
  const {
    budget,
    BUDGET_FOCUS,
    handleChangeAmount,
    increaseAmount,
    decreaseAmount,
    handleBlurAmount,
    handleBack,
    handleNext,
    handleChangeBudgetFocus,
  } = useBudget()

  return (
    <CannyValleyLayout>
      <div className="budget-page">
        <div className="step-header">
          <QuizStep item={3} step={1} />
        </div>
        <div className="budget-content-wrapper">
          <div className="budget-content">
            <div className="top-actions desktop">
              <div className="back-action" onClick={handleBack}>
                <img src={arrowLeftIcon} />
                BACK
              </div>
            </div>

            <div className="budget-header">
              <div className="step-title-header">
                <div className="top-actions mobile">
                  <div className="back-action" onClick={handleBack}>
                    <img src={arrowLeftIcon} />
                  </div>
                </div>
                <h3 className="step-title">Your Budget</h3>
              </div>
              <p className="step-description">
                Choose where flowers matter the most in your wedding, and see the impact on budget.
              </p>
              <Dropdown
                className="budget-focus"
                options={BUDGET_FOCUS}
                value={{ value: budget.focus, label: budget.label }}
                handleChange={handleChangeBudgetFocus}
              />
            </div>

            <div className="budget-amount mt-24px">
              <div className="title">BUDGET AMOUNT</div>
              <div className="budget-input-wrapper mt-20px">
                <div className="action" onClick={decreaseAmount}>
                  <img src={stemFillArrow} className="rotate-180" />
                </div>
                <div>
                  <NumberFormatCustom
                    thousandSeparator={true}
                    value={budget.amount}
                    onChange={handleChangeAmount}
                    onBlur={handleBlurAmount}
                    name="budgetNumber"
                    className="budget-input"
                  />
                </div>
                <div className="action" onClick={increaseAmount}>
                  <img src={stemFillArrow} />
                </div>
              </div>
            </div>
            <Button className="desktop button-stem mt-46px" onClick={handleNext}>
              See MY MOODBOARD
            </Button>
          </div>
          <div className="flower-gallery mt-12px">
            <ImageGallery images={budget.images} showImgNumber={Math.min(budget.images.length, 6)} />
          </div>
          <div className="mobile">
            <Button className="button-stem" onClick={handleNext}>
              See MY MOODBOARD
            </Button>
          </div>
        </div>
      </div>
    </CannyValleyLayout>
  )
}
