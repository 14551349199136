import React from 'react'

import { Button } from 'src/common'
import { BudgetFocus } from 'src/types'
import { useAppContext } from 'src/helpers/app'
import './styles.scss'
import { useHistory } from 'react-router-dom'
import { segmentTracking, SegmentAction } from 'src/helpers/segment'

const MAX_BUDGET = 7500

const mappingFocusLabel = (focus?: BudgetFocus) => {
  switch (focus) {
    case BudgetFocus.EQUAL:
      return 'an all-inclusive'
    case BudgetFocus.CEREMONY:
      return 'a ceremony focused'
    case BudgetFocus.RECEPTION:
      return 'a reception focused'
    default:
      return ''
  }
}

const budgetDisplay = (budget?: number) => {
  if (!budget) return
  if (budget >= MAX_BUDGET) {
    budget = 7500
    return '$7,500+'
  }
  return budget.toLocaleString('en-US', {
    style: 'currency',
    currency: 'USD',
    maximumFractionDigits: 0,
    minimumFractionDigits: 0,
  })
}

export const ConnectUs: React.FC = () => {
  const { quizSession, sessionID } = useAppContext()
  const history = useHistory()

  const bookYourConsult = () => {
    history.push(`/schedule-a-call/${sessionID}`)
  }

  const shopFlowers = () => {
    if (quizSession?.proposal.proposalUrl) {
      segmentTracking(SegmentAction.CANNY_VALLEY_SHOP_FLOWERS_CLICKED)
      window.location.href = quizSession?.proposal.proposalUrl
    }
  }

  return (
    <div className="connect-us-section">
      <div className="container">
        <div className="connect-us">
          <div className="section-title">LIKE WHAT YOU SEE?</div>
          <div className="section-subtitle mt-24px">
            Connect with our team to design {mappingFocusLabel(quizSession?.event.budgetFocus)} floral package for your{' '}
            {budgetDisplay(quizSession?.event.budget)} budget.
          </div>
          <p className="description mt-32px">
            Your final total will depend on the quantity of arrangements you choose.
          </p>
          <div className="actions mt-40px">
            <Button className="button-stem width-fit-content" onClick={bookYourConsult}>
              BOOK YOUR CONSULT
            </Button>
            <Button className="button-stem secondary width-fit-content" onClick={shopFlowers}>
              SHOP FLOWERS
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}
