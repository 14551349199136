import { useRef } from 'react'

const ENTER = 'Enter'

type IEventTrigger = HTMLLabelElement | HTMLDivElement

export const clickEventTrigger = (): Event => {
  return new MouseEvent('click', {
    view: window || '',
    bubbles: true,
    cancelable: true,
    buttons: 1,
  })
}

export const handleDispatchEventEnter = (event: React.KeyboardEvent<IEventTrigger>) => {
  if (event.key === ENTER) {
    event.target.dispatchEvent(clickEventTrigger())
  }
}

export const useTriggerEvent = () => {
  const triggerRef = useRef<HTMLInputElement>(null)

  const handleEnterTrigger = (event: React.KeyboardEvent<IEventTrigger>) => {
    if (event.key === ENTER && triggerRef.current) {
      triggerRef.current.click()
    }
  }

  return {
    triggerRef,
    handleEnterTrigger,
  }
}
