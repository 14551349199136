import { postHttp } from './apiLayer'

interface ConversationPayload {
  sessionId: string
  message: string
}
export const postStartConversation = async (payload: ConversationPayload) => {
  const result = await postHttp(`/startConversation`, payload)
  return result.data
}
