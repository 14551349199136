import { useContext, useMemo, useState } from 'react'
import { IBooleanDynamicPrototype, IOption } from 'src/types'
import { QuizQuestionContext, SCREEN_QUIZ_QUESTION } from '../contextAPI'

interface IItemPriceList {
  title: string
  className: string
  name: string
  data: IOption[]
}

const itemPriceList: IItemPriceList[] = [
  {
    className: '',
    title: 'personals',
    name: 'personals',
    data: [
      {
        value: 'BRIDAL_BOUQUET',
        label: 'BRIDAL BOUQUET',
      },
      {
        value: 'BRIDESMAID_BOUQUET',
        label: 'BRIDESMAID BOUQUET',
      },
      {
        value: 'BOUTONNIERE',
        label: 'BOUTONNIÉRE',
      },
      {
        value: 'CORSAGE',
        label: 'CORSAGE',
      },
      {
        value: 'FLOWER_CROWN',
        label: 'FLOWER CROWN',
      },
    ],
  },
  {
    className: 'mt-30px',
    title: 'ceremony decor',
    name: 'ceremony',
    data: [
      {
        value: 'AISLE_DECOR',
        label: 'AISLE DECOR',
      },
      {
        value: 'ALTAR_DECOR',
        label: 'ALTAR DECOR',
      },
    ],
  },
  {
    className: 'mt-30px',
    title: 'cocktail decor',
    name: 'cocktail',
    data: [
      {
        value: 'COCKTAIL_BUD_VASES',
        label: 'BUD VASES',
      },
      {
        value: 'COCKTAIL_CENTERPIECE',
        label: 'CENTERPIECE',
      },
      {
        value: 'COCKTAIL_GREENERY',
        label: 'LOOSE GREENERY',
      },
    ],
  },
  {
    className: 'mt-30px mb-20px',
    title: 'reception decor',
    name: 'reception',
    data: [
      {
        value: 'RECEPTION_BUD_VASES',
        label: 'BUD VASES',
      },
      {
        value: 'RECEPTION_CENTERPIECE',
        label: 'CENTERPIECE',
      },
      {
        value: 'RECEPTION_TABLE_GARLAND',
        label: 'TABLE GARLAND',
      },
      {
        value: 'RECEPTION_LOOSE_GREENERY',
        label: 'LOOSE GREENERY',
      },
      {
        value: 'SWEETHEART_TABLE',
        label: 'SWEETHEART TABLE',
      },
    ],
  },
]

export const useSeeItemPrices = () => {
  const { data, setQuizData, setQuizScreen } = useContext(QuizQuestionContext)
  const [itemPricesSelected, setItemPricesSelected] = useState<IBooleanDynamicPrototype>(data.price || {})

  const handleNext = () => {
    setQuizData({ price: itemPricesSelected })
    setQuizScreen(SCREEN_QUIZ_QUESTION.COLORS_FLOWERS)
  }

  const handleBack = () => {
    setQuizScreen(SCREEN_QUIZ_QUESTION.FLOWERS_WEDDING)
  }

  const nextDisabled = useMemo(() => {
    return !Object.keys(itemPricesSelected).some((key) => itemPricesSelected[key])
  }, [itemPricesSelected])

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target

    setItemPricesSelected((list) => ({ ...list, [value]: !list[value] }))
  }

  return {
    itemPriceList,
    nextDisabled,
    itemPricesSelected,
    handleBack,
    handleChange,
    handleNext,
  }
}
