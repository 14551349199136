import { useContext, useMemo, useState } from 'react'
import { IBooleanDynamicPrototype, IOption } from 'src/types'
import { QuizQuestionContext, SCREEN_QUIZ_QUESTION } from '../contextAPI'

export const rituals: IOption[] = [
  {
    label: 'hindu',
    value: 'HINDU',
  },
  {
    label: 'muslim',
    value: 'MUSLIM',
  },
  {
    label: 'christian',
    value: 'CHRISTIAN',
  },
  {
    label: 'jewish',
    value: 'JEWISH',
  },
  {
    label: 'Something else',
    value: 'OTHER',
  },
]

export const useRituals = () => {
  const { data, setQuizData, setQuizScreen } = useContext(QuizQuestionContext)
  const [hasRitual, setHasRitual] = useState<boolean | null>(data.ritual?.hasRitual ?? null)
  const [ritualsSelected, setRitualsSelected] = useState<IBooleanDynamicPrototype>(data.ritual?.type || {})

  const handleNext = () => {
    setQuizData({ ritual: { hasRitual: hasRitual || false, type: ritualsSelected } })
    setQuizScreen(SCREEN_QUIZ_QUESTION.FLOWERS_WEDDING)
  }

  const handleBack = () => {
    setQuizScreen(SCREEN_QUIZ_QUESTION.GUESTS_AND_TABLE_EXPECT)
  }

  const nextDisabled = useMemo(() => {
    const checkHasRitual = hasRitual === true && !Object.keys(ritualsSelected).some((key) => ritualsSelected[key])
    return hasRitual === null || checkHasRitual
  }, [hasRitual, ritualsSelected])

  const handleChangeStatus = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target

    if (+value === 0) {
      // reset ritualsSelected
      setRitualsSelected({})
    }

    setHasRitual(+value === 1)
  }

  const handleSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target

    if (value === 'NOTHING') {
      setHasRitual(false)
    } else {
      setHasRitual(true)
    }

    setRitualsSelected((list) => ({ ...list, [value]: !list[value] }))
  }

  return {
    nextDisabled,
    hasRitual,
    ritualsSelected,
    handleChangeStatus,
    handleBack,
    handleNext,
    handleSelect,
  }
}
