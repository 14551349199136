import React, { useState } from 'react'
import { CartIcon } from 'src/common'
import { ACTION_APP, ga4EventApp, gaEventApp } from 'src/helpers/googleAnalyticsService'
import { SegmentAction, segmentTracking } from 'src/helpers/segment'
import { useNormalResult } from '../../ResultsPage.hook'
import './ProposalHeader.scss'

const MENU_ITEM = [
  { key: 'moodboard', value: 'Moodboard' },
  { key: 'proposalOverview', value: 'Proposal Overview' },
  { key: 'chooseFlowers', value: 'Choose your flowers' },
]

export const ProposalHeader: React.FC = () => {
  const { customerFullInfo } = useNormalResult()
  const [activeMenu, setActiveMenu] = useState('moodboard')
  const handleClickMenu = (action: string) => {
    setActiveMenu(action)
    switch (action) {
      case 'moodboard':
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
        break
      case 'chooseFlowers':
        break
      case 'proposalOverview':
        break
      default:
        break
    }
  }
  const bookNowAction = () => {
    if (customerFullInfo?.bookNowUrl) {
      segmentTracking(SegmentAction.SQ_BOOK_NOW_CLICKED)
      gaEventApp(ACTION_APP.BOOK_NOW_CLICKED)
      ga4EventApp(ACTION_APP.BOOK_NOW_CLICKED)
      window.location.href = customerFullInfo.bookNowUrl
    }
  }
  return (
    <div className="proposal-header-wrapper">
      <div className="container mt-0px-auto proposal-header">
        <div className="left-menu">
          {MENU_ITEM.map((m) => (
            <div
              key={m.key}
              className={`menu-item ${activeMenu === m.key && 'active'}`}
              onClick={() => handleClickMenu(m.key)}
            >
              <span className="menu-title">{m.value}</span>
            </div>
          ))}
        </div>
        <div className="right-menu">
          <button className="button button-basic w-button book-now-btn" onClick={bookNowAction}>
            <CartIcon style={{ fontSize: 15, marginRight: '15px' }} />
            Book Now
          </button>
        </div>
      </div>
    </div>
  )
}
