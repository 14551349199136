import React from 'react'
import { CannyValleyLayout } from 'src/common/components/Layout'
import './styles.scss'
import { usePostMoodBoard } from './usePostMoodboard'
import moment from 'moment'

export const PostMoodboard: React.FC = () => {
  const { calendlyEvent, owner, proposal } = usePostMoodBoard()

  if (!calendlyEvent)
    return (
      <CannyValleyLayout className="post-moodboard-page">
        <div className="post-moodboard-wrapper"></div>
      </CannyValleyLayout>
    )

  return (
    <CannyValleyLayout className="post-moodboard-page">
      <div className="post-moodboard-wrapper">
        <div className="header-title">We’ll see you soon!</div>
        <p className="normal-text mt-16px">
          You're confirmed for a wedding floral consult on Zoom with {owner?.firstName} on{' '}
          {moment(calendlyEvent.start_time).format('MMMM DD, YYYY [at] hh:mm a')}. Look out for an email from{' '}
          {owner?.firstName} with all the details.
        </p>
        <div className="wedding-specialist">
          <div className="avatar">
            <img src={owner?.avatar} />
          </div>
          <div className="details">
            <div className="title">YOUR WEDDING SPECIALIST</div>
            <div className="owner-name mt-5px">
              {owner?.firstName} {owner?.lastName}
            </div>
            <div className="normal-text mt-5px">{owner?.location}</div>
            <div className="normal-text mt-3px">{owner?.description}</div>
          </div>
        </div>
        <p className="normal-text mt-16px">Can’t get enough flowers? Browse our portfolio to get inspired.</p>
        <div className="actions mt-32px">
          <a className="button-stem" href="https://poppyflowers.com/real-weddings">
            Let’s go!
          </a>
          <a
            className="button-stem secondary"
            href={proposal?.proposalUrl?.replace(/\/landing\?|\/overview\?/, '/proposal-totals?')}
          >
            RESERVE MY DATE
          </a>
        </div>
      </div>
    </CannyValleyLayout>
  )
}
