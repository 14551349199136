//Hook

import { useEffect } from 'react'

export default function useOnClickOutside(
  refs: React.RefObject<HTMLElement> | React.RefObject<HTMLElement>[],
  handler: (e: React.MouseEvent<HTMLElement>) => void
) {
  useEffect(
    () => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const listener = (event: any) => {
        // Do nothing if clicking ref's element or descendent elements
        if (Array.isArray(refs)) {
          const isClickOutsideAll = refs.every(
            (ref) => !ref.current || (!!ref.current && !ref.current.contains(event.target))
          )
          if (!isClickOutsideAll) {
            return
          }
        } else if (!refs.current || refs.current.contains(event.target)) {
          return
        }
        handler(event)
      }

      document.addEventListener('mousedown', listener)
      document.addEventListener('touchstart', listener)

      return () => {
        document.removeEventListener('mousedown', listener)
        document.removeEventListener('touchstart', listener)
      }
    },
    // Add ref and handler to effect dependencies
    // It's worth noting that because the passed-in handler is a new ...
    // ... function on every render that will cause this effect ...
    // ... callback/cleanup to run every render. It's not a big deal ...
    // ... but to optimize you can wrap handler in useCallback before ...
    // ... passing it into this hook.
    [refs, handler]
  )
}
