import React from 'react'
import { QuizQuestionWrapper } from '../QuizQuestionWrapper'
import guineaPigBg from 'src/assets/images/guinea-pig-bg.png'
import { useGuineaPig } from './GuineaPig.hook'
import './GuineaPigStyle.scss'

export const GuineaPig: React.FC = () => {
  const { handleNext, nextDisabled } = useGuineaPig()
  return (
    <QuizQuestionWrapper titleStep="Guinea pig" className="guinea-pig-wrapper" backAction={{ disabled: true }}>
      <div className="guinea-pig">
        <div className="left-part">
          <img src={guineaPigBg} alt="Hey, we've met before" className="bg-image" />
        </div>
        <div className="right-part part-bg">
          <div>
            <h2 className="title">How do you want to get started?</h2>
            <div className="content-above-box">
              <p className="subtitle">I want an expert to prepare my proposal</p>
              <p className="description">
                Give us more information about your wedding and speak with one of our designers to receive an expertly
                curated proposal
              </p>
              <div className="action-box-wrapper">
                <button
                  disabled={nextDisabled}
                  className="button white-space-wrap btn-primary"
                  onClick={() => handleNext(1)}
                >
                  Get started
                </button>
              </div>
            </div>
            <div className="content-bellow-box">
              <h3 className="subtitle">I’m ready to shop flowers</h3>
              <p className="description">Shop our recommendations based on your color palette and budget.</p>
              <div className="action-box-wrapper">
                <button
                  disabled={nextDisabled}
                  className="button white-space-wrap btn-secondary"
                  onClick={() => handleNext(2)}
                >
                  Shop recommendations
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </QuizQuestionWrapper>
  )
}
