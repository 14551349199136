import React, { useEffect, useState } from 'react'
import { postMicroSurveyZapier } from 'src/api/zapier'
import { Button } from 'src/common'
import { ModalStyle } from 'src/common/components/modal/ModalStyle'
import { getCookie, setCookie } from 'src/helpers/cookies'
import { useNormalResult } from '../../ResultsPage.hook'
import './MicroSurvey.scss'

export const MicroSurvey: React.FC = () => {
  const { customerFullInfo } = useNormalResult()
  const [open, setOpen] = useState<boolean>(false)
  // only display this popup one time
  const [isShow, setIsShow] = useState<boolean>(false)
  const [answer, setAnswer] = useState<string>('')

  const onSubmitSurvey = async () => {
    try {
      await postMicroSurveyZapier({
        answer,
        email: customerFullInfo?.customerData.email,
        colorPalette: customerFullInfo?.colorPalette.name,
      })
      setOpen(false)
    } catch (error) {
      console.error('Post zapier failed: ', error)
    }
  }

  const onChangeAnswer = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setAnswer(event.target.value)
  }

  useEffect(() => {
    const handleMouseOut = (e: MouseEvent) => {
      if (isShow) return
      if (!e.relatedTarget) {
        setOpen(true)
        setIsShow(true)
        // Set the cookie when the popup is shown to the user
        setCookie('exitIntentShown', true, 30)
      }
    }
    if (!getCookie('exitIntentShown')) {
      document.addEventListener('mouseout', handleMouseOut)
      return () => {
        document.removeEventListener('mouseout', handleMouseOut)
      }
    }
  }, [isShow])

  return (
    <ModalStyle isOpen={open} handleClose={() => setOpen(false)} className="survey-modal">
      <div className="survey-wrapper">
        <div className="content">
          <h3 className="title">What can we improve about your quiz results?</h3>
          <div className="mb-20rem">
            <textarea className="full-width" placeholder="Answer here" value={answer} onChange={onChangeAnswer} />
          </div>
          <div className="">
            <Button className="button button-basic" onClick={onSubmitSurvey}>
              Submit
            </Button>
          </div>
        </div>
      </div>
    </ModalStyle>
  )
}
