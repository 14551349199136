import React from 'react'
import { GorgiasChat } from 'src/common/components/embed'
import { AsSeenIn } from '../results/AsSeenIn'
import { Congratulations, Products, WantMore, WorkingProcess } from './components'
// import { ExperiencePopup } from '../shared/ExperiencePopup/ExperiencePopup'
import { NavigationHeader } from './components/NavigationHeader'
import { CustomerExperienceProvider } from './context'

import './CustomerExperience.scss'
import { useCustomerExperience } from './useCustomerExperience'
// import { useAppContext } from 'src/helpers/app'

const CustomerExperience: React.FC = () => {
  useCustomerExperience()
  // const { appData } = useAppContext()

  return (
    <div className="customer_experience">
      <NavigationHeader />
      <Congratulations />
      <WorkingProcess className="desktop" />
      <Products />
      <WorkingProcess className="mobile" />
      <WantMore />
      <AsSeenIn />
      <GorgiasChat />
      {/* <ExperiencePopup triggerElmId="want_more" templateId={appData.customerFullInfo?.templateId} /> */}
    </div>
  )
}

export const CustomerExperiencePage: React.FC = () => {
  return (
    <CustomerExperienceProvider>
      <CustomerExperience />
    </CustomerExperienceProvider>
  )
}
