import React from 'react'
import { ImageWithLogoLazy } from 'src/common'

import './AsSeenIn.scss'

export const AsSeenIn = () => {
  return (
    <div className="as-seen-in">
      <div className="div-block-164">
        <div className="red-horizontal-rule" />
        <h4 className="heading-82">AS&nbsp;SEEN&nbsp;IN</h4>
        <div className="red-horizontal-rule" />
      </div>
      <div className="div-block-165">
        <ImageWithLogoLazy
          src="https://assets.website-files.com/5e0784a90d37a1952324a9a5/609d8487a47988ffe75c5408_The%20Knot%201.png"
          loading="lazy"
          alt=""
          className="image-93 hidden-sm"
        />
        <ImageWithLogoLazy
          src="https://assets.website-files.com/5e0784a90d37a1952324a9a5/609d8487d80c08f14024a6ab_og-logo-vf_BW%20copy%201.png"
          loading="lazy"
          alt=""
          className="image-93 hidden-sm"
        />
        <ImageWithLogoLazy
          src="https://assets.website-files.com/5e0784a90d37a1952324a9a5/609d8487cf326e49515e4601_Today-Logo_Grayscale%201.png"
          loading="lazy"
          alt=""
          className="image-93 hidden-sm"
        />
        <ImageWithLogoLazy
          src="https://assets.website-files.com/5e0784a90d37a1952324a9a5/609d8487866f090f79b07f8d_martha-stewart-weddings%201.png"
          loading="lazy"
          alt=""
          className="image-93 hidden-sm"
        />
        <ImageWithLogoLazy
          src="https://assets.website-files.com/5e0784a90d37a1952324a9a5/609d8487866f090f79b07f8d_martha-stewart-weddings%201.png"
          loading="lazy"
          alt=""
          className="image-93 show-sm"
        />
        <ImageWithLogoLazy
          src="https://assets.website-files.com/5e0784a90d37a1952324a9a5/609d8487a47988ffe75c5408_The%20Knot%201.png"
          loading="lazy"
          alt=""
          className="image-93 show-sm"
        />
        <ImageWithLogoLazy
          src="https://assets.website-files.com/5e0784a90d37a1952324a9a5/609d8487cf326e49515e4601_Today-Logo_Grayscale%201.png"
          loading="lazy"
          alt=""
          className="image-93 show-sm"
        />
        <ImageWithLogoLazy
          src="https://assets.website-files.com/5e0784a90d37a1952324a9a5/609d8487d80c08f14024a6ab_og-logo-vf_BW%20copy%201.png"
          loading="lazy"
          alt=""
          className="image-93 show-sm"
        />
      </div>
    </div>
  )
}
