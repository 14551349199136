import React from 'react'
import styled from 'styled-components'

import { TickRedIcon } from 'src/common'
import { TickWhiteIcon } from 'src/common/icons'

const TickWrapper = styled.div`
  border-radius: 50%;
  border: 2px solid #d7462d;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  height: 38px;
  width: 38px;

  &.checked {
    background: #d7462d;
  }
`

interface Props {
  checked?: boolean
}

export const Tick = (props: Props) => {
  return (
    <TickWrapper className={`tick ${props.checked ? 'checked' : ''}`}>
      {props.checked ? <TickWhiteIcon /> : <TickRedIcon />}
    </TickWrapper>
  )
}
