import React, { PropsWithChildren } from 'react'
import ReactModal from 'react-modal'
import { CloseIcon } from 'src/common'

// Make sure to bind modal to your appElement (http://reactcommunity.org/react-modal/accessibility/)
ReactModal.setAppElement('#root')

export type IModalBaseProps = PropsWithChildren<{
  isOpen: boolean
  classModal?: string
  hiddenClose?: boolean
  closeTimeoutMS?: number
  closeElm?: JSX.Element
  handleClose?: () => void
}>

export const ModalBase: React.FC<IModalBaseProps> = (props: IModalBaseProps) => {
  const { handleClose, isOpen, classModal, hiddenClose, closeElm, children, closeTimeoutMS } = props
  return (
    <ReactModal
      isOpen={isOpen}
      className={`${classModal ?? 'modal-base'}`}
      style={{
        overlay: {
          backgroundColor: '#F2F1EA',
        },
      }}
      closeTimeoutMS={closeTimeoutMS}
    >
      {!hiddenClose && (
        <p className="modal-close" onClick={handleClose}>
          <CloseIcon />
        </p>
      )}
      {closeElm && closeElm}

      {children}
    </ReactModal>
  )
}
