import { PATH_API } from 'src/helpers'
import { ICustomerFullInfo } from 'src/helpers/app'
import { getHttp, postHttp } from './apiLayer'

interface RecordDuplicateReasonPayload {
  sessionId: string
  reason: string[]
  additionalNotes: string
  proposalId?: number
}

export const getCustomerInfo = async (sessionId: string): Promise<ICustomerFullInfo> => {
  const response = await getHttp(PATH_API.getCustomerInfo(sessionId))

  return response.data
}

export const recordDuplicateReason = async (data: RecordDuplicateReasonPayload): Promise<ICustomerFullInfo> => {
  const response = await postHttp(PATH_API.recordDuplicateSession, data)

  return response.data
}
