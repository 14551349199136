import React from 'react'
import { formatCurrency } from 'src/helpers'
import { useAppContext } from 'src/helpers/app'
import { useCustomerExperienceContext } from '../context'
import { handleShowNameOption } from '../helper'

const MIN_CHECKOUT = 300 * 100

export const Cart = () => {
  const { cart, setShowCheckoutPopup } = useCustomerExperienceContext()
  const { appData } = useAppContext()

  const cartArr = Object.keys(cart)
  let total = 0

  return (
    <div className="cart-wrapper">
      <div className="cart-title">Item Summary</div>
      <div className="line"></div>
      <div className="cart-products">
        {cartArr.map((key) => {
          const price = cart[key].quantity * cart[key].product.price
          total += price

          return (
            <div key={key} className="cart-item">
              <div className="item-name">{`(${cart[key].quantity}) ${handleShowNameOption(key)}`}</div>
              <div className="item-price">{formatCurrency(price)}</div>
            </div>
          )
        })}
      </div>
      <div className="line"></div>
      <div className="cart-item cart-total">
        <div className="item-name">Total</div>
        <div className="item-price">{formatCurrency(total)}*</div>
      </div>
      <button
        disabled={total < MIN_CHECKOUT}
        onClick={() => setShowCheckoutPopup(true)}
        className="button button-basic button-checkout"
      >
        Checkout
      </button>
      <p className="note">$300 minimum on flowers ● $100 delivery fee</p>
      {appData.customerFullInfo?.photographerAttribution && (
        <p className="photographer">Photography courtesy of {appData.customerFullInfo?.photographerAttribution}</p>
      )}
    </div>
  )
}
