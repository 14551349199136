export function setCookie(name: string, value: unknown, days: number) {
  let expires = ''

  if (days) {
    const date = new Date()
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000)
    expires = '; expires=' + date.toUTCString()
  }

  document.cookie = name + '=' + (value || '') + expires + ';'
}

export function getCookie(name: string) {
  const cookies = document.cookie.split(';')

  for (const cookie of cookies) {
    if (cookie.indexOf(name + '=') > -1) {
      return cookie.split('=')[1]
    }
  }

  return null
}
