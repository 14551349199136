export const REGEX = {
  email: /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i,
  leastOneLetter: /\w*[a-zA-Z]\w*/,
}

export const validate = {
  email: (email: string) => {
    return REGEX.email.test(String(email).toLowerCase())
  },
  leastOneLetter: (value: string) => {
    return REGEX.leastOneLetter.test(value)
  },
}
