import React from 'react'

import { Button, Radio } from 'src/common'
import { TableOptionManySelect } from 'src/common/components/select'
import { useGuestsAndTableExpect } from './GuestsAndTableExpect.hook'
import roundTables from 'src/assets/images/round_table.png'
import farmTables from 'src/assets/images/farm_table.png'
import { QuizQuestionWrapper } from './QuizQuestionWrapper'

export const GuestsAndTableExpect = () => {
  const {
    guestList,
    indexNumberGuests,
    nextDisabled,
    handleDismiss,
    handleNext,
    handleBack,
    handleChangeGuests,
    tableTypeSelected,
    handleSelectTable,
    notSureYet,
  } = useGuestsAndTableExpect()

  return (
    <>
      <QuizQuestionWrapper
        title="guests"
        nextAction={{
          disabled: nextDisabled,
          onAction: handleNext,
        }}
        backAction={{
          onAction: handleBack,
        }}
        step={3}
        total={4}
      >
        <div className="quiz-guest-expect">
          <div className="guest-and-table-wrapper">
            <div className="quiz-question-header">How many guests will you have?</div>
            <div className="guests-wrapper text-transform-upper">
              {guestList.map((item, index) => (
                <Radio
                  key={item.value}
                  id={item.label}
                  value={item.value}
                  name="guests"
                  classNames={{ container: 'ml-0px mr-20px', label: 'basic' }}
                  checked={indexNumberGuests === index}
                  onChange={handleChangeGuests}
                >
                  {item.label}
                </Radio>
              ))}
            </div>
            <div className="quiz-question-header mt-16px">Where will guests be seated?</div>
            <div className="table-wrapper">
              <TableOptionManySelect
                options={[
                  { id: 'Round', img: roundTables, label: 'Round Tables' },
                  { id: 'Farm', img: farmTables, label: 'Farm Tables' },
                ]}
                onSelect={handleSelectTable}
                defaultActives={tableTypeSelected}
              />
            </div>
          </div>
          <div className="text-align-center mt-48px button-wrapper">
            <Button className={`button transparent pl-50px pr-50px w-100 ${notSureYet}`} onClick={handleDismiss}>
              NOT SURE YET
            </Button>
          </div>
          <div className="mt-20px">
            <button disabled={nextDisabled} className="button button-primary mt-14px" onClick={handleNext}>
              CONTINUE TO FLOWERS
            </button>
          </div>
        </div>
      </QuizQuestionWrapper>
    </>
  )
}
