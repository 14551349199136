import { useEffect, useState } from 'react'
import copy from 'copy-to-clipboard'

import { useAppContext } from 'src/helpers/app'

import { useResultsPage } from '../results/ResultsPage.hook'

export const useCallScheduled = () => {
  const { customerFullInfo, infoProposal, searchObject } = useResultsPage()
  const { appData } = useAppContext()

  const [isRegularPage, setIsRegularPage] = useState(true)
  const [isCopy, setIsCopy] = useState(false)

  useEffect(() => {
    if (searchObject && searchObject.shared === 'true') {
      setIsRegularPage(false)
    }
  }, [searchObject])

  const handleCopy = () => {
    try {
      const urlCopy = new URL(window.location.href)
      urlCopy.searchParams.append('shared', 'true')
      copy(urlCopy.toString())
      setIsCopy(true)
    } catch (error) {
      window.alert('Copy failed')
    }
  }

  const textShared = isCopy ? 'Link Copied!' : 'share your results'

  return {
    textShared,
    isRegularPage,
    customerFullInfo,
    infoProposal,
    scheduledEventInfo: appData.scheduledEventInfo,
    handleCopy,
  }
}
