import React from 'react'

import { Button } from 'src/common'
import arrowLeftIcon from 'src/assets/images/cannyValley/arrow-left.svg'
import circleArrowIcon from 'src/assets/images/cannyValley/circle-arrow.svg'
import { CannyValleyLayout } from '../../../../common/components/Layout'
import { QuizStep } from '../QuizStep'
import { PaletteImage } from './PaletteImage'
import { useChoosePalette } from './useChoosePalette'

import './style.scss'

export const ChoosePalette: React.FC = () => {
  const {
    currentPaletteIndex,
    colorPalettes,
    handleNextPalette,
    handlePrePalette,
    handleNext,
    handleBack,
  } = useChoosePalette()

  return (
    <CannyValleyLayout>
      <div className="choose-palette-page">
        <div className="step-header">
          <QuizStep item={1} step={1} />
        </div>
        <div className="choose-palette-content">
          <div className="palette-content">
            <div className="top-actions desktop">
              <div className="back-action" onClick={handleBack}>
                <img src={arrowLeftIcon} />
                BACK
              </div>
            </div>

            <div className="choose-header">
              <div className="step-title-header">
                <div className="top-actions mobile">
                  <div className="back-action" onClick={handleBack}>
                    <img src={arrowLeftIcon} />
                  </div>
                </div>
                <h3 className="step-title">Choose your base palette</h3>
              </div>
              <p className="step-description mt-16px">
                Choose one of our {colorPalettes.length} base palettes for the foundation of your color story.
              </p>
            </div>

            <div className="palette desktop mt-40px">
              <h3 className="palette-title">{colorPalettes[currentPaletteIndex]?.name}</h3>
              <p className="palette-description">
                {colorPalettes[currentPaletteIndex]?.description} ({currentPaletteIndex + 1}/{colorPalettes.length})
              </p>
              <div className="action-slider mt-16px">
                <div className="action-item" onClick={handlePrePalette}>
                  <img src={circleArrowIcon} />
                </div>
                <div className="action-item" onClick={handleNextPalette}>
                  <img src={circleArrowIcon} className="rotate-right" />
                </div>
              </div>

              <Button className="button-stem mt-40px" onClick={handleNext}>
                CUSTOMIZE THIS PALETTE
              </Button>
            </div>
          </div>

          <div className="palette-image-group">
            <PaletteImage palette={colorPalettes[currentPaletteIndex]} />
          </div>

          <div className="palette mobile">
            <div className="palette-group">
              <div className="action-btn" onClick={handlePrePalette}>
                <img src={circleArrowIcon} />
              </div>
              <div>
                <h3 className="palette-title">{colorPalettes[currentPaletteIndex]?.name}</h3>
                <p className="palette-description">
                  {colorPalettes[currentPaletteIndex]?.description} ({currentPaletteIndex + 1}/{colorPalettes.length})
                </p>
              </div>
              <div></div>

              <div className="action-btn" onClick={handleNextPalette}>
                <img src={circleArrowIcon} className="rotate-right" />
              </div>
            </div>

            <div className="palette-group mt-20px">
              <Button className="button-stem" onClick={handleNext}>
                CUSTOMIZE THIS PALETTE
              </Button>
            </div>
          </div>
        </div>
      </div>
    </CannyValleyLayout>
  )
}
