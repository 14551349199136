import { useContext, useEffect, useState } from 'react'
import { cloneDeep, redirect, REDIRECT_PATH } from 'src/helpers'
import { useAppContext } from 'src/helpers/app'
import { EVENT_LOCATION_TYPE, ILocationQuiz } from 'src/types'
import { QuizQuestionContext, SCREEN_QUIZ_QUESTION } from '../../contextAPI'

export const defaultLocation: ILocationQuiz = {
  type: EVENT_LOCATION_TYPE.CEREMONY,
  place: '',

  venue: '',
  city: '',
  state: '',
  streetAddress: '',
  zipCode: '',
}

export const useLocation = () => {
  const { data, setQuizScreen, setQuizData } = useContext(QuizQuestionContext)
  const { handleChangeAppData } = useAppContext()
  const [locationSelected, setLocationSelected] = useState<ILocationQuiz[]>(
    data.location ? cloneDeep(data.location) : [{ ...defaultLocation }]
  )
  const [hasValue, setHasValue] = useState(!!data.location)

  const handleNext = async () => {
    handleChangeAppData({ isLoading: true })
    const recordResponse = await setQuizData({ location: locationSelected })

    if (recordResponse && recordResponse.data && recordResponse.data.serviceable === false) {
      redirect(recordResponse.data.redirectLink || REDIRECT_PATH.UNSERVICEABLE)
      return
    } else {
      handleChangeAppData({ isLoading: false })
      setQuizScreen(SCREEN_QUIZ_QUESTION.GUESTS_AND_TABLE_EXPECT)
    }
  }

  const handleBack = () => {
    setQuizScreen(SCREEN_QUIZ_QUESTION.YOU_BOOKED)
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>, index: number) => {
    event.preventDefault()
    const { value, name } = event.target
    const temp = [...locationSelected]
    temp[index][name as 'place'] = value

    setLocationSelected(temp)
    setHasValue(!!value)
  }

  const handleAddVenue = () => {
    setLocationSelected((data) => {
      const locations = []

      locations[0] = data[0]

      if (data[1] === undefined) {
        locations[1] = { ...defaultLocation, type: EVENT_LOCATION_TYPE.RECEPTION }
      }

      return locations as ILocationQuiz[]
    })
  }

  const handleRemoveVenue = (venueIndex: number) => {
    setLocationSelected(locationSelected.filter((_, index) => index !== venueIndex))
  }

  /* eslint-disable @typescript-eslint/no-explicit-any */
  const handleGGPlaceSelected = (place: any, index: number) => {
    let streetNumber = '',
      route = '',
      city = '',
      state = '',
      zipCode = '',
      country = ''

    /* eslint-disable @typescript-eslint/no-explicit-any */
    place?.address_components?.forEach((component: any) => {
      const componentType = component.types[0]
      switch (componentType) {
        case 'street_number': {
          streetNumber = component.long_name
          break
        }
        case 'route': {
          route = component.long_name
          break
        }
        case 'postal_code': {
          zipCode = component.long_name
          break
        }
        case 'locality':
          city = component.long_name
          break
        case 'administrative_area_level_1': {
          state = component.short_name
          break
        }
        case 'country':
          country = component.short_name
          break
      }
    })
    const temp = [...locationSelected]
    temp[index] = {
      ...temp[index],
      city,
      state,
      zipCode,
      streetAddress: `${streetNumber} ${route}`,
      place: [place?.name, place?.formatted_address].filter((i) => i).join(', ') || '',
      venue: place?.name || '',
      placeId: place.place_id,
      country,
    }
    setLocationSelected(temp)
  }

  const nextDisabled = [...locationSelected].some((value) => value.place.trim() === '')

  useEffect(() => {
    if (Array.isArray(data.location) && data.location.length > 0) {
      setLocationSelected(cloneDeep(data.location))
      setHasValue(data.location.some((loc) => !!loc.place))
    }
  }, [data.location])

  return {
    locationSelected,
    setLocationSelected,
    nextDisabled,
    hasValue,
    handleAddVenue,
    handleRemoveVenue,
    handleChange,
    handleBack,
    handleNext,
    handleGGPlaceSelected,
  }
}
