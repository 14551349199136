import { useContext, useMemo, useState } from 'react'
import { useAppContext } from 'src/helpers/app'
import { IBooleanDynamicPrototype, IOption } from 'src/types'
import { QuizQuestionContext, SCREEN_QUIZ_QUESTION } from '../contextAPI'

const partnerList: IOption[] = [
  {
    label: '1 bouquet, 1 boutonnière',
    value: 'bouquet_boutonniere',
  },
  {
    label: '2 bouquets',
    value: 'two_bouquets',
  },
  {
    label: '2 boutonnières',
    value: 'two_boutonnieres',
  },
  {
    label: 'none',
    value: 'nothing',
  },
]

const ritualList: IOption[] = [
  {
    label: 'none',
    value: 'NOTHING',
  },
  {
    label: 'hindu',
    value: 'HINDU',
  },
  {
    label: 'muslim',
    value: 'MUSLIM',
  },
  {
    label: 'christian',
    value: 'CHRISTIAN',
  },
  {
    label: 'jewish',
    value: 'JEWISH',
  },
  {
    label: 'Something else',
    value: 'OTHER',
  },
]

export const useFlowersWedding = () => {
  const { appData } = useAppContext()

  const { data, setQuizScreen, setQuizData } = useContext(QuizQuestionContext)
  const [partner, setPartner] = useState(data.flowerWedding?.partner ?? '')
  const [ritualsSelected, setRitualsSelected] = useState<IBooleanDynamicPrototype>(data.ritual?.type || {})
  const [bouquets, setBouquets] = useState(data.flowerWedding?.bouquets ?? 1)
  const [boutonnieres, setBoutonnieres] = useState(data.flowerWedding?.boutonnieres ?? 1)

  const handleNext = () => {
    setQuizData({
      flowerWedding: {
        partner,
        bouquets,
        boutonnieres,
      },
    })

    if (appData.isMicroWedding) {
      setQuizScreen(SCREEN_QUIZ_QUESTION.COLORS_FLOWERS)
    } else {
      setQuizScreen(SCREEN_QUIZ_QUESTION.SEE_ITEM_PRICE)
    }
  }

  const handleBack = () => {
    setQuizScreen(SCREEN_QUIZ_QUESTION.GUESTS_AND_TABLE_EXPECT)
  }

  const handleChangePartner = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPartner(event.target.value)
  }

  const handleSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target

    setRitualsSelected((list) => ({ ...list, [value]: !list[value] }))
  }

  const handleChangeBouquets = (value: number) => {
    setBouquets(value >= 0 ? value : 0)
  }

  const handleChangeBoutonnieres = (value: number) => {
    setBoutonnieres(value >= 0 ? value : 0)
  }

  const nextDisabled = useMemo(() => {
    return partner.trim() === '' || !Object.keys(ritualsSelected).some((key) => ritualsSelected[key])
  }, [partner, ritualsSelected])

  return {
    partner,
    ritualsSelected,
    bouquets,
    boutonnieres,
    nextDisabled,
    partnerList,
    ritualList,
    setBouquets,
    setBoutonnieres,
    handleChangePartner,
    handleSelect,
    handleChangeBoutonnieres,
    handleChangeBouquets,
    handleBack,
    handleNext,
  }
}
