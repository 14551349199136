import React from 'react'
import { QuizQuestionWrapper } from '../QuizQuestionWrapper'
import metBeforeAfterSubmittedBg from 'src/assets/images/met-before-after-submitted-bg.png'
import metBeforeAfterSubmittedMobileBg from 'src/assets/images/met-before-after-submitted-mobile-bg.png'
import flowerBg from 'src/assets/images/Lupine_Curved_Grayscale_2.png'
import { isMobile } from 'src/helpers'
import './style.scss'

export const MetBeforeAfterSubmitted: React.FC = () => {
  const proposalUrlTemp = sessionStorage.getItem('proposalUrl')

  const proposalUrl = proposalUrlTemp ? new URL(proposalUrlTemp)?.origin : 'https://www.customers.poppyflowers.com'

  const openChangeColorPalette = () => {
    window.location.href = `${proposalUrl}/proposal#details?show-color-palette=1`
  }

  const openMessageToFloralConsultant = () => {
    window.location.href = `${proposalUrl}/proposal#details?show-message=1`
  }

  return (
    <QuizQuestionWrapper noHeaderTop className="met-before-after-submitted">
      <div className="met-before-wrapper">
        <div className="left-part">
          <img
            src={isMobile ? metBeforeAfterSubmittedMobileBg : metBeforeAfterSubmittedBg}
            alt="Hey, we've met before"
            className="bg-image"
          />
        </div>
        <div className="right-part">
          <div>
            <h2 className="title hidden-mobile">Want new options? Piece of cake.</h2>
            <h2 className="title hidden-desktop">
              Want new options? <br />
              Piece of cake.
            </h2>
            <p className="description">
              You can add or edit your color palette directly from your proposal. Need a different change? Send us a
              note.
            </p>
            <div className="action-box-wrapper mt-30px mb-90px">
              <button className="button white-space-wrap" onClick={openChangeColorPalette}>
                Change Palette
              </button>
              <button className="button btn-secondary white-space-wrap" onClick={openMessageToFloralConsultant}>
                Message Us
              </button>
            </div>
          </div>
        </div>
        <img src={flowerBg} alt="flower bg" className="flower-bg" />
      </div>
    </QuizQuestionWrapper>
  )
}
