import React, { useEffect, useRef, useState } from 'react'

import { ModalBase } from 'src/common'
import { Flower } from './useSampleFlower'
import { StemPreferenceValue } from 'src/types'
import useOnClickOutside from 'src/helpers/hooks/useOnClickOutside'
import { STEM_ALT_COLORS_AVAILABLE } from 'src/pages/shared'

type SampleFlowerPreviewProps = {
  flowers: Flower[]
  selectStemPreference: (stemId: number, value: StemPreferenceValue) => void
}

const getClassFlowerItem = (preference?: StemPreferenceValue | string) => {
  switch (preference) {
    case StemPreferenceValue.EXCLUDE:
      return 'gray-out'
    case StemPreferenceValue.LIKE:
      return 'like'
    case StemPreferenceValue.LOVE:
      return 'love'

    default:
      return ''
  }
}

type FlowerModal = {
  open: boolean
  flower: Flower | null
  selectedIndex: number
}

type PreviewModalProps = {
  open: boolean
  flower: Flower | null
  selectedIndex: number
  closeModal: () => void
  changePreferenceValue: (stemId: number, value: StemPreferenceValue) => void
}

const PreviewModal: React.FC<PreviewModalProps> = ({
  open,
  flower,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  selectedIndex,
  closeModal,
  changePreferenceValue,
}: PreviewModalProps) => {
  const [reviewAction, setReviewAction] = useState<StemPreferenceValue | undefined>(flower?.value)
  const flowerRef = useRef<HTMLDivElement>(null)
  const contentRef = useRef<HTMLDivElement>(null)
  useOnClickOutside([flowerRef, contentRef], () => closeModal())
  const altColorsAvailable = STEM_ALT_COLORS_AVAILABLE.includes(flower?.stem.typeName || '')

  const handleClose = () => {
    closeModal()
  }
  const handleClickAction = (value: StemPreferenceValue) => {
    setReviewAction(value)
    if (flower && value) changePreferenceValue(flower.stemId, value)
    closeModal()
  }

  useEffect(() => {
    setReviewAction(flower?.value)
  }, [flower])

  return (
    <ModalBase
      isOpen={open}
      handleClose={handleClose}
      classModal="modal-base sample-flower-preview-modal"
      hiddenClose
      closeTimeoutMS={700}
    >
      <div className="flower-preview-wrapper">
        <div className="flower-circle" ref={flowerRef}>
          <div className="circle-item">
            <img src={flower?.stem.imageUrl} />
          </div>
        </div>
        <div className="flower-preview-content" ref={contentRef}>
          <div className="close-text" onClick={handleClose}>
            <span>Close</span>
          </div>
          <div className="flower-name">{flower?.stem.typeName || flower?.stem.name}</div>
          {altColorsAvailable && <div className="alt-colors-available">Available in additional colors</div>}
          <div className="description">{flower?.stem.description}</div>
          <div className="actions mt-16px">
            <div
              className={`item description ${reviewAction === StemPreferenceValue.LIKE ? 'selected' : ''}`}
              onClick={() => handleClickAction(StemPreferenceValue.LIKE)}
            >
              Like them
            </div>
            <div
              className={`item description ${reviewAction === StemPreferenceValue.LOVE ? 'selected' : ''}`}
              onClick={() => handleClickAction(StemPreferenceValue.LOVE)}
            >
              Love them
            </div>
            <div
              className={`item description ${reviewAction === StemPreferenceValue.EXCLUDE ? 'selected' : ''}`}
              onClick={() => handleClickAction(StemPreferenceValue.EXCLUDE)}
            >
              Exclude from my order
            </div>
          </div>
        </div>
      </div>
    </ModalBase>
  )
}

export const SampleFlowerPreview: React.FC<SampleFlowerPreviewProps> = ({
  flowers,
  selectStemPreference,
}: SampleFlowerPreviewProps) => {
  const [flowerModal, setFlowerModal] = useState<FlowerModal>({ open: false, flower: null, selectedIndex: -1 })

  const reviewFlower = (index: number) => {
    setFlowerModal({ open: true, flower: flowers[index], selectedIndex: index })
  }
  const closeModal = () => {
    setFlowerModal((pre) => ({ ...pre, open: false }))
  }

  return (
    <>
      <div className="sample-flower-preview">
        <div className="sample-flower-grid-wrapper">
          {flowers.map((flower, index) => (
            <div
              key={flower.stem.id}
              className={`flower-grid-item --${index + 1} ${getClassFlowerItem(flower.value)}`}
              onClick={() => reviewFlower(index)}
            >
              <img src={flower.stem.imageUrl} />
            </div>
          ))}
        </div>
      </div>
      <PreviewModal
        open={flowerModal.open}
        flower={flowerModal.flower}
        selectedIndex={flowerModal.selectedIndex}
        closeModal={closeModal}
        changePreferenceValue={selectStemPreference}
      />
    </>
  )
}
