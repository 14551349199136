import React, { PropsWithChildren, useState } from 'react'
import Slider from 'react-slick'
import { ModalBase } from '../modal'
import './ImageGallery.scss'

interface ImageGalleryProps {
  images: string[]
  showImgNumber?: number
  hideOverlay?: boolean
  disablePreview?: boolean
}

export const ImageGallery: React.FC<ImageGalleryProps> = (props: PropsWithChildren<ImageGalleryProps>) => {
  const [openModal, setOpenModal] = useState<boolean>(false)

  const numberImgToShow = props.showImgNumber || 6
  const imagesToRender = props.images.slice(0, props.showImgNumber)
  if (numberImgToShow <= 0 || numberImgToShow > 6) {
    console.warn('showImgNumber is limited to 6!')
  }

  const handleClickImage = () => {
    if (props.disablePreview) return
    setOpenModal(true)
  }

  const renderOverlay = (position: number) => {
    if (props.hideOverlay) return
    if (props.images.length > numberImgToShow && position === imagesToRender.length - 1) {
      const extra = props.images.length - numberImgToShow
      return (
        <div className="cover">
          <div className="cover-text">
            <p>+{extra}</p>
          </div>
        </div>
      )
    }
  }

  return (
    <div className={`gallery gallery--${numberImgToShow}`}>
      {imagesToRender.map((image, index) => (
        <figure
          key={image}
          className={`gallery__item gallery__item--${index + 1} ${!props.disablePreview ? 'pointer' : ''}`}
          onClick={handleClickImage}
        >
          <img src={image} className="gallery__img" alt={`Image ${index + 1}`} loading="lazy" />
          {renderOverlay(index)}
        </figure>
      ))}
      {!props.disablePreview && (
        <ModalBase
          isOpen={openModal}
          handleClose={() => setOpenModal(false)}
          classModal="modal-base preview-image-modal"
        >
          <Slider infinite slidesToShow={1} dots={false}>
            {props.images.map((image, index) => (
              <div key={image} className="image-preview-item">
                <div>
                  <img src={image} alt={`Image ${index + 1}`} className="image-preview" />
                </div>
              </div>
            ))}
          </Slider>
        </ModalBase>
      )}
    </div>
  )
}
