import { useContext, useMemo, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { ENV } from 'src/config/env'

import { EVENTS_FB_PIXEL, fbEvent, PATH_API, PATH_URL, splitFullName } from 'src/helpers'
import { postHttp } from 'src/api/apiLayer'
import { AppContext } from 'src/helpers/app'
import { ACTION_APP, ga4EventApp, gaEventApp } from 'src/helpers/googleAnalyticsService'
import { SegmentAction, segmentTracking, segmentIdentify } from 'src/helpers/segment'
import { API } from 'src/types'

import { QuizQuestionContext, SCREEN_QUIZ_QUESTION } from '../../contextAPI'
import { useCustomer } from './useCustomer'
import { vwoEvent } from 'src/helpers/vwo'

export enum FIELD_NAMES {
  OTHER_INFORMATION = 'otherInformation',
  PINTEREST = 'pinterest',
}

interface ISubmitQuizQuestionPayload {
  sessionId: string
  firstName: string
  lastName: string
  emailAddress: string
  phoneNumber: string
  attributionSource?: string
}

interface ISubmitQuizQuestionResponse {
  redirectLink: string
  integratedFireworkExperience: boolean
  premerchandizedKits: boolean
  isActivePremerchandizedKits: boolean
}

const submitQuizResponseAPI = (submitData: ISubmitQuizQuestionPayload) => {
  return postHttp(PATH_API.postSubmit, submitData)
}

export const useAnything = () => {
  const customerHelpers = useCustomer()
  const history = useHistory()

  const { fullName, phoneNumber, attributionSource, emailAddress } = customerHelpers.customerData
  const [firstName, lastName] = splitFullName(fullName || '')

  const { data, setQuizData, setQuizScreen } = useContext(QuizQuestionContext)

  const { sessionID, handleChangeAppData, appData } = useContext(AppContext)
  const [otherInformation, setOtherInformation] = useState<string>(data.otherInformation || '')
  const [pinterest, setPinterest] = useState<string>(data.pinterest || '')

  const handleSubmit = async () => {
    handleChangeAppData({ countLoadingTestimonial: 1 })
    setQuizData({ otherInformation: otherInformation, pinterest: pinterest })

    try {
      const calculateStyleResult: API<ISubmitQuizQuestionResponse> = await submitQuizResponseAPI({
        phoneNumber: phoneNumber?.trim() ?? '',
        firstName: firstName?.trim() ?? '',
        lastName: lastName?.trim() ?? '',
        emailAddress: emailAddress?.trim() ?? '',
        attributionSource: attributionSource,
        sessionId: sessionID,
      })

      gaEventApp(ACTION_APP.QUIZ_SUBMITTED)
      ga4EventApp(ACTION_APP.QUIZ_SUBMITTED)
      gaEventApp(ACTION_APP.FULLY_QUALIFIED_LEAD)
      ga4EventApp(ACTION_APP.FULLY_QUALIFIED_LEAD)
      segmentIdentify(sessionID, {
        name: `${firstName} ${lastName}`,
        email: emailAddress,
      })
      segmentTracking(SegmentAction.SQ_QUIZ_SUBMITTED)
      segmentTracking(SegmentAction.SQ_FULLY_QUALIFIED_LEAD)
      // We report adwords conversions by inserting a function into the <head> of the page.
      /* eslint-disable  @typescript-eslint/no-explicit-any */
      if ((window as { [key: string]: any })['gtag_report_conversion']) {
        /* eslint-disable  @typescript-eslint/no-explicit-any */
        ;(window as { [key: string]: any })['gtag_report_conversion']()
      }

      // premerchandizedKits
      if (
        ENV.PREMERCHANDIZED_KITS &&
        calculateStyleResult.data.premerchandizedKits &&
        calculateStyleResult.data.isActivePremerchandizedKits
      ) {
        const params = new URL(calculateStyleResult.data.redirectLink)
        history.push(`${PATH_URL.$500_CUSTOMER}${params.search}`)
        handleChangeAppData({ countLoadingTestimonial: -1 })
        // integratedFireworkExperience
      } else if (calculateStyleResult?.data?.integratedFireworkExperience || ENV.INTEGRATED_FIREWORK_EXPERIENCE) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const windowGlobal = window as any

        if (windowGlobal.pintrk) {
          windowGlobal.pintrk('track', 'lead')
        }

        fbEvent(EVENTS_FB_PIXEL.QUIZ2COMPLETE)
        vwoEvent()
        const params = new URL(calculateStyleResult.data.redirectLink)
        history.push(`${PATH_URL.YOUR_POPPY_PROPOSAL}${params.search}`)
        handleChangeAppData({ countLoadingTestimonial: -1 })
      } else {
        window.location.href = calculateStyleResult?.data?.redirectLink || `https://www.poppyflowers.com/`
      }
      /* eslint-disable  @typescript-eslint/no-explicit-any */
    } catch (error: any) {
      console.log('[handleSubmit]', error)
      alert(error?.data?.message ?? 'Submit failed')
      handleChangeAppData({ countLoadingTestimonial: -1 })
    }
  }

  const handleBack = () => {
    if (appData.isMicroWedding) {
      setQuizScreen(SCREEN_QUIZ_QUESTION.COLORS_FLOWERS)
    } else {
      setQuizScreen(SCREEN_QUIZ_QUESTION.BUDGET)
    }
  }

  const nextDisabled = useMemo(() => {
    return otherInformation.trim() === ''
  }, [otherInformation])

  const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const { value, name } = event.target

    if (name === FIELD_NAMES.PINTEREST) {
      setPinterest(value)
    } else {
      setOtherInformation(() => value)
    }
  }

  return {
    pinterest,
    nextDisabled,
    otherInformation,
    customerHelpers,
    handleChange,
    handleBack,
    handleSubmit,
  }
}
