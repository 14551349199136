import React, { InputHTMLAttributes } from 'react'

import './styles.scss'

type TextFieldProps = {
  isError?: boolean
  errorMsg?: string
  label?: string
  wrapperClass?: string
  inputClass?: string
  variant?: 'standard' | 'filled' | 'outlined'
  inputComponent?: React.ReactElement
} & InputHTMLAttributes<HTMLInputElement>

const TextField: React.FC<TextFieldProps> = React.forwardRef((props: TextFieldProps) => {
  const { inputComponent } = props
  return (
    <div
      className={`text-field-wrapper ${props.variant ?? 'standard'} ${!!props.value ? 'has-value' : ''} ${
        props.wrapperClass || ''
      }`}
    >
      <label className="label">{props.label}</label>
      <div className={`text-field-group ${props.variant ?? 'standard'} ${props.isError ? 'input-error' : ''}`}>
        {inputComponent ? (
          inputComponent
        ) : (
          <input
            className={`text-field ${props.inputClass || ''}`}
            id={props.id}
            name={props.name}
            value={props.value}
            type={props.type}
            placeholder={props.placeholder}
            onChange={props.onChange}
            onBlur={props.onBlur}
          />
        )}
      </div>
      {props.isError ? (
        <div className="error-helper">{props.errorMsg}</div>
      ) : (
        <div className="error-helper">&nbsp;</div>
      )}
    </div>
  )
})

TextField.displayName = 'TextField'

export default TextField
