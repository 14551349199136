import React, { useState } from 'react'
import Drawer from 'react-modern-drawer'

import arrowLeftIcon from 'src/assets/images/cannyValley/arrow-left.svg'
import { Button } from 'src/common'
import { CannyValleyLayout } from '../../../../common/components/Layout'
import { QuizStep } from '../QuizStep'
import { useCustomPalette } from './useCustomPalette'
import { ColorSlider } from './ColorSlider'
import { AddPopColor } from './AddPopColor'

import './style.scss'
import 'react-modern-drawer/dist/index.css'

export const CustomPalette: React.FC = () => {
  const { colorPalette, colors, handleBack, handleNext, handleChangeColorPercent, addPopColor } = useCustomPalette()
  const [open, setOpen] = useState(false)

  return (
    <CannyValleyLayout>
      <div className="custom-palette-page">
        <div className="step-header">
          <QuizStep item={1} step={2} />
        </div>
        <div className="custom-palette-content">
          <div className="custom-content">
            <div className="top-actions desktop">
              <div className="back-action" onClick={handleBack}>
                <img src={arrowLeftIcon} />
                BACK
              </div>
            </div>

            <div className="custom-header">
              <div className="step-title-header">
                <div className="top-actions mobile">
                  <div className="back-action" onClick={handleBack}>
                    <img src={arrowLeftIcon} />
                  </div>
                </div>
                <h3 className="step-title">Customize {colorPalette?.name}</h3>
              </div>
              <p className="step-description mt-16px">
                Adjust the sliders or add a pop of color to balance this palette to your preference.
              </p>
            </div>

            <div className="desktop mt-40px">
              <Button className="button-stem" onClick={handleNext}>
                EXPLORE INSPIRATION
              </Button>
            </div>
          </div>

          <div className="color-content-wrapper">
            <div className="custom-color-wrapper">
              {colors.map((color) => (
                <div key={color.key} className="color-item">
                  <ColorSlider
                    color={color.key}
                    value={color.value * 100}
                    handleChangeColorPercent={handleChangeColorPercent}
                  />
                </div>
              ))}
            </div>
            <div className="actions mt-40px">
              <Button className="button-stem secondary" onClick={() => setOpen(true)}>
                ADD A POP OF COLOR
              </Button>
              <Button className="button-stem mobile mt-20px" onClick={handleNext}>
                EXPLORE INSPIRATION
              </Button>
            </div>
          </div>
        </div>
      </div>
      <Drawer open={open} onClose={() => setOpen((pre) => !pre)} direction="right" className="add-pop-color-drawer">
        <AddPopColor
          onClose={() => setOpen((pre) => !pre)}
          colors={colors.map((i) => i.key.toLowerCase())}
          addPopColor={addPopColor}
        />
      </Drawer>
    </CannyValleyLayout>
  )
}
