import React from 'react'

import layer1Img from 'src/assets/images/flower_img_top_schedule.png'
import layer2Img from 'src/assets/images/flower_img_top_schedule_2.png'
import layer1ImgConversation from 'src/assets/images/flower_img_top_conversation.png'
import layer2ImgConversation from 'src/assets/images/flower_img_top_conversation_2.png'

interface IScheduleCallHeaderProps {
  isConversation: boolean
  isConversationFromCalendly: boolean
}

export const ScheduleCallHeader = (props: IScheduleCallHeaderProps) => {
  return (
    <div className="header">
      <div className="header-wrapper container mt-0px-auto">
        {props.isConversation ? (
          <div className="section-left">
            <img className="img-layer1" src={layer1ImgConversation} alt="layer 1" />
            <img className="img-layer2" src={layer2ImgConversation} alt="layer 2" />
          </div>
        ) : (
          <div className="section-left">
            <img className="img-layer1" src={layer1Img} alt="layer 1" />
            <img className="img-layer2" src={layer2Img} alt="layer 2" />
          </div>
        )}
        <div className="section-right">
          <div className="text-box">
            <h1 className="title">
              {props.isConversation && !props.isConversationFromCalendly
                ? `Not a phone person? That's ok!`
                : `Let's Chat`}
            </h1>
            <div className="subtitle">
              {props.isConversation && !props.isConversationFromCalendly
                ? ``
                : `Partner with your Proposal Designer to curate your floral style and budget`}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
