import { useContext, useEffect, useState } from 'react'
import { QuizQuestionContext, SCREEN_QUIZ_QUESTION } from '../../contextAPI'
import { ColorPreference } from 'src/types'
import { SegmentAction, segmentTracking } from 'src/helpers/segment'

type CustomColor = {
  key: string
  value: number
}

export const useCustomPalette = () => {
  const { setQuizScreen, setQuizData, data } = useContext(QuizQuestionContext)
  const [colors, setColors] = useState<CustomColor[]>([])

  const handleBack = () => {
    setQuizScreen(SCREEN_QUIZ_QUESTION.CHOOSE_PALETTE)
  }

  const handleNext = async () => {
    try {
      setQuizData({
        colorPreference: colors
          .filter((c) => c.value > 0)
          .reduce((acc, cur) => {
            acc[cur.key] = cur.value
            return acc
          }, {} as ColorPreference),
      })
      setQuizScreen(SCREEN_QUIZ_QUESTION.INSPIRATION)
      segmentTracking(SegmentAction.SQ_CV_COLOR_PALETTE_CUSTOM_COMPLETED)
    } catch (error) {}
  }

  const handleChangeColorPercent = (color: string, value: number) => {
    setColors((pre) => {
      const newState = [...pre]
      const index = newState.findIndex((c) => c.key === color)
      if (index > -1) newState[index] = { ...newState[index], value: value / 100 }
      return newState
    })
  }
  const addPopColor = (popColors: string[]) => {
    const curColors = colors.map((c) => c.key.toLowerCase())
    const newColors = popColors.filter((c) => !curColors.includes(c))
    setColors((pre) => {
      return pre.concat(newColors.map((c) => ({ key: c, value: 1 })))
    })
  }

  useEffect(() => {
    const initPage = () => {
      const preference = data.colorPreference
      if (preference && Object.keys(preference).length) {
        setColors(
          Object.keys(preference).map((key) => ({
            key,
            value: preference[key],
          }))
        )
        return
      }
      if (data.colorPalette) {
        setColors(
          data.colorPalette.orbConfig.orb_colors.map((cl) => ({
            key: cl.toLowerCase(),
            value: 1,
          }))
        )
      }
    }
    initPage()
  }, [data.colorPalette, data.colorPreference])

  return {
    colorPalette: data.colorPalette,
    colors,
    handleBack,
    handleNext,
    handleChangeColorPercent,
    addPopColor,
  }
}
