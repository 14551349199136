import React, { useEffect, useState } from 'react'

interface IPreloadImagesProps {
  images: string[]
  handleLoading?: (count: number) => void
}

export const PreloadImages: React.FC<IPreloadImagesProps> = (props: IPreloadImagesProps) => {
  const { images, handleLoading } = props

  const [count, setCount] = useState(1)

  const loaded = () => {
    setCount((value) => ++value)
  }

  useEffect(() => {
    handleLoading && handleLoading(count)
  }, [count, handleLoading])

  return (
    <>
      {images.slice(0, count).map((item) => (
        <img
          className="d-none"
          key={`preload-img-${item}`}
          src={item}
          alt="image-preloaded"
          onLoad={loaded}
          onError={loaded}
        />
      ))}
    </>
  )
}

export const ImageCachePreloader = ({ imageUrls }: { imageUrls: string[] }) => {
  useEffect(() => {
    // Create an array to store Image objects
    const imageObjects: HTMLImageElement[] = []

    // Load each image and store the Image objects
    imageUrls.forEach((imageUrl) => {
      const img = new Image()
      img.src = imageUrl
      imageObjects.push(img)
    })

    return () => {
      // Clean up by removing event listeners and clearing Image objects
      imageObjects.forEach((img) => {
        img.onload = null
        img.onerror = null
      })
    }
  }, [imageUrls])

  return null // This component doesn't render anything
}
