import React from 'react'
import { ISalesPerson } from 'src/types/scheduledEvent'

import logoImg from 'src/assets/images/logo.png'

interface ISalesPersonProps {
  salesPerson: ISalesPerson
}

export const SalesPerson = (props: ISalesPersonProps) => {
  const { salesPerson } = props

  const { pronoun1, pronoun2, flower, firstName, namePos, location, gifMobileUrl, gifWebUrl, yearExp } = salesPerson

  const title = `Your Proposal Designer, ${firstName}, has everything ${pronoun1} needs for your consultation.`

  const introduce1 = `
    ${firstName} is a true floral expert – ${pronoun1} has over ${yearExp} years of experience in the industry and has expertly guided hundreds of couples through their wedding flower search.
  `
  const introduce2 = `From ${pronoun2} home base
    of ${location}, ${pronoun1} will walk you through every step of the process, sharing budget-friendly tips
    and tricks along the way.
  `
  const introduce3 = `${namePos} favorite flower is ${flower}, and ${pronoun1} can’t wait to learn about yours!`
  const introduce = (
    <p className="introduce">
      <p>{introduce1}</p>
      <p>{introduce2}</p>
      <p>{introduce3}</p>
    </p>
  )

  const introduceTitle = `You’ve been matched with ${firstName}!`

  return (
    <>
      <div className={`section-42`}>
        <div className="container-31 w-container">
          <div id="wellTalkSoonHeader" className="div-block-172">
            <h1 className="heading-85">Your call is confirmed!</h1>
            <div className="text-block-95">{title}</div>
            <div className="div-block-166" />
          </div>
        </div>
      </div>
      <div className="section-37 introduce-wrapper">
        <div className="sneak-peek-desktop container mt-0px-auto">
          <div className="div-block-152">
            <img src={logoImg} loading="lazy" alt="" className="image-87" />
            <h2 id="nameHeading" className="heading-79">
              {introduceTitle}
            </h2>
            <div className="text-block-91 mobile-hidden">{introduce}</div>
          </div>
          <div className="gallery gallery-desktop">
            <div className="avatar-gif">
              <img src={gifWebUrl} />
            </div>
          </div>
        </div>
      </div>
      <div className="section-38">
        <div className="container-29 container mt-0px-auto">
          <div className="avatar-gif-mobile">
            <img src={gifMobileUrl} />
          </div>
          <h2 className="heading-79">{introduceTitle}</h2>
          <div className="hidden-on-desktop">{introduce}</div>
        </div>
      </div>
    </>
  )
}
