import React, { createContext, useState, Dispatch, SetStateAction, useContext } from 'react'
import { IProduct, IProductOptions } from 'src/types/product'

export interface ICart {
  [label: string]: {
    quantity: number
    product: IProduct
  }
}

export interface CustomerExperienceContextData {
  cart: ICart
  products: IProductOptions
  showExperiencePopup: boolean
  showCheckoutPopup: boolean
  setCart: Dispatch<SetStateAction<ICart>>
  setShowExperiencePopup: Dispatch<SetStateAction<boolean>>
  setShowCheckoutPopup: Dispatch<SetStateAction<boolean>>
  setProducts: Dispatch<SetStateAction<IProductOptions>>
}

export const customerExperienceContextDefaultValue: CustomerExperienceContextData = {
  cart: {},
  products: {},
  showExperiencePopup: false,
  showCheckoutPopup: false,
  setCart: () => null,
  setShowExperiencePopup: () => null,
  setShowCheckoutPopup: () => null,
  setProducts: () => null,
}

const CustomerExperienceContext = createContext<CustomerExperienceContextData>({
  ...customerExperienceContextDefaultValue,
})

const useCustomerExperienceContextValue = (): CustomerExperienceContextData => {
  const [cart, setCart] = useState<ICart>(customerExperienceContextDefaultValue.cart)
  const [products, setProducts] = useState<IProductOptions>(customerExperienceContextDefaultValue.products)
  const [showExperiencePopup, setShowExperiencePopup] = useState<boolean>(
    customerExperienceContextDefaultValue.showExperiencePopup
  )
  const [showCheckoutPopup, setShowCheckoutPopup] = useState<boolean>(
    customerExperienceContextDefaultValue.showCheckoutPopup
  )

  return {
    cart,
    products,
    showExperiencePopup,
    showCheckoutPopup,
    setShowCheckoutPopup,
    setShowExperiencePopup,
    setProducts,
    setCart,
  }
}

export const CustomerExperienceProvider = ({ children }: { children: JSX.Element }) => {
  const value = useCustomerExperienceContextValue()

  return <CustomerExperienceContext.Provider value={value}>{children}</CustomerExperienceContext.Provider>
}

export function useCustomerExperienceContext() {
  const customerExperienceContext = useContext(CustomerExperienceContext)
  if (!customerExperienceContext) {
    throw new Error('useAppContext must be used within the AppContext.Provider')
  }

  return customerExperienceContext
}
