/* eslint-disable @typescript-eslint/no-explicit-any */
export const cloneDeep = (value: any) => {
  return JSON.parse(JSON.stringify(value))
}

export const addOrRemoveStringArray = (array: string[], value: string) => {
  const temp = [...array]
  const index = temp.findIndex((o) => o == value)

  if (index === -1) return [...temp, value]

  return [...temp.filter((item) => item !== value)]
}
