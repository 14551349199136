import React, { useContext } from 'react'

import { Button } from 'src/common'
import bg from 'src/assets/images/cannyValley/flower-interstitial-bg.png'
import mobileBg from 'src/assets/images/cannyValley/flower-interstitial-mobile-bg.png'
import arrowLeftIcon from 'src/assets/images/cannyValley/arrow-left.svg'
import { CannyValleyLayout } from 'src/common/components/Layout'
import { QuizQuestionContext, SCREEN_QUIZ_QUESTION } from '../../contextAPI'

import './styles.scss'

export const FlowersInterstitial: React.FC = () => {
  const { setQuizScreen } = useContext(QuizQuestionContext)

  const reviewSampleFlower = () => {
    setQuizScreen(SCREEN_QUIZ_QUESTION.SAMPLE_FLOWER)
  }
  const continueBudget = () => {
    setQuizScreen(SCREEN_QUIZ_QUESTION.CANNY_VALLEY_BUDGET)
  }
  const handleBack = () => {
    setQuizScreen(SCREEN_QUIZ_QUESTION.INSPIRATION)
  }

  return (
    <CannyValleyLayout className="welcome-page-wrapper flower-interstitial">
      <div className="welcome-page">
        <div className="welcome-content">
          <div className="top-actions">
            <div className="back-action" onClick={handleBack}>
              <img src={arrowLeftIcon} />
              BACK
            </div>
          </div>
          <h3 className="poppy-intro mt-24px">Review sample flowers we'd use on your big day?</h3>
          <p className="description mt-24px">
            Based on your palette and the season of your wedding, we'd like to show you potential flowers we'll use on
            your big day. Or, skip straight to budget.
          </p>
          <div className="actions mt-40px">
            <Button className="button-stem width-fit-content" onClick={reviewSampleFlower}>
              Review sample flowers
            </Button>
            <Button className="button-stem secondary width-fit-content" onClick={continueBudget}>
              CONTINUE TO BUDGET
            </Button>
          </div>
        </div>
      </div>
      <img src={bg} className="desktop bg-image" />
      <img src={mobileBg} className="mobile bg-image" />
    </CannyValleyLayout>
  )
}
