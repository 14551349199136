import React from 'react'
import style from 'styled-components'

const ProgressbarWrapper = style.div`
  width: 100%;
  height: 8px;
  border-radius: 4px;
  background-color: rgba(244, 192, 143, 0.3);

  .progress {
    background-color: #F4C08F;
    border-radius: 4px;
    transition: width 400ms ease-in-out;
    height: 100%;
  }
`

interface IProgressbarProps {
  progress: number
}

export const Progressbar: React.FC<IProgressbarProps> = (props: IProgressbarProps) => {
  const { progress } = props

  return (
    <ProgressbarWrapper>
      <div className="progress" style={{ width: `${progress}%` }}></div>
    </ProgressbarWrapper>
  )
}
