import React from 'react'

import { Header } from '../Header'
import './style.scss'

export const CannyValleyLayout: React.FC<React.PropsWithChildren<{ className?: string }>> = ({
  children,
  className,
}: React.PropsWithChildren<{ className?: string }>) => {
  return (
    <div className={`canny-valley-layout ${className || ''}`}>
      <Header />
      <div id="main-content" className="main-content">
        <div className="container">{children}</div>
      </div>
    </div>
  )
}
