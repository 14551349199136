import React from 'react'

import arrowLeftIcon from 'src/assets/images/cannyValley/arrow-left.svg'
import { Button } from 'src/common'

import { CannyValleyLayout } from '../../../../common/components/Layout'
import { QuizStep } from '../QuizStep'
import { useSampleFlower } from './useSampleFlower'

import './style.scss'
import { SampleFlowerPreview } from './SampleFlowerPreview'

export const SampleFlower: React.FC = () => {
  const { flowers, excluding, handleBack, handleNext, handleSelectStemPreference } = useSampleFlower()

  return (
    <CannyValleyLayout>
      <div className="sample-flower-page">
        <div className="step-header">
          <QuizStep item={2} step={1} />
        </div>
        <div className="sample-flower-content-wrapper">
          <div className="sample-flower-content">
            <div className="top-actions desktop">
              <div className="back-action" onClick={handleBack}>
                <img src={arrowLeftIcon} />
                BACK
              </div>
            </div>

            <div className="mt-16px">
              <div className="step-title-header">
                <div className="top-actions mobile">
                  <div className="back-action" onClick={handleBack}>
                    <img src={arrowLeftIcon} />
                  </div>
                </div>
                <h3 className="step-title">Your Sample Flowers</h3>
              </div>
              <p className="step-description mt-16px">
                Tap a stem to learn more. Give feedback to tell our team what you like the most.
              </p>
              {excluding.length ? (
                <p className="step-description mt-35px">
                  Excluding: {excluding.map((e) => e.stem.typeName || e.stem.name).join(', ')}
                </p>
              ) : (
                <></>
              )}
            </div>

            <div className="desktop mt-40px">
              <Button className="button-stem" onClick={handleNext}>
                CONTINUE TO BUDGET
              </Button>
            </div>
          </div>

          <SampleFlowerPreview flowers={flowers || []} selectStemPreference={handleSelectStemPreference} />

          <div className="mobile actions">
            <Button className="button-stem" onClick={handleNext}>
              CONTINUE TO BUDGET
            </Button>
          </div>
        </div>
      </div>
    </CannyValleyLayout>
  )
}
