import React, { useEffect } from 'react'
import { toast } from 'react-toastify'
import { useDropzone, Accept } from 'react-dropzone'

import { ENV } from 'src/config/env'
import './style.scss'

type UploadDropzoneProps = {
  accept?: Accept
  noClick?: boolean
  noKeyboard?: boolean
  disabled?: boolean
  loading?: boolean
  multiple?: boolean
  className?: string
  children?: (props: { open: () => void }) => React.ReactNode
  handleUploadImages: (images: File[]) => void
}

export const UploadDropzone: React.FC<React.PropsWithChildren<UploadDropzoneProps>> = ({
  accept,
  children,
  noClick = false,
  noKeyboard = true,
  disabled = false,
  multiple = true,
  className,
  handleUploadImages,
}: React.PropsWithChildren<UploadDropzoneProps>) => {
  const { getRootProps, getInputProps, open, acceptedFiles, fileRejections, isDragAccept } = useDropzone({
    // Disable click and keydown behavior
    noClick,
    noKeyboard,
    accept,
    disabled,
    multiple,
    maxSize: ENV.MAXIMUM_UPLOAD_FILE_SIZE,
  })

  useEffect(() => {
    if (acceptedFiles.length > 0) {
      handleUploadImages(acceptedFiles)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [acceptedFiles])

  useEffect(() => {
    const fileNames: string[] = []
    let countOfFile = 0
    fileRejections.forEach((file) => {
      countOfFile++
      fileNames.push(file.file.name)
    })

    if (countOfFile > 0) {
      toast.error(
        <div>
          Can not upload these files:
          {fileNames.map((name) => (
            <div key={name}>
              {name}
              <br />
            </div>
          ))}
        </div>
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fileRejections])

  return (
    <div {...getRootProps({ className: `dropzone-wrapper ${className ?? ''} ${isDragAccept ? 'active' : ''}` })}>
      <input {...getInputProps()} />
      {children?.({ open })}
    </div>
  )
}
