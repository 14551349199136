import React from 'react'
import { BrowserRouter as Router } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'

import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'

import { AppContext, useAppContextValue } from './helpers/app'

import AppBase from './AppBase'
import { ENV } from './config/env'
import 'react-toastify/dist/ReactToastify.css'

if (ENV.NODE_ENV === 'production') {
  Sentry.init({
    dsn: ENV.REACT_APP_SENTRY_DSN,
    integrations: [
      new Integrations.BrowserTracing(),
      new Sentry.Integrations.Breadcrumbs({
        console: true,
        fetch: true,
        dom: true,
        history: true,
        xhr: true,
      }),
    ],
    release: process.env.REACT_APP_SENTRY_RELEASE,
    environment: ENV.REACT_APP_ENV,
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  })
}

const App: React.FC = () => {
  const appContextAPIValue = useAppContextValue()

  return (
    <AppContext.Provider value={appContextAPIValue}>
      <Router>
        <AppBase />
      </Router>
      <ToastContainer theme="colored" position="bottom-right" />
    </AppContext.Provider>
  )
}

export default Sentry.withProfiler(App)
