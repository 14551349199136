import React from 'react'
import { IIconProps } from 'src/types/Icon'
import iconCalendar from 'src/assets/images/icon_calendar.png'
import { ReactComponent as CalendarIconSVG } from 'src/assets/icons/calendar-icon.svg'
import { ReactComponent as UnCheckedIconSVG } from 'src/assets/icons/un-checked.svg'
import { ReactComponent as CheckedIconSVG } from 'src/assets/icons/checked.svg'
import { ReactComponent as ArrowForwardSVG } from 'src/assets/icons/arrow-forward.svg'
import { ReactComponent as ChevronDownSVG } from 'src/assets/icons/chevron-down.svg'

export const CalendarIcon: React.FC<IIconProps> = (props: IIconProps) => {
  return <CalendarIconSVG {...props} />
}

export const UnCheckedIcon: React.FC<IIconProps> = (props: IIconProps) => {
  return <UnCheckedIconSVG {...props} />
}

export const CheckedIcon: React.FC<IIconProps> = (props: IIconProps) => {
  return <CheckedIconSVG {...props} />
}

export const ArrowForwardIcon: React.FC<IIconProps> = (props: IIconProps) => {
  return <ArrowForwardSVG {...props} />
}

export const ChevronDownIcon: React.FC<IIconProps> = (props: IIconProps) => {
  return <ChevronDownSVG {...props} />
}

export const BackIcon: React.FC<IIconProps> = (props: IIconProps) => {
  return (
    <svg width="52" height="52" viewBox="0 0 52 52" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M26 51C39.8071 51 51 39.8071 51 26C51 12.1929 39.8071 1 26 1C12.1929 1 1 12.1929 1 26C1 39.8071 12.1929 51 26 51Z"
        stroke="#289A9A"
        strokeWidth="2"
      />
      <path
        d="M21.5195 26.0898L29.0781 33.6484L28.3398 34.4219L20.0078 26.0898L28.3398 17.793L29.0781 18.5312L21.5195 26.0898Z"
        fill="#289A9A"
      />
    </svg>
  )
}

export const BackWhiteIcon: React.FC<IIconProps> = (props: IIconProps) => {
  return (
    <svg width="52" height="52" viewBox="0 0 52 52" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M26 51C39.8071 51 51 39.8071 51 26C51 12.1929 39.8071 1 26 1C12.1929 1 1 12.1929 1 26C1 39.8071 12.1929 51 26 51Z"
        stroke="#289A9A"
        strokeWidth="2"
      />
      <path
        d="M21.5195 26.0898L29.0781 33.6484L28.3398 34.4219L20.0078 26.0898L28.3398 17.793L29.0781 18.5312L21.5195 26.0898Z"
        fill="#fff"
      />
    </svg>
  )
}

export const NextIcon: React.FC<IIconProps> = (props: IIconProps) => {
  return (
    <svg width="52" height="52" viewBox="0 0 52 52" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M26.0001 0.999998C12.1929 0.999997 1.00006 12.1929 1.00006 26C1.00006 39.8071 12.1929 51 26.0001 51C39.8072 51 51.0001 39.8071 51.0001 26C51.0001 12.1929 39.8072 0.999999 26.0001 0.999998Z"
        stroke="#289A9A"
        strokeWidth="2"
      />
      <path
        d="M30.4805 26.9102L22.9219 19.3516L23.6602 18.5781L31.9922 26.9102L23.6602 35.207L22.9219 34.4687L30.4805 26.9102Z"
        fill="#289A9A"
      />
    </svg>
  )
}

export const NextBGIcon: React.FC<IIconProps> = (props: IIconProps) => {
  const { className, ...otherProps } = props
  return <BackWhiteIcon className={`rotate-180 icon-next ${className ?? ''}`} {...otherProps} />
}

export const MinusIcon: React.FC<IIconProps> = (props: IIconProps) => {
  return (
    <svg width="14" height="2" viewBox="0 0 14 2" xmlns="http://www.w3.org/2000/svg" {...props}>
      <rect width="14" height="2" rx="1" />
    </svg>
  )
}

export const PlusIcon: React.FC<IIconProps> = (props: IIconProps) => {
  return (
    <svg width="14" height="14" viewBox="0 0 14 14" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 6H13C13.5523 6 14 6.44772 14 7C14 7.55228 13.5523 8 13 8H8V13C8 13.5523 7.55228 14 7 14C6.44772 14 6 13.5523 6 13V8H1C0.447715 8 0 7.55228 0 7C0 6.44772 0.447715 6 1 6H6V1C6 0.447715 6.44772 0 7 0C7.55228 0 8 0.447715 8 1V6Z"
      />
    </svg>
  )
}

export const CloseIcon: React.FC<IIconProps> = (props: IIconProps) => {
  return (
    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 511.76 511.76" {...props}>
      <g>
        <g>
          <path
            d="M436.896,74.869c-99.84-99.819-262.208-99.819-362.048,0c-99.797,99.819-99.797,262.229,0,362.048
			c49.92,49.899,115.477,74.837,181.035,74.837s131.093-24.939,181.013-74.837C536.715,337.099,536.715,174.688,436.896,74.869z
			 M361.461,331.317c8.341,8.341,8.341,21.824,0,30.165c-4.16,4.16-9.621,6.251-15.083,6.251c-5.461,0-10.923-2.091-15.083-6.251
			l-75.413-75.435l-75.392,75.413c-4.181,4.16-9.643,6.251-15.083,6.251c-5.461,0-10.923-2.091-15.083-6.251
			c-8.341-8.341-8.341-21.845,0-30.165l75.392-75.413l-75.413-75.413c-8.341-8.341-8.341-21.845,0-30.165
			c8.32-8.341,21.824-8.341,30.165,0l75.413,75.413l75.413-75.413c8.341-8.341,21.824-8.341,30.165,0
			c8.341,8.32,8.341,21.824,0,30.165l-75.413,75.413L361.461,331.317z"
          />
        </g>
      </g>
    </svg>
  )
}

export const CalenderIcon: React.FC<IIconProps> = (props: IIconProps) => {
  return (
    <svg
      width={34}
      height={34}
      viewBox="0 0 34 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <rect width={34} height={34} fill="url(#pattern0)" />
      <defs>
        <pattern id="pattern0" patternContentUnits="objectBoundingBox" width={1} height={1}>
          <use xlinkHref="#image0" transform="translate(-0.00395928) scale(0.00373303 0.00384615)" />
        </pattern>
        <image id="image0" width={270} height={270} xlinkHref={iconCalendar} />
      </defs>
    </svg>
  )
}

export const PinMobileIcon: React.FC<IIconProps> = (props: IIconProps) => {
  return (
    <svg width="79" height="112" viewBox="0 0 79 112" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M78.9965 39.1306C78.9895 46.2582 77.0259 53.2466 73.3204 59.3314C63.7156 75.0325 46.634 103.061 41.4419 111.21C41.2896 111.45 41.0796 111.648 40.8312 111.786C40.5827 111.924 40.3038 111.997 40.0198 111.998C39.7359 112 39.4562 111.93 39.2062 111.795C38.9561 111.661 38.7439 111.465 38.5889 111.227C33.4676 103.391 17.0481 77.2151 7.67732 62.3632C5.8756 59.5078 4.03496 56.687 2.82999 53.5128C-0.917139 43.6488 -1.08329 33.7398 3.17427 24.0542C9.06838 10.6457 19.4456 2.6944 33.8848 0.485795C45.3681 -1.27099 55.7787 1.7313 64.7699 9.1108C72.5929 15.5322 77.1907 23.8728 78.6147 33.9357C78.8792 35.6545 79.0068 37.3916 78.9965 39.1306ZM39.5254 54.8422C48.5325 54.8467 55.6051 47.9525 55.6071 39.1666C55.5908 34.9261 53.8968 30.865 50.8963 27.8735C47.8958 24.8821 43.8336 23.2043 39.6002 23.2081C35.3845 23.2273 31.3465 24.9118 28.3633 27.8956C25.38 30.8794 23.6923 34.9217 23.6667 39.1446C23.6737 41.2199 24.09 43.2734 24.8918 45.1871C25.6935 47.1007 26.8649 48.8368 28.3386 50.2955C29.8123 51.7542 31.5592 52.9068 33.479 53.6871C35.3988 54.4674 37.4537 54.8599 39.5254 54.8422Z"
        fill="#FFA3A3"
      />
    </svg>
  )
}

export const PinDesktopIcon: React.FC<IIconProps> = (props: IIconProps) => {
  return (
    <svg width="84" height="118" viewBox="0 0 84 118" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M83.9962 41.2281C83.9886 48.7375 81.9007 56.1002 77.9609 62.5111C67.7482 79.0533 49.5855 108.583 44.0648 117.169C43.9029 117.422 43.6796 117.631 43.4154 117.776C43.1513 117.921 42.8546 117.998 42.5527 117.999C42.2509 118.001 41.9534 117.928 41.6876 117.786C41.4217 117.644 41.1961 117.438 41.0312 117.187C35.5858 108.932 18.1271 81.3529 8.16324 65.7053C6.24749 62.697 4.29032 59.725 3.00909 56.3808C-0.975201 45.9883 -1.15183 35.5485 3.37519 25.3441C9.64235 11.2172 20.6764 2.83996 36.0294 0.513042C48.2395 -1.33786 59.309 1.82527 68.8692 9.6001C77.1874 16.3655 82.0762 25.1529 83.5904 35.7549C83.8715 37.5658 84.0073 39.3959 83.9962 41.2281V41.2281ZM42.027 57.7814C51.6041 57.7862 59.1244 50.5226 59.1265 41.266C59.1092 36.7983 57.308 32.5197 54.1176 29.368C50.9272 26.2163 46.6079 24.4486 42.1066 24.4526C37.6239 24.4727 33.3303 26.2474 30.1581 29.3911C26.9859 32.5348 25.1913 36.7937 25.1641 41.2428C25.1715 43.4294 25.6142 45.593 26.4667 47.6091C27.3192 49.6253 28.5648 51.4544 30.1318 52.9913C31.6988 54.5282 33.5564 55.7425 35.5978 56.5646C37.6392 57.3866 39.8241 57.8002 42.027 57.7814V57.7814Z"
        fill="#D7462D"
      />
    </svg>
  )
}

export const XIcon: React.FC<IIconProps> = (props: IIconProps) => {
  return (
    <svg
      fill="none"
      height="24"
      stroke="#fff"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      viewBox="0 0 24 24"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <line x1="18" x2="6" y1="6" y2="18" />
      <line x1="6" x2="18" y1="6" y2="18" />
    </svg>
  )
}

export const TickRedIcon = (props: IIconProps) => {
  return (
    <svg width="18" height="13" viewBox="0 0 18 13" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M1 6.07692L6.6 12L17 1" stroke="#D7462D" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  )
}

export const TickWhiteIcon = (props: IIconProps) => {
  return (
    <svg width="18" height="13" viewBox="0 0 18 13" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M1 6.07692L6.6 12L17 1" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  )
}

export const NextArrowIcon = (props: IIconProps) => {
  return (
    <svg width="10" height="19" viewBox="0 0 20 37" fill="none" {...props} xmlns="http://www.w3.org/2000/svg">
      <path d="M2 2L18.5 18.5L2 35" stroke="white" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  )
}

export const PrevArrowIcon = (props: IIconProps) => {
  return (
    <svg width="10" height="19" viewBox="0 0 20 37" fill="none" {...props} xmlns="http://www.w3.org/2000/svg">
      <path
        d="M18.5 35L2 18.5L18.5 2"
        stroke={props.stroke}
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export const CartIcon = (props: IIconProps) => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M2.66671 16C3.40309 16 4.00004 15.4031 4.00004 14.6667C4.00004 13.9303 3.40309 13.3334 2.66671 13.3334C1.93033 13.3334 1.33337 13.9303 1.33337 14.6667C1.33337 15.4031 1.93033 16 2.66671 16Z"
        fill={props.color || '#F2F1EA'}
      />
      <path
        d="M13.3333 16C14.0697 16 14.6667 15.4031 14.6667 14.6667C14.6667 13.9303 14.0697 13.3334 13.3333 13.3334C12.597 13.3334 12 13.9303 12 14.6667C12 15.4031 12.597 16 13.3333 16Z"
        fill={props.color || '#F2F1EA'}
      />
      <path
        d="M0.666687 0.666687L2.00002 2.00002V8.66669L0.666687 11.3334H15.3334"
        stroke={props.color || '#F2F1EA'}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2 2H15.3333L12.6667 8.66667H2"
        stroke={props.color || '#F2F1EA'}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export const HeartCircleIcon = (props: IIconProps) => {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="16" cy="16" r="15.5" fill="white" stroke={props.color || '#CFCFCF'} />
      <path
        d="M19.9348 8.66667C19.1877 8.66487 18.449 8.82499 17.7697 9.13602C17.0904 9.44704 16.4866 9.90158 15.9998 10.4683C15.513 9.90158 14.9091 9.44704 14.2298 9.13602C13.5505 8.82499 12.8119 8.66487 12.0648 8.66667C11.0424 8.66437 10.0418 8.96235 9.18729 9.52363C8.33275 10.0849 7.66184 10.8848 7.25784 11.8239C6.85384 12.7631 6.73453 13.8003 6.91472 14.8067C7.09491 15.813 7.56667 16.7443 8.27144 17.485L15.3989 24.8925C15.4767 24.9734 15.57 25.0378 15.6732 25.0818C15.7765 25.1257 15.8876 25.1484 15.9998 25.1484C16.112 25.1484 16.2231 25.1257 16.3263 25.0818C16.4295 25.0378 16.5228 24.9734 16.6006 24.8925L23.7281 17.485L23.7339 17.4792C24.4371 16.7379 24.9073 15.8066 25.0862 14.8006C25.2651 13.7947 25.1448 12.7584 24.7403 11.8201C24.3358 10.8819 23.6649 10.083 22.8107 9.5224C21.9564 8.96185 20.9565 8.66431 19.9348 8.66667Z"
        fill={props.color || '#CFCFCF'}
      />
    </svg>
  )
}
