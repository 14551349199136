import React, { useState } from 'react'
import { InlineWidget, CalendlyEventListener } from 'react-calendly'
import { CalendlyEvent } from 'react-calendly/typings/calendly'
import { LoadingSpinner } from '../loading/LoadingSpinner'
import './Calendly.scss'

export type EventScheduledEvent = MessageEvent<{
  event: CalendlyEvent.EVENT_SCHEDULED
  payload: {
    event: {
      /**
       * @description Canonical reference (unique identifier) to the event that was scheduled.
       * @example https://calendly.com/api/v2/scheduled_events/AAAAAAAAAAAAAA
       * @see {@link https://developer.calendly.com/docs/api-docs/reference/calendly-api/openapi.yaml/paths/~1scheduled_events~1%7Buuid%7D/get} for further information.
       */
      uri: string
    }
    invitee: {
      /**
       * @description Canonical reference (unique identifier) for the invitee who scheduled the event.
       * @example https://calendly.com/api/v2/scheduled_events/AAAAAAAAAAAAAA/invitees/AAAAAAAAAAAAAA
       * @see {@link https://developer.calendly.com/docs/api-docs/reference/calendly-api/openapi.yaml/paths/~1scheduled_events~1%7Bevent_uuid%7D~1invitees~1%7Binvitee_uuid%7D/get} for further information.
       */
      uri: string
    }
  }
}>

type CalendlyProps = {
  name: string
  email: string
  url: string
  handleEventScheduled?: (e: EventScheduledEvent) => void
  loading?: boolean
}

export const Calendly: React.FC<CalendlyProps> = (props: CalendlyProps) => {
  const [internalLoading, setInternalLoading] = useState(true)

  const onEventTypeViewed = () => {
    setInternalLoading(false)
  }
  const onEventScheduled = (e: EventScheduledEvent) => {
    props.handleEventScheduled?.(e)
  }

  return (
    <div id="calendly-wrapper" className="calendly-wrapper">
      {(props.loading || internalLoading) && (
        <div className="loading-container">
          <LoadingSpinner size="md" />
        </div>
      )}

      <CalendlyEventListener onEventScheduled={onEventScheduled} onEventTypeViewed={onEventTypeViewed}>
        <InlineWidget
          url={props.url}
          styles={{ height: '100%', width: '100%' }}
          prefill={{
            name: props.name,
            email: props.email,
          }}
          pageSettings={
            {
              // hideEventTypeDetails: true,
              // hideGdprBanner: true,
              // hideLandingPageDetails: true,
            }
          }
        />
      </CalendlyEventListener>
    </div>
  )
}
