import React, { useCallback, useEffect, useState } from 'react'

import { postHttp } from 'src/api'
import { PATH_API } from 'src/helpers/path'
import { useAppContext } from 'src/helpers/app'
import { useDebounce } from 'src/helpers/hooks/useDebounce'

import './styles.scss'

export const PinterestBoard: React.FC = () => {
  const [pinterestBoard, setPinterestBoard] = useState<string | undefined>()
  const [error, setError] = useState<string>('')
  const { sessionID, quizSession } = useAppContext()
  const boardDebounce = useDebounce<string>(pinterestBoard || '', 1000)

  const handleRecordPinterestBoard = useCallback(
    async (board: string) => {
      if (!sessionID) return
      await postHttp(PATH_API.postRecordQuizResponse, {
        sessionId: sessionID,
        pinterestBoardLink: board,
        eventVenues: [],
        quizResponses: [],
      })
    },
    [sessionID]
  )

  useEffect(() => {
    const saveBoard = () => {
      if (!boardDebounce) {
        setError('')
        return
      }
      if (!/[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/.test(boardDebounce)) {
        setError('Must a link!')
        return
      }
      setError('')
      handleRecordPinterestBoard(boardDebounce)
    }
    saveBoard()
  }, [boardDebounce, handleRecordPinterestBoard])

  useEffect(() => {
    setPinterestBoard(quizSession?.customer.pinterestBoard || undefined)
  }, [quizSession?.customer.pinterestBoard])

  return (
    <div className="pinterest-section mt-24px">
      <p className="normal-text mt-16px">Do you have a Pinterest board to share?</p>
      <div className="item form-group">
        <div className="form-item">
          <input
            id="email-address"
            name="emailAddress"
            className={`input w-100 mt-15px ${error ? 'border-red' : ''}`}
            type="text"
            placeholder="Pinterest link"
            value={pinterestBoard}
            onChange={(e) => setPinterestBoard(e.target.value)}
          />
        </div>
        {error && <span className="error-text">{error}</span>}
      </div>
    </div>
  )
}
