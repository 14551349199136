import React from 'react'

import './styles.scss'
import { useAppContext } from 'src/helpers/app'
import moment from 'moment'
import { Button } from 'src/common'
import { useHistory } from 'react-router-dom'
import { SegmentAction, segmentTracking } from 'src/helpers/segment'

export const WelcomeHeader: React.FC = () => {
  const { quizSession, sessionID } = useAppContext()
  const history = useHistory()

  const bookYourConsult = () => {
    history.push(`/schedule-a-call/${sessionID}`)
  }

  const shopFlowers = () => {
    if (quizSession?.proposal.proposalUrl) {
      segmentTracking(SegmentAction.CANNY_VALLEY_SHOP_FLOWERS_CLICKED)
      window.location.href = quizSession?.proposal.proposalUrl
    }
  }

  return (
    <div className="welcome-header">
      <div className="container">
        <div className="welcome-content">
          <div className="section-title mb-16px">WELCOME TO</div>
          <h2>Your Poppy Moodboard</h2>
          <div className="event-detail mt-16px">
            <div>
              {quizSession?.customer.name} & {quizSession?.customer.partnerName}
            </div>
            <div>{moment(quizSession?.event.date).format('MMMM DD, YYYY')}</div>
          </div>
          <div className="actions mt-24px">
            <Button className="button-stem width-fit-content" onClick={bookYourConsult}>
              BOOK YOUR CONSULT
            </Button>
            <Button className="button-stem secondary width-fit-content" onClick={shopFlowers}>
              SHOP FLOWERS
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}
