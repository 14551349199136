import React, { ChangeEvent, useState } from 'react'

interface IConversationFormProps {
  handleSubmit: (content: string) => void
}

export const ConversationForm = (props: IConversationFormProps) => {
  const [conversationContent, setConversationContent] = useState('')

  const handleClickSubmit = () => {
    props.handleSubmit(conversationContent)
  }

  const handleChangeContent = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setConversationContent(e.target.value)
  }

  return (
    <div className="column-6 w-col w-col-6 conversation-form">
      <h2 className="heading-start-conversation"> Start a conversation </h2>
      <div className="subtitle">
        Send us a message and your Proposal Designer will work with you to design the wedding flowers of your dreams
        based on your unique preferences and needs.
      </div>
      <div className="div-block-168">
        <textarea
          value={conversationContent}
          placeholder="Tell us more about your style, your venue, or anything else you want us to know!"
          onChange={handleChangeContent}
        />
      </div>
      <button
        disabled={conversationContent.trim().length < 1}
        className="button button-basic mt-34px"
        onClick={handleClickSubmit}
      >
        Send us a message
      </button>
    </div>
  )
}
