/* eslint-disable @typescript-eslint/no-explicit-any */
import { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import moment from 'moment'

import { IBooleanDynamicPrototype, IOption } from 'src/types'
import { combineToFullName, splitFullName, validate } from 'src/helpers'
import { useAppContext } from 'src/helpers/app'
import { isValidPhoneNumber } from 'src/common'
import { SegmentAction, segmentIdentify, segmentTracking } from 'src/helpers/segment'
import { REFERRAL_SOURCES } from 'src/pages/shared'

import { QuizQuestionContext, SCREEN_QUIZ_QUESTION } from '../../contextAPI'
import { useLocation } from '../../components/Location'

interface ICoupleInfo {
  weddingDate: string | Date
  emailAddress: string
  firstName: string
  lastName: string
  partnerFirstName: string
  partnerLastName: string
  phoneNumber: string
  fullName: string
  partnerFullName: string
}

const defaultValue: ICoupleInfo = {
  weddingDate: '',
  phoneNumber: '',
  emailAddress: '',
  firstName: '',
  lastName: '',
  partnerFirstName: '',
  partnerLastName: '',
  fullName: '',
  partnerFullName: '',
}

// const segmentTrackingDetail = (experiment: string[]) => {
//   if (!experiment.length) {
//     segmentTracking(SegmentAction.SQ_DETAILx2_VARIANT_1, {
//       quizType: 'CANNY_VALLEY',
//       experience: 'No Changes - what’s currently live, all fields required',
//       weight: '33%',
//     })
//     return
//   }
//   if (experiment.length === 1 && experiment.includes(QuizExperience.ATTRIBUTION_HIDDEN)) {
//     segmentTracking(SegmentAction.SQ_DETAILx2_VARIANT_3, {
//       quizType: 'CANNY_VALLEY',
//       experience: 'Last Name Shown - Last Name Shown',
//       weight: '33%',
//     })
//     return
//   }
//   segmentTracking(SegmentAction.SQ_DETAILx2_VARIANT_2, {
//     quizType: 'CANNY_VALLEY',
//     experience: 'Last Names Not Shown - Attribute Question Not Shown',
//     weight: '33%',
//   })
// }

export const useCoupleInfo = () => {
  const {
    data: { weddingDate, planningStage, referralSource: refSource },
    setQuizData,
    setQuizScreen,
    setRecordResponse,
  } = useContext(QuizQuestionContext)
  const location = useLocation()
  const { sessionID, quizExperience } = useAppContext()
  const [coupleInfo, setCoupleInfo] = useState<ICoupleInfo>(defaultValue)
  const [focused, setFocused] = useState<IBooleanDynamicPrototype>({})
  const [loading, setLoading] = useState(false)
  const [validateError, setValidateError] = useState<any>({})
  const [stage, setStage] = useState<string>(planningStage?.stage ?? '')
  const [alreadyBookedSelected] = useState<IBooleanDynamicPrototype>(planningStage?.vendors ?? {})
  const [intent] = useState<string>(planningStage?.intent ?? '')
  const selectedRef = REFERRAL_SOURCES.find((ref) => ref.value === refSource)
  const [referralSource, setReferralSource] = useState<IOption | null>(
    refSource ? { value: refSource, label: selectedRef?.label || '' } : null
  )

  const handleChangeCoupleInfo = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target
    if (name === 'fullName') {
      const [firstName, lastName] = splitFullName(value)
      setCoupleInfo((data) => ({ ...data, [name]: value, firstName, lastName }))
      return
    }
    if (name === 'partnerFullName') {
      const [partnerFirstName, partnerLastName] = splitFullName(value)
      setCoupleInfo((data) => ({ ...data, [name]: value, partnerFirstName, partnerLastName }))
      return
    }
    setCoupleInfo((data) => ({ ...data, [name]: value }))
  }

  const handleChangeDate = useCallback((day: Date) => {
    setCoupleInfo((oldData) => ({ ...oldData, weddingDate: day }))
  }, [])

  const handleBlurInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault()
    event.stopPropagation()
    const { name } = event.target
    setFocused((data) => ({ ...data, [name]: true }))
  }
  const handleBlurSelect = (event: React.FocusEvent<HTMLInputElement>) => {
    event.preventDefault()
    event.stopPropagation()
    const { name } = event.target
    setFocused((data) => ({ ...data, [name]: true }))
  }

  const handleChangePhoneNumber = (value: string) => {
    setCoupleInfo((data) => ({ ...data, phoneNumber: value }))
  }

  const handleBlurPhoneNumber = () => {
    setFocused((data) => ({ ...data, phoneNumber: true }))
  }

  const handleChangeStage = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target
    setStage(value)
  }

  const handleChangeReferralSource = (value: IOption | null) => {
    setReferralSource(value)
  }

  const gTagSetUserData = useCallback((param: any) => {
    const report = (window as { [key: string]: any })['gtag']
    if (report) {
      report('set', 'user_data', param)
    }
  }, [])

  const handleNext = async () => {
    if (!focused.all) setFocused((data) => ({ ...data, all: true }))
    if (Object.keys(validateForm(true)).length) return

    setLoading(true)
    const recordResponse = await setQuizData({
      weddingDate: {
        weddingDate: coupleInfo.weddingDate,
        firstName: coupleInfo.firstName,
        lastName: coupleInfo.lastName,
        partnerFirstName: coupleInfo.partnerFirstName,
        partnerLastName: coupleInfo.partnerLastName,
        fullName: combineToFullName(coupleInfo.firstName, coupleInfo.lastName),
        partnerName: combineToFullName(coupleInfo.partnerFirstName, coupleInfo.partnerLastName),
        emailAddress: coupleInfo.emailAddress.toLowerCase(),
        phoneNumber: coupleInfo.phoneNumber,
      },
      location: location.locationSelected,
      quizExperience,
      planningStage: { vendors: alreadyBookedSelected, stage, intent },
      referralSource: referralSource?.value,
    })

    segmentIdentify(sessionID, {
      name: combineToFullName(coupleInfo.firstName, coupleInfo.lastName),
      email: coupleInfo.emailAddress.toLowerCase(),
      location: location.locationSelected,
      serviceable: recordResponse?.data.serviceable,
    })
    segmentTracking(SegmentAction.SQ_CV_DETAILS_COMPLETED)
    gTagSetUserData({
      email: coupleInfo.emailAddress.toLowerCase(),
      address: {
        firstName: coupleInfo.firstName,
        lastName: coupleInfo.lastName,
      },
    })
    setLoading(false)
    if (recordResponse?.data) {
      if (recordResponse.data.validateError?.email) {
        setValidateError({ emailAddress: 'Invalid email' })
        return
      }
      setRecordResponse(recordResponse.data)
      if (recordResponse?.data?.proposal?.hadProposal) {
        setQuizScreen(SCREEN_QUIZ_QUESTION.MET_BEFORE)
        return
      }
      if (recordResponse.data.serviceable) {
        setQuizScreen(SCREEN_QUIZ_QUESTION.CHOOSE_PALETTE)
      }
    }
  }

  useEffect(() => {
    if (weddingDate) {
      const [partnerFirstName, partnerLastName] = splitFullName(weddingDate.partnerName)
      setCoupleInfo({
        weddingDate: weddingDate.weddingDate,
        emailAddress: weddingDate.emailAddress,
        phoneNumber: weddingDate.phoneNumber,
        firstName: weddingDate.firstName || '',
        lastName: weddingDate.lastName || '',
        partnerFirstName: partnerFirstName || '',
        partnerLastName: partnerLastName || '',
        fullName: weddingDate.fullName,
        partnerFullName: weddingDate.partnerName,
      })
    }
  }, [weddingDate])

  const validateForm = useCallback(
    (focusedAll = false) => {
      const error: any = {}

      if ((focused.place || focused.all || focusedAll) && location.nextDisabled)
        error.location = 'Location is required!'

      if (focused.firstName || focused.all || focusedAll) {
        if (!coupleInfo.firstName.trim()) error.firstName = 'First name is required!'
      }
      if (focused.lastName || focused.all || focusedAll) {
        if (!coupleInfo.lastName.trim()) error.lastName = 'Last name is required!'
      }
      if (focused.partnerFirstName || focused.all || focusedAll) {
        if (!coupleInfo.partnerFirstName.trim()) error.partnerFirstName = 'First name is required!'
      }
      if (focused.partnerLastName || focused.all || focusedAll) {
        if (!coupleInfo.partnerLastName.trim()) error.partnerLastName = 'Last name is required!'
      }

      if (focused.weddingDate || focused.all || focusedAll) {
        if (!coupleInfo.weddingDate) error.weddingDate = 'Wedding date is required!'
        if (coupleInfo.weddingDate && moment(coupleInfo.weddingDate).isBefore(moment()))
          error.weddingDate = 'Kindly select a day in the future!'
      }
      if (focused.emailAddress || focused.all || focusedAll) {
        if (!coupleInfo.emailAddress) error.emailAddress = 'Email address is required!'
        if (coupleInfo.emailAddress && !validate.email(coupleInfo.emailAddress)) error.emailAddress = 'Invalid email'
      }
      if (focused.phoneNumber || focused.all || focusedAll) {
        if (!coupleInfo.phoneNumber) error.phoneNumber = 'Phone number is required!'
        if (coupleInfo.phoneNumber && !isValidPhoneNumber(coupleInfo.phoneNumber))
          error.phoneNumber = 'Invalid phone number!'
      }
      if (!stage || stage === '') {
        error.stage = 'Planning stage is required!'
      }
      if (focused.referralSource || focused.all || focusedAll) {
        if (!referralSource) error.referralSource = 'Please select a option!'
      }

      setValidateError(error)
      return error
    },
    [coupleInfo, focused, location.nextDisabled, stage, referralSource]
  )

  useMemo(() => validateForm(), [validateForm])

  useEffect(() => {
    segmentTracking(SegmentAction.SQ_CV_LOADED)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return {
    coupleInfo,
    location,
    validateError,
    loading,
    weddingDate,
    stage,
    referralSource,
    handleChangeCoupleInfo,
    handleChangeDate,
    handleBlurInput,
    handleChangePhoneNumber,
    handleBlurPhoneNumber,
    handleNext,
    handleChangeStage,
    handleChangeReferralSource,
    handleBlurSelect,
  }
}
