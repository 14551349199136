import { useRef } from 'react'
import { useAppContext } from 'src/helpers/app'
import { useCustomerExperienceContext } from '../../context'
import { getURLCheckout, IExtraDataCheckout } from '../../helper'

export const useCheckoutPopup = () => {
  const { appData } = useAppContext()
  const { cart, showCheckoutPopup, setShowCheckoutPopup } = useCustomerExperienceContext()

  const dateRef = useRef<HTMLInputElement>(null)

  const handleClosePopup = () => {
    setShowCheckoutPopup(false)
  }

  const handleSubmit = async () => {
    const extraData: IExtraDataCheckout = {
      pickupInfo: dateRef.current?.value ?? '',
    }
    const urlCheckout = getURLCheckout(cart, appData.customerFullInfo, extraData)
    window.open(urlCheckout, '_blank')
  }

  return {
    showCheckoutPopup,
    dateRef,
    setShowCheckoutPopup,
    handleClosePopup,
    handleSubmit,
  }
}
