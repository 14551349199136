import React, { ReactNode } from 'react'
import { H2, ModalBase, XIcon } from 'src/common'

interface IModalStyleDesktopProps {
  handleClose: () => void
  isOpen: boolean
  children?: ReactNode
  className?: string
}

export const titleDefault = <H2 className="mt-10rem">Uh oh!</H2>

export const ModalStyle = (props: IModalStyleDesktopProps) => {
  return (
    <ModalBase
      isOpen={props.isOpen}
      hiddenClose={true}
      handleClose={props.handleClose}
      classModal={`modal-base modal-style ${props.className ?? ''}`}
      closeElm={
        <p className="modal-close" onClick={props.handleClose}>
          <XIcon />
        </p>
      }
    >
      {props.children}
    </ModalBase>
  )
}
