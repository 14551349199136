import { useEffect, useMemo, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'

import { getScheduledEventInfo, postZapHttp } from 'src/api'
import { postStartConversation } from 'src/api/conversation'

import { ENV } from 'src/config/env'
import { PATH_URL } from 'src/helpers'
import { useAppContext } from 'src/helpers/app'
import {
  ACTION_APP,
  ga4EventApp,
  gaEventApp,
  SCHEDULED_CATEGORY_GA,
  SCHEDULED_LABEL_GA,
} from 'src/helpers/googleAnalyticsService'
import { SegmentAction, segmentTracking } from 'src/helpers/segment'

import { useResultsPage } from '../results/ResultsPage.hook'
import { EventScheduledEvent } from './components'

const calendlyLinkForCertainCustomers =
  'https://calendly.com/poppy-flowers-weddings/poppy-flowers-wedding-consultation-2w'

const getCalendlyEventId = (uri: string) => {
  const splitUri = uri.split('/')
  return splitUri[splitUri.length - 1]
}

export const useScheduleCall = () => {
  const location = useLocation()
  const history = useHistory()
  const scheduledVariant = 'callback'
  const { appData, handleChangeAppData, sessionID } = useAppContext()

  const { customerFullInfo } = useResultsPage()

  const [showCalendly, setShowCalendly] = useState<boolean | null>(null)
  const [isConversation, setIsConversation] = useState<boolean | null>(!!appData.isConversation)
  const [isConversationFromCalendly, setIsConversationFromCalendly] = useState<boolean | null>(false)

  const handleCallbackToday = async () => {
    handleChangeAppData({ countLoading: 1 })
    gaEventApp(ACTION_APP.CALL_SCHEDULED, {
      label: SCHEDULED_LABEL_GA.CALL_TODAY,
    })
    ga4EventApp(ACTION_APP.CALL_SCHEDULED, {
      label: SCHEDULED_LABEL_GA.CALL_TODAY,
    })
    segmentTracking(SegmentAction.SQ_CALL_SCHEDULED, {
      label: SCHEDULED_LABEL_GA.CALL_TODAY,
    })
    try {
      await postZapHttp('', JSON.stringify(customerFullInfo?.customerData))
    } catch (error) {
      console.error('Callback Request Failed', error)
    }

    handleChangeAppData({ countLoading: -1 })
    history.push(`${PATH_URL.CALL_SCHEDULED}${location.search}`)
    window.scrollTo(0, 0)
  }

  const handleAScheduleCall = async () => {
    setShowCalendly(true)
  }

  const handleSendAMessage = async () => {
    setIsConversation(true)
    setIsConversationFromCalendly(true)
  }

  const handleEventScheduled = async (scheduledEvent: EventScheduledEvent) => {
    gaEventApp(ACTION_APP.CALL_SCHEDULED, {
      category: SCHEDULED_CATEGORY_GA.FIREWORK,
    })
    ga4EventApp(ACTION_APP.CALL_SCHEDULED, {
      category: SCHEDULED_CATEGORY_GA.FIREWORK,
    })
    segmentTracking(SegmentAction.SQ_CALL_SCHEDULED, {
      category: SCHEDULED_CATEGORY_GA.FIREWORK,
    })

    // We report adwords conversions by inserting a function into the <head> of the page.
    /* eslint-disable  @typescript-eslint/no-explicit-any */
    if ((window as { [key: string]: any })['gtag_report_conversion']) {
      /* eslint-disable  @typescript-eslint/no-explicit-any */
      ;(window as { [key: string]: any })['gtag_report_conversion']()
    }
    handleChangeAppData({ countLoading: 1 })

    try {
      const urlEventScheduled = scheduledEvent.data.payload.event.uri
      const eventId = getCalendlyEventId(urlEventScheduled)

      const scheduledEventInfo = await getScheduledEventInfo(eventId)
      handleChangeAppData({ scheduledEventInfo })
    } catch (error) {
      console.error('Scheduled Failed', error)
    }

    handleChangeAppData({ countLoading: -1 })
    history.push(`${PATH_URL.CALL_SCHEDULED}${location.search}`)
  }

  const handleSubmitConversation = async (message: string) => {
    handleChangeAppData({ countLoading: 1 })
    try {
      await postStartConversation({
        message,
        sessionId: sessionID,
      })
      gaEventApp(ACTION_APP.MESSAGE_SUBMITTED)
      ga4EventApp(ACTION_APP.MESSAGE_SUBMITTED)
      segmentTracking(SegmentAction.SQ_MESSAGE_SUBMITTED)

      // We report adwords conversions by inserting a function into the <head> of the page.
      /* eslint-disable  @typescript-eslint/no-explicit-any */
      if ((window as { [key: string]: any })['gtag_report_conversion']) {
        /* eslint-disable  @typescript-eslint/no-explicit-any */
        ;(window as { [key: string]: any })['gtag_report_conversion']()
      }

      history.push(`${PATH_URL.CALL_SCHEDULED}${location.search}&msg`)
      /* eslint-disable  @typescript-eslint/no-explicit-any */
    } catch (error: any) {
      console.error('handleSubmitConversation', error)
      alert(error.message ?? 'Submit Failed')
    }

    handleChangeAppData({ countLoading: -1 })
  }

  const urlCalendly = useMemo(() => {
    let isCertainCustomers = false

    if (customerFullInfo?.calendlyLink) return customerFullInfo.calendlyLink

    if (customerFullInfo) {
      const { customerData } = customerFullInfo
      const { budget } = customerData

      isCertainCustomers = budget <= 500
    }

    return isCertainCustomers ? calendlyLinkForCertainCustomers : ENV.REACT_APP_CALENDLY_URL
  }, [customerFullInfo])

  useEffect(() => {
    if (scheduledVariant === null) {
      handleChangeAppData({ countLoading: 1 })
    } else {
      setShowCalendly(false)
      // handleChangeAppData({ countLoading: -1 })
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scheduledVariant])

  return {
    showCalendly,
    customerFullInfo,
    urlCalendly,
    isConversation,
    isConversationFromCalendly,
    handleSubmitConversation,
    handleEventScheduled,
    handleCallbackToday,
    handleAScheduleCall,
    handleSendAMessage,
  }
}
