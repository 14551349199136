/* eslint-disable @typescript-eslint/no-explicit-any */
import ReactGA from 'react-ga'
import ReactGA4 from 'react-ga4'

import { ENV } from 'src/config/env'

ReactGA.initialize(ENV.REACT_APP_GOOGLE_ANALYTICS)
ReactGA4.initialize(ENV.REACT_APP_GOOGLE_ANALYTICS_4)

const APP_NAME_VERSION = 'Quiz 1.0'

export enum ACTION_APP {
  QUIZ_LOADED = 'Quiz loaded',
  QUIZ_SUBMITTED = 'Quiz Submitted',
  QUESTION_COMPLETED = 'Question Completed',
  IMAGE_SWIPED = 'Image Swiped',
  CONTACT_INFO = 'Contact Info Submitted',
  FULLY_QUALIFIED_LEAD = 'Fully Qualified Lead',
  CALL_SCHEDULED = 'Call Scheduled',
  MESSAGE_SUBMITTED = 'Message Submitted',
  INITIAL_ENGAGEMENT = 'initial_engagement',
  IMAGE_RESPONSE_SWIPE = 'IMAGE_RESPONSE_SWIPE',
  IMAGE_RESPONSE_TAP = 'IMAGE_RESPONSE_TAP',
  BOOK_NOW_CLICKED = 'Book Now Clicked',

  // add path name for quiz
  DETAIL_LOADED = 'DETAIL_LOADED',
  PERSONAL_DETAIL_LOADED = 'PERSONAL_DETAIL_LOADED',
  WEDDING_DETAIL_LOADED = 'WEDDING_DETAIL_LOADED',
  BUDGET_LOADED = 'BUDGET_LOADED',
  COLOR_PALETTE_LOADED = 'COLOR_PALETTE_LOADED',
  AVAILABILITY_LOADED = 'AVAILABILITY_LOADED',
  UNAVAILABILITY_LOADED = 'UNAVAILABILITY_LOADED',
}

export enum SCHEDULED_CATEGORY_GA {
  FIREWORK = 'Firework',
  HOTLINE = 'Hotline',
}

export enum SCHEDULED_LABEL_GA {
  CALL_TODAY = 'Call Today',
}

export enum QUESTION_LABELS_GA {
  EVENT_DATE = 'Event Date',
  COVID_IMPACT = 'COVID Impact',
  PLANNING_STAGE = 'Planning Stage',
  FLORAL_FOCUS = 'Floral Focus',
  BOOKED_VENDORS = 'Booked Vendors',
  VENUE_LOCATION = 'Venue Location',
  GUEST_COUNT = 'Guest Count',
  TABLE_TYPES = 'Table Types',
  RITUALS = 'Rituals',
  COUPLE_NEEDS = 'Couple Needs',
  BOUQUET_COUNT = 'Bouquet Count',
  BOUTONNIERE_COUNT = 'Boutonnière Count',
  ITEM_NEEDS = 'Item Needs',
  COLOR = 'Color',
  BOUQUET_SHAPE = 'BOUQUET_SHAPE',
  BUDGET = 'Budget',
  PINTEREST_BOARD = 'Pinterest Board',
  OTHER_NOTES = 'Other notes',
  CUSTOM_PALETTE = 'Custom color palette',
  INSPIRATION_PHOTO = 'Inspiration photo',
  SAMPLE_FLOWER = 'Sample flower',
}

export const gaEventApp = (
  action: ACTION_APP,
  args?: Partial<ReactGA.EventArgs>,
  trackerNames?: ReactGA.TrackerNames | undefined
) => {
  ReactGA.event(
    {
      category: APP_NAME_VERSION,
      ...args,
      action: action,
      transport: 'beacon',
    },
    trackerNames
  )
}

export const gaPageview = (page: string) => {
  ReactGA.pageview(page)
}

export const ga4SendPageview = (page: string) => {
  ReactGA4.send({ hitType: 'pageview', page })
}

export const ga4EventApp = (action: ACTION_APP, args?: any) => {
  ReactGA4.event(action, {
    ...args,
    category: APP_NAME_VERSION,
    transport: 'beacon',
  })
}
