import React from 'react'
import { CalendlyEventListener, InlineWidget } from 'react-calendly'
import { CalendlyEvent } from 'react-calendly/typings/calendly'

export type EventScheduledEvent = MessageEvent<{
  event: CalendlyEvent.EVENT_SCHEDULED
  payload: {
    event: {
      /**
       * @description Canonical reference (unique identifier) to the event that was scheduled.
       * @example https://calendly.com/api/v2/scheduled_events/AAAAAAAAAAAAAA
       * @see {@link https://developer.calendly.com/docs/api-docs/reference/calendly-api/openapi.yaml/paths/~1scheduled_events~1%7Buuid%7D/get} for further information.
       */
      uri: string
    }
    invitee: {
      /**
       * @description Canonical reference (unique identifier) for the invitee who scheduled the event.
       * @example https://calendly.com/api/v2/scheduled_events/AAAAAAAAAAAAAA/invitees/AAAAAAAAAAAAAA
       * @see {@link https://developer.calendly.com/docs/api-docs/reference/calendly-api/openapi.yaml/paths/~1scheduled_events~1%7Bevent_uuid%7D~1invitees~1%7Binvitee_uuid%7D/get} for further information.
       */
      uri: string
    }
  }
}>

interface ICalendlyProps {
  name: string
  email: string
  url: string
  handleEventScheduled: (e: EventScheduledEvent) => void
  handleSendAMessage: () => void
}

export const Calendly = (props: ICalendlyProps) => {
  return (
    <div className="column-6 w-col w-col-6 w-col-stack">
      <div id="calendly-wrapper" className="div-block-52">
        <CalendlyEventListener onEventScheduled={props.handleEventScheduled}>
          <InlineWidget
            url={props.url}
            styles={{ height: '100%', width: '100%' }}
            prefill={{
              name: props.name,
              email: props.email,
            }}
          />
        </CalendlyEventListener>
        <button className="button button-text mt-14px" onClick={props.handleSendAMessage}>
          Can't find a good time? Send us a message.
        </button>
      </div>
    </div>
  )
}
