import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

import { SESSION_NAME_LOCAL, useAppContext } from 'src/helpers/app'
import { getCustomerInfo } from 'src/api/customer'
import { convertSearchParamsToSearchObject } from 'src/helpers'

import { ICart, useCustomerExperienceContext } from './context'
import { NAME_OPTION_CODE, sortProduct } from './helper'

export const useCustomerExperience = () => {
  const location = useLocation<{ from: string }>()
  const { setProducts, setCart } = useCustomerExperienceContext()
  const { handleChangeAppData, sessionID } = useAppContext()
  const searchObject = convertSearchParamsToSearchObject(location.search)

  useEffect(() => {
    const getInfo = async () => {
      try {
        if (location?.state?.from !== 'quiz-question') {
          handleChangeAppData({ countLoading: 1 })
        }
        const session = searchObject?.session || sessionID || localStorage.getItem(SESSION_NAME_LOCAL)

        const customerFullInfo = await getCustomerInfo(session ?? '')
        const { productOptions } = customerFullInfo

        const productOptionsSorted = sortProduct(productOptions)

        setProducts(productOptionsSorted)
        handleChangeAppData({ customerFullInfo: customerFullInfo })

        const cartDefaultValue: ICart = {}
        Object.keys(productOptionsSorted).forEach((key) => {
          // default quantity
          let quantity = 0

          if (key === NAME_OPTION_CODE.BRIDESMAIDS_BOUQUET) {
            quantity = customerFullInfo?.customerData?.additionalBouquetsNeeded ?? 1
          } else if (key === NAME_OPTION_CODE.BOUTS) {
            quantity = customerFullInfo?.customerData?.additionalBoutsNeeded ?? 1
          } else {
            quantity = 1
          }

          cartDefaultValue[key] = {
            quantity: quantity,
            product: productOptionsSorted[key][0],
          }
        })
        setCart(cartDefaultValue)
      } catch (error) {}
      if (location?.state?.from === 'quiz-question') {
        handleChangeAppData({ countLoadingTestimonial: -1 })
      } else {
        handleChangeAppData({ countLoading: -1 })
      }
    }

    getInfo()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
}
