import { useMemo, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { postHttp } from 'src/api'
import { EventScheduledEvent } from 'src/common/components/Calendly'
import { ENV } from 'src/config/env'
import { PATH_API } from 'src/helpers'
import { useAppContext } from 'src/helpers/app'
import { SegmentAction, segmentTracking } from 'src/helpers/segment'

const getCalendlyEventId = (uri: string) => {
  const splitUri = uri.split('/')
  return splitUri[splitUri.length - 1]
}

export const useScheduleACall = () => {
  const { quizSession, sessionID, handleChangeAppData } = useAppContext()
  const [loading, setLoading] = useState(false)
  const history = useHistory()

  const urlCalendly = useMemo(() => {
    if (quizSession?.event?.owner?.calendlyLink) return quizSession.event.owner.calendlyLink
    return ENV.REACT_APP_CALENDLY_URL
  }, [quizSession?.event])

  const handleEventScheduled = async (scheduledEvent: EventScheduledEvent) => {
    if (!quizSession?.event.id) return

    setLoading(true)
    const urlEventScheduled = scheduledEvent.data.payload.event.uri
    const calendlyEventId = getCalendlyEventId(urlEventScheduled)
    const response = await postHttp(PATH_API.scheduledACall, {
      eventId: quizSession?.event.id,
      calendlyEventId,
    })
    handleChangeAppData({ scheduledEventInfo: response.data })
    setLoading(false)
    segmentTracking(SegmentAction.SQ_CV_SCHEDULE_CALL_COMPLETED)
    history.push(`/post-moodboard/${sessionID}`)
  }

  return {
    urlCalendly,
    customer: quizSession?.customer,
    handleEventScheduled,
    loading,
  }
}
