import React from 'react'
import { H2, Button } from 'src/common'
import { ModalStyle } from 'src/common/components/modal/ModalStyle'

import { useCheckoutPopup } from './CheckoutPopup.hook'

import './CheckoutPopup.scss'

export const CheckoutPopup = () => {
  const { showCheckoutPopup, dateRef, handleClosePopup, handleSubmit } = useCheckoutPopup()

  if (!showCheckoutPopup) {
    return <></>
  }

  return (
    <ModalStyle className={`experience-popup message-popup`} isOpen={showCheckoutPopup} handleClose={handleClosePopup}>
      <div className="warning-wrapper">
        <div className="warning-content">
          <H2>Delivery Date & Time</H2>
          <div className="sub-title">Tell us when your wedding flowers need to arrive.</div>
          <div className="input-wrapper">
            <input type="text" className="input date-field" placeholder="Date and Time" ref={dateRef} />
          </div>
          <div className="button-wrapper">
            <Button className="button button-basic button-submit mlr-10rem mb-10rem" onClick={handleSubmit}>
              submit
            </Button>
          </div>
        </div>
      </div>
    </ModalStyle>
  )
}
