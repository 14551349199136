import React, { PropsWithChildren, useEffect, useState } from 'react'

import './ResultsPage.scss'
import { ImageWithLogoLazy } from 'src/common'
import { ENV } from 'src/config/env'
import { useNormalResult } from './ResultsPage.hook'

import './IntroduceStyle.scss'
import { PATH_API, PATH_URL } from 'src/helpers'
import { SegmentAction, segmentTracking } from 'src/helpers/segment'
import { postHttp } from 'src/api'
import { useAppContext } from 'src/helpers/app'
import { ACTION_APP, ga4EventApp, gaEventApp } from 'src/helpers/googleAnalyticsService'

// const redirectBookNow = 'https://www.poppyflowers.com/reserve-your-date'

interface IUrgencyHeaderProps {
  isConversationPrimary: boolean
  handleScheduleACall: () => void
  handleGetInTouch: () => void
}

interface BookNowButtonProps {
  className: string
  urgencyHeader?: IUrgencyHeaderProps
}

interface CreateCheckoutSessionPayload {
  sessionId: string
}

const createCheckoutSession = (payload: CreateCheckoutSessionPayload) => {
  return postHttp(PATH_API.postCheckoutSession, payload)
}

export const BookNowButton = (props: BookNowButtonProps) => {
  const { sessionID } = useAppContext()
  const [loading, setLoading] = useState<boolean>(false)

  if (!props.urgencyHeader) {
    const booknowHandler = async () => {
      try {
        setLoading(true)
        segmentTracking(SegmentAction.SQ_BOOK_NOW_CLICKED)
        gaEventApp(ACTION_APP.BOOK_NOW_CLICKED)
        ga4EventApp(ACTION_APP.BOOK_NOW_CLICKED)
        const response = await createCheckoutSession({ sessionId: sessionID })
        const session = response.data
        window.location.href = session.url
      } catch (error) {
      } finally {
        setLoading(false)
      }
    }
    return (
      <button onClick={booknowHandler} className={'book-now-btn ' + props.className}>
        Book Now - $149
        {loading && <div className="loader"></div>}
      </button>
    )
  }

  const { isConversationPrimary, handleGetInTouch, handleScheduleACall } = props.urgencyHeader

  return (
    <button className={props.className} onClick={isConversationPrimary ? handleGetInTouch : handleScheduleACall}>
      {isConversationPrimary ? 'Start a conversation' : 'schedule a call'}
    </button>
  )
}

interface IGetInTouchButtonProps {
  className?: string
  handleClick: () => void
}

export const GetInTouchButton = (props: PropsWithChildren<IGetInTouchButtonProps>) => {
  return (
    <button className={props.className} onClick={props.handleClick}>
      Start a conversation
    </button>
  )
}

interface IIntroduceStyleProps {
  hideActions?: boolean
  urgencyHeader?: (props: IUrgencyHeaderProps) => JSX.Element
}

export const IntroduceStyle = (props: IIntroduceStyleProps) => {
  const { infoProposal, customerFullInfo, handleScheduleACall, handleGetInTouch } = useNormalResult()

  const [stickyElementOffsetTop, setStickyElementOffsetTop] = useState<number>(0)
  useEffect(() => {
    setStickyElementOffsetTop(document.getElementById('sticky-btn')?.offsetTop || 0)
    const handleScroll = () => {
      // const stickyElement = document.getElementById('sticky-btn')
      if (window.scrollY >= stickyElementOffsetTop) {
        // stickyElement?.classList.add('is-sticky')
      } else {
        // stickyElement?.classList.remove('is-sticky')
      }
    }
    document.addEventListener('scroll', handleScroll)
    return () => {
      document.removeEventListener('scroll', handleScroll)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [infoProposal])

  if (!infoProposal) {
    return <></>
  }

  const { templateConfig } = infoProposal

  const isBookNowPrimaryCTA = customerFullInfo?.primaryCTAType === 'BOOK_NOW'
  const ctaConfigs = customerFullInfo?.ctaConfigs || ['START_CONVERSATION']
  const isConversationPrimary = ctaConfigs[0] === 'START_CONVERSATION' && !isBookNowPrimaryCTA

  const conversationButtonCss = isConversationPrimary ? 'button button-basic' : 'button button-secondary'
  const scheduleButtonCss = isConversationPrimary ? 'button button-secondary' : 'button button-basic'

  let topGif = templateConfig.topGif

  if (window.location.pathname.includes(PATH_URL.$500_CUSTOMER) && ENV.PREMERCHANDIZED_KITS) {
    topGif = templateConfig.topGifPremerchandized
  }

  const openChatWithUs = () => {
    /* eslint-disable @typescript-eslint/no-explicit-any */
    const windowGlobal = window as any
    if (windowGlobal.GorgiasChat) {
      windowGlobal.GorgiasChat.open()
    }
  }

  return (
    <div className="section-36">
      {props.urgencyHeader &&
        props.urgencyHeader({
          isConversationPrimary,
          handleGetInTouch,
          handleScheduleACall,
        })}
      <div className="container-28 container mt-0px-auto">
        <div className="similar-flowers-wrapper">
          <div className="div-block-153">
            <ImageWithLogoLazy src={topGif} loading="lazy" id="topGif" alt="" className="image-89" />
          </div>

          {/* <div>
            <a className="lookbook" target="_blank" rel="noreferrer" href={REDIRECT_PATH.LOOK_BOOK}>
              See past Poppy Weddings in our Lookbook
            </a>
          </div> */}

          {/* {customerFullInfo?.lookbookExampleUrl && (
            <div>
              <a className="lookbook" target="_blank" rel="noreferrer" href={customerFullInfo.lookbookExampleUrl}>
                See similar flowers from a real Poppy wedding
              </a>
            </div>
          )} */}
        </div>
        <div className="div-block-152">
          <h3 id="nameHeading" className="heading-79">
            {infoProposal.nameTitle}
          </h3>
          <div id="styleText" className="text-block-91">
            The countryside is the perfect setting for your special day. You’re drawn to a natural floral style with a
            relaxed feel and a rich, warm floral palette. Refined florals, flowing greenery and candle light will
            beautifully complement you and your partner, making your wedding truly timeless and unique.
          </div>
          {isBookNowPrimaryCTA && (
            <div className="book-now-text">
              Book now with an initial deposit of $149 to secure your date with Poppy and get started with your wedding
              floral design.
            </div>
          )}

          <div
            className={`${props.hideActions ? 'd-none' : 'div-block-192'} ${
              isConversationPrimary || isBookNowPrimaryCTA ? '' : 'text-experience'
            }`}
          >
            {isBookNowPrimaryCTA ? (
              <>
                <div id="sticky-btn">
                  <div className="sticky-item">
                    <BookNowButton className={`button button-basic w-button`} />
                  </div>
                </div>
                <div className="div-block-193"></div>
                <button onClick={openChatWithUs} className={`button button-secondary w-button`}>
                  Chat with us
                </button>
              </>
            ) : (
              <>
                {ENV.START_CONVERSATION_EXPERIMENT && ctaConfigs.includes('START_CONVERSATION') && (
                  <div id={isConversationPrimary ? 'sticky-btn' : 'sec-btn'}>
                    <div className="sticky-item">
                      <GetInTouchButton
                        className={`${conversationButtonCss} w-button`}
                        handleClick={handleGetInTouch}
                      />
                    </div>
                  </div>
                )}
                <div className="div-block-193"></div>
                {ctaConfigs.includes('SCHEDULE_CALL') && (
                  <div id={!isConversationPrimary ? 'sticky-btn' : 'sec-btn'}>
                    <div className="sticky-item">
                      <button onClick={handleScheduleACall} className={`${scheduleButtonCss} w-button`}>
                        schedule a call
                      </button>
                    </div>
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
