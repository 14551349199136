import { useEffect, useRef, useState } from 'react'

import { useAppContext } from 'src/helpers/app'

import roundTables from 'src/assets/images/round_table.png'
import farmTables from 'src/assets/images/farm_table.png'
import logo from 'src/assets/images/logo.png'

import { budgets } from '../Budget/Budget.hook'

export const imagesPreload = [...budgets.map((item) => item.image ?? ''), logo, roundTables, farmTables]

export const useAssetPreload = () => {
  const numberImagesLoadedRef = useRef(0)
  const [isShowContent, setIsShowContent] = useState(false)
  const { handleChangeAppData } = useAppContext()

  useEffect(() => {
    if (numberImagesLoadedRef.current < imagesPreload.length) {
      handleChangeAppData({ isLoading: true })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleImageLoaded = () => {
    // Turn off loading when all images are finished loading
    if (++numberImagesLoadedRef.current >= imagesPreload.length) {
      handleChangeAppData({ isLoading: false })
      setIsShowContent(true)
    }
  }

  return {
    isShowContent,
    handleImageLoaded,
  }
}
