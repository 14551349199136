import React from 'react'

import { ButtonBase } from 'src/common'

import logo from 'src/assets/images/logo.png'

import { useStyleInfo } from './useStyleInfo'
import { isMobile } from 'src/helpers'

export const StyleInfo: React.FC = () => {
  const { styleInfo, styleImages, customer, handleClickProposal } = useStyleInfo()

  const title = customer?.firstName ? `${customer?.firstName}'s Personal Style` : 'Your Unique Wedding Style'

  return (
    <div className="style-info-wrapper">
      {isMobile && (
        <>
          <div className="style-info">
            <img src={logo} alt="logo" className="logo" />
            <h1 className="title text-align-center">{title}</h1>
            <div className="info-content">{styleInfo?.styleText}</div>
            <div>
              <ButtonBase onClick={handleClickProposal} className="button button-basic button-proposal mt-25px mb-35px">
                GET YOUR PROPOSAL
              </ButtonBase>
            </div>
            <div className="img-weddings">
              {styleImages.narrow.map((imgUrl, index) => (
                <img key={index} src={imgUrl} alt="narrow image" />
              ))}
              <img src={styleImages.wide} alt="wide image" />
            </div>
            <div className="sub-title">
              Looking for something different? No worries! Our team will work with you to pick your perfect flowers.
            </div>
          </div>
        </>
      )}
      {!isMobile && (
        <>
          <div className="style-info">
            <img src={logo} alt="logo" className="logo" />
            <div className="content-wrapper">
              <div>
                <div>
                  <div className="narrow-wrapper">
                    {styleImages.narrow.map((imgUrl, index) => (
                      <div key={index} className={index === 1 ? 'ml-10px' : ''}>
                        <img src={imgUrl} alt="narrow image" />
                      </div>
                    ))}
                  </div>
                  <div className="wide-wrapper mt-20px">
                    <img src={styleImages.wide} alt="wide image" className="w-100" />
                  </div>
                </div>
                <div className="sub-title">
                  Looking for something different? No worries! Our team will work with you to pick your perfect flowers.
                </div>
              </div>
              <div className="info-wrapper">
                <div>
                  <h1 className="title">{title}</h1>
                  <div className="info-content">{styleInfo?.styleText}</div>
                  <div>
                    <ButtonBase
                      onClick={handleClickProposal}
                      className="button button-basic button-proposal mt-25px mb-35px"
                    >
                      GET YOUR PROPOSAL
                    </ButtonBase>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  )
}
