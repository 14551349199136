import React from 'react'
import { CheckboxButton, Radio } from 'src/common'

import { useRituals, rituals } from './Rituals.hook'

import { QuizQuestionWrapper } from './QuizQuestionWrapper'

export const Rituals = () => {
  const {
    hasRitual,
    nextDisabled,
    ritualsSelected,
    handleSelect,
    handleNext,
    handleBack,
    handleChangeStatus,
  } = useRituals()

  return (
    <>
      <QuizQuestionWrapper
        title="Is there a specific tradition your flowers should reflect?"
        nextAction={{
          disabled: nextDisabled,
          onAction: handleNext,
        }}
        backAction={{
          onAction: handleBack,
        }}
        step={8}
        total={8}
      >
        <div className="quiz-rituals text-align-center">
          <div className="d-flex justify-content-center">
            <Radio
              id="yes"
              value={1}
              defaultChecked={hasRitual === true}
              name="rituals"
              classNames={{ container: 'mr-40px', label: 'basic' }}
              onChange={handleChangeStatus}
            >
              Yes
            </Radio>
            <Radio
              id="no"
              value={0}
              defaultChecked={hasRitual === false}
              name="rituals"
              onChange={handleChangeStatus}
              classNames={{ container: 'ml-40px', label: 'basic' }}
            >
              No
            </Radio>
          </div>
          {hasRitual && (
            <div className="mt-40px">
              <p>Select all that apply</p>
              <div className="rituals-option gap-10">
                {rituals &&
                  rituals.map((item) => (
                    <CheckboxButton
                      key={item.value}
                      value={item.value}
                      className="cb-btn-basic"
                      id={item.value}
                      checked={ritualsSelected[item.value] ?? false}
                      onChange={handleSelect}
                    >
                      {item.label}
                    </CheckboxButton>
                  ))}
              </div>
            </div>
          )}
        </div>
      </QuizQuestionWrapper>
    </>
  )
}
