import React from 'react'

import styleIcon from 'src/assets/images/cannyValley/style-icon.svg'
import flowerRoseIcon from 'src/assets/images/cannyValley/flower-rose-icon.svg'
import walletIcon from 'src/assets/images/cannyValley/wallet-icon.svg'
import savedItemIcon from 'src/assets/images/cannyValley/saved-item.svg'

import './style.scss'

interface QuizStepProps {
  item: number
  step: number
}

const QUIZ_STEPS = [
  {
    title: 'STYLE',
    icon: styleIcon,
    steps: [1, 2, 3],
  },
  {
    title: 'FLOWERS',
    icon: flowerRoseIcon,
    steps: [1],
  },
  {
    title: 'BUDGET',
    icon: walletIcon,
    steps: [1],
  },
  {
    title: 'MOODBOARD',
    icon: savedItemIcon,
    steps: [],
  },
]

export const QuizStep: React.FC<QuizStepProps> = ({ item, step }: QuizStepProps) => {
  const renderItemStep = () => {
    const totalLine = QUIZ_STEPS.length - 1
    return QUIZ_STEPS.map((stepItem, index) => (
      <React.Fragment key={stepItem.title}>
        <div className="item">
          <div className={`step-wrapper ${index + 1 <= item ? 'active' : ''}`}>
            <img src={stepItem.icon} className="step-icon" />
            <span className="step-title mt-5px">{stepItem.title}</span>
          </div>
        </div>
        {index !== totalLine && (
          <div className="step-line-wrapper">
            {stepItem.steps.map((i) => (
              <div
                key={`${stepItem.title}_step_${i}`}
                className={`step-line ${(index + 1 === item && i <= step) || index + 1 < item ? 'active' : ''}`}
                style={{ width: `calc(${100 / stepItem.steps.length}% - 2px)` }}
              />
            ))}
          </div>
        )}
      </React.Fragment>
    ))
  }

  return <div className="quiz-step">{renderItemStep()}</div>
}
