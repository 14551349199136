import React, { ReactNode, useState } from 'react'
import { useTriggerEvent } from 'src/common/hook'
import { IBooleanDynamicPrototype } from 'src/types'
export interface ITableOption {
  id: string
  img?: string
  label: ReactNode
}

export interface ITableOptionItemProps {
  item: ITableOption
  active?: boolean
  onSelect?: (item: ITableOption) => void
}

export const ImageOptionItem: React.FC<ITableOptionItemProps> = (props: ITableOptionItemProps) => {
  const { item, active, onSelect } = props

  const { triggerRef, handleEnterTrigger } = useTriggerEvent()

  return (
    <div
      ref={triggerRef}
      onKeyUp={handleEnterTrigger}
      tabIndex={0}
      className={`image-option-card ${active ? 'active' : ''}`}
      onClick={() => onSelect && onSelect(item)}
    >
      <div className="under-layer">
        <div className="up-layer">
          <div className="image-option-image">
            <img src={item.img} alt="image option" className="d-block" />
          </div>
          <div className="image-option-label">{item.label}</div>
        </div>
      </div>
    </div>
  )
}

export const TableStyleOptionItem: React.FC<ITableOptionItemProps> = (props: ITableOptionItemProps) => {
  const { item, active, onSelect } = props

  const { triggerRef, handleEnterTrigger } = useTriggerEvent()

  return (
    <div
      ref={triggerRef}
      onKeyUp={handleEnterTrigger}
      tabIndex={0}
      className={`table-option-card ${active ? 'active' : ''}`}
      onClick={() => onSelect && onSelect(item)}
    >
      <div className="under-layer">
        <div className="up-layer">
          {item.img ? (
            <div className="image-option-image">
              <img src={item.img} alt="image option" className="d-block" />
            </div>
          ) : (
            ''
          )}
          <div className="option-label">{item.label}</div>
        </div>
      </div>
    </div>
  )
}

export interface ITableOptionSelectBaseProps {
  options: ITableOption[]
  className?: string
  onSelect?: (item: ITableOption) => void
}

export interface IImageOptionSelectProps extends ITableOptionSelectBaseProps {
  defaultActive?: string
}

export const ImageOptionSelect: React.FC<IImageOptionSelectProps> = (props: IImageOptionSelectProps) => {
  const { className, options, defaultActive, onSelect } = props

  const [active, setActive] = useState<string | undefined>(defaultActive)

  const handleSelect = (item: ITableOption) => {
    setActive(item.id)

    onSelect && onSelect(item)
  }

  return (
    <div className={`${className ?? 'image-option-basic'}`}>
      {options.map((item) => (
        <ImageOptionItem
          key={item.id}
          item={item}
          active={(active ?? '' + '') === item.id + ''}
          onSelect={() => handleSelect(item)}
        />
      ))}
    </div>
  )
}

export interface ITableOptionManySelectProps extends ITableOptionSelectBaseProps {
  defaultActives?: IBooleanDynamicPrototype
}

export const TableOptionManySelect: React.FC<ITableOptionManySelectProps> = (props: ITableOptionManySelectProps) => {
  const { className, options, defaultActives, onSelect } = props

  const handleSelect = (item: ITableOption) => {
    onSelect && onSelect(item)
  }

  return (
    <div className={`${className ?? 'image-option-basic'}`}>
      {options.map((item) => (
        <TableStyleOptionItem
          key={item.id}
          item={item}
          active={defaultActives && defaultActives[item.id]}
          onSelect={() => handleSelect(item)}
        />
      ))}
    </div>
  )
}
