import React from 'react'

import { Radio } from 'src/common/components'

import { QuizQuestionWrapper } from '../QuizQuestionWrapper'
import { useFloralFocus, FloralFocusOption } from './FloralFocus.hook'

export const FloralFocus = () => {
  const { nextDisabled, handleNext, handleBack, handleFloralFocus, floralFocusSelected } = useFloralFocus()

  return (
    <QuizQuestionWrapper
      title="Floral Focus"
      backAction={{
        onAction: handleBack,
      }}
      nextAction={{
        onAction: handleNext,
        disabled: nextDisabled,
      }}
      step={2}
      total={5}
    >
      <div className="quiz-guest-booked">
        <div className="quiz-question-header">
          When you think about your wedding flowers, what part of your wedding is most important?
        </div>
        <div className="stage-wrapper text-transform-upper">
          {FloralFocusOption.map((item) => (
            <Radio
              key={item.value}
              value={item.value}
              checked={floralFocusSelected === item.value}
              className="cb-btn-basic btn-vendor mb-10px ml-0px pl-0px mr-15px"
              id={item.value}
              onChange={handleFloralFocus}
            >
              {item.label}
            </Radio>
          ))}
        </div>
        <div className="mt-50px">
          <button disabled={nextDisabled} className="button button-primary mt-14px" onClick={handleNext}>
            CONTINUE TO PLANNING
          </button>
        </div>
      </div>
    </QuizQuestionWrapper>
  )
}
