import { useContext, useMemo, useState } from 'react'
import { ITableOption } from 'src/common/components/select'

import { IRangeSliderOptionItem } from 'src/common/components/slider'
import { isMobile } from 'src/helpers'
import { IBooleanDynamicPrototype } from 'src/types/common'

import { QuizQuestionContext, SCREEN_QUIZ_QUESTION } from '../contextAPI'

const guestList: IRangeSliderOptionItem[] = [
  {
    label: '<50 GUESTS',
    value: 50,
    labelSelected: isMobile ? undefined : '<50 GUESTS',
  },
  {
    label: '100 GUESTS',
    value: 100,
    labelSelected: isMobile ? undefined : '100 GUESTS',
  },
  {
    label: '150 GUESTS',
    value: 150,
    labelSelected: isMobile ? undefined : '150 GUESTS',
  },
  {
    label: '200 GUESTS',
    value: 200,
    labelSelected: isMobile ? undefined : '200 GUESTS',
  },
  {
    label: '250 GUESTS',
    value: 250,
    labelSelected: isMobile ? undefined : '250 GUESTS',
  },
]

export const useGuestsAndTableExpect = () => {
  const { data, setQuizData, setQuizScreen } = useContext(QuizQuestionContext)
  const [guestCountSelected, setGuestCountSelected] = useState(data.guestCount ?? +guestList[2].value)
  const [tableTypeSelected, setTableTypeSelected] = useState<IBooleanDynamicPrototype>(data.tableType || {})
  const [notSureYet, setNotSureYet] = useState<boolean>(false)

  const handleNext = () => {
    setQuizData({ guestCount: guestCountSelected, tableType: tableTypeSelected })
    setQuizScreen(SCREEN_QUIZ_QUESTION.FLOWERS_WEDDING)
  }

  const handleDismiss = () => {
    setNotSureYet(!notSureYet)
    setTableTypeSelected({})
  }

  const handleBack = () => {
    setQuizScreen(SCREEN_QUIZ_QUESTION.YOU_BOOKED)
  }

  const handleChange = (index: number) => {
    setGuestCountSelected(+guestList[index].value)
  }

  const handleChangeGuests = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target
    setGuestCountSelected(+value)
  }

  const handleSelectTable = (item: ITableOption) => {
    setTableTypeSelected((list) => ({ ...list, [item.id]: !list[item.id] }))
    setNotSureYet(false)
  }

  const nextDisabled = useMemo(() => {
    const tableSelected = Object.values(tableTypeSelected).includes(true)
    const canProceed = guestCountSelected !== 0 && (tableSelected || notSureYet)
    return !canProceed
  }, [guestCountSelected, tableTypeSelected, notSureYet])

  const indexNumberGuests = guestList.findIndex((item) => +item.value === +guestCountSelected)

  return {
    guestList,
    indexNumberGuests,
    nextDisabled,
    guestCountSelected,
    handleChange,
    handleDismiss,
    handleBack,
    handleNext,
    handleChangeGuests,
    tableTypeSelected,
    handleSelectTable,
    notSureYet,
  }
}
