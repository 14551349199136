import React from 'react'

import eye from 'src/assets/images/eye.svg'
import money from 'src/assets/images/money-icon.svg'
import bouquet from 'src/assets/images/bouquet-icon.svg'

export const Expect = () => {
  return (
    <div className="expect-wrapper column-5 w-col w-col-6 w-col-stack">
      <p className="tip-top">Poppy Weddings</p>
      <h2 className="heading-81">What to Expect</h2>
      <div className="div-block-168">
        <img src={eye} loading="lazy" alt="eye icon" className="image-94" />
        <div className="paragraph what-to-expect-copy">
          <h4>Meet Your Proposal Designer</h4>
          Review 2-3 options and finalize your budget. We'll help you conceptualize every bouquet, boutonniere, table
          setting, and ceremony arch in line with your floral budget.
        </div>
      </div>
      <div className="div-block-168">
        <img src={money} loading="lazy" alt="money icon" className="image-94" />
        <div className="paragraph what-to-expect-copy">
          <h4>Order your Wedding Flowers</h4>
          Secure your floral order with an initial deposit of $149. We'll hustle behind the scenes from day one to
          day-of, managing the design, logisitics, and installation of your flowers, so you can focus on the big stuff.
        </div>
      </div>
      <div className="div-block-168">
        <img src={bouquet} loading="lazy" alt="bouquet icon" className="image-94" />
        <div className="paragraph what-to-expect-copy">
          <h4>The Big Day</h4>
          Your local Day-Of Florist assembles your arrangements and installs everything on the day of your wedding. Let
          the magic begin.
        </div>
      </div>
    </div>
  )
}
