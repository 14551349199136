export type ListColor = {
  id: string
  code: string
  selected: boolean
  name: string
  hasBorder?: boolean
}

export const DEFAULT_COLORS: ListColor[] = [
  { id: 'red', code: '#be0218', selected: false, name: 'Red' },
  { id: 'burgundy', code: '#73010e', selected: false, name: 'Burgundy' },
  { id: 'pink', code: '#ffaee8', selected: false, name: 'Pink' },
  { id: 'hotPink', code: '#dc1d90', selected: false, name: 'Hot Pink' },
  { id: 'orange', code: '#f5911b', selected: false, name: 'Orange' },
  { id: 'terracotta', code: '#cf7f06', selected: false, name: 'Terracotta' },
  { id: 'cream', code: '#f1f0c2', selected: false, name: 'Cream' },
  { id: 'gold', code: '#e4ca46', selected: false, name: 'Gold' },
  { id: 'yellow', code: '#ffea7c', selected: false, name: 'Yellow' },
  { id: 'ivory', code: '#ffffff', selected: false, name: 'Ivory', hasBorder: true },
  { id: 'jadeGreen', code: '#5e5929', selected: false, name: 'Jade Green' },
  { id: 'silveryGreen', code: '#c2dcbd', selected: false, name: 'Silvery Green' },
  { id: 'lightBlue', code: '#bfd8f5', selected: false, name: 'Light Blue' },
  { id: 'blue', code: '#5c87c9', selected: false, name: 'Blue' },
  { id: 'indigo', code: '#5940bb', selected: false, name: 'Indigo' },
  { id: 'lavender', code: '#ceb8f1', selected: false, name: 'Lavender' },
  { id: 'violet', code: '#9b5fbf', selected: false, name: 'Violet' },
  { id: 'blush', code: '#ffc2bf', selected: false, name: 'Blush' },
  { id: 'purple', code: '#311753', selected: false, name: 'Purple' },
  { id: 'coral', code: '#ff7b7b', selected: false, name: 'Coral' },
]
