import React, { PropsWithChildren } from 'react'
import { useTriggerEvent } from 'src/common/hook'

export type ICheckboxProps = React.InputHTMLAttributes<HTMLInputElement>

export const Checkbox: React.FC<ICheckboxProps> = (props: ICheckboxProps) => {
  const { children, ...checkboxProps } = props
  return (
    <label className="checkbox">
      {children}
      <input type="checkbox" {...checkboxProps} />
      <span className="checkmark"></span>
    </label>
  )
}

export type ICheckboxCustomProps = PropsWithChildren<{
  active?: boolean
  className?: string
  onClick?: (event: React.MouseEvent<HTMLSpanElement, MouseEvent>) => void
}>

export const CheckboxCustom: React.FC<ICheckboxCustomProps> = (props: ICheckboxCustomProps) => {
  const { active, className, onClick, children } = props
  return (
    <div className={`checkbox-custom ${className ?? ''} ${active ? 'active' : ''}`} onClick={onClick}>
      {children}
    </div>
  )
}

export type ICheckboxButtonProps = React.InputHTMLAttributes<HTMLInputElement> & {
  id: string
}

export const CheckboxButton: React.FC<ICheckboxButtonProps> = (props: ICheckboxButtonProps) => {
  const { children, className, id, ...checkboxProps } = props

  const { triggerRef, handleEnterTrigger } = useTriggerEvent()

  return (
    <button tabIndex={-1} className={`checkbox-button ${className ?? ''}`}>
      <input ref={triggerRef} id={id} type="checkbox" {...checkboxProps} />
      <label tabIndex={0} htmlFor={id} className="label" onKeyUp={handleEnterTrigger}>
        {children}
      </label>
    </button>
  )
}

export const RadioButton: React.FC<ICheckboxButtonProps> = (props: ICheckboxButtonProps) => {
  const { children, className, id, ...checkboxProps } = props
  return (
    <button className={`checkbox-button ${className ?? ''}`}>
      <input tabIndex={-1} id={id} type="radio" {...checkboxProps} />
      <label tabIndex={0} htmlFor={id} className="label">
        {children}
      </label>
    </button>
  )
}
