import React from 'react'
import { useWeddingDate } from './WeddingDate.hook'
import { QuizQuestionWrapper } from './QuizQuestionWrapper'
import { OutlinedTextField } from 'src/common/components'
import { PhoneNumber } from 'src/common'
import { QuizExperience, REFERRAL_SOURCES } from 'src/pages/shared'
import { Dropdown } from 'src/common/components/select/Dropdown'

const disabledDays = new Date()
disabledDays.setDate(disabledDays.getDate() + 1)

export const PersonalDetailsComponent = () => {
  const {
    weddingDateData,
    nextWeddingDetailsDisabled,
    formError,
    handleNext,
    handleChangeInput,
    handleBlurInput,
    handleBlurPhoneNumber,
    handleChangePhoneNumber,
    loading,
    referralSource,
    handleChangeReferralSource,
    experiment,
  } = useWeddingDate()

  const renderYourName = () => {
    if (experiment.includes(QuizExperience.LAST_NAME_HIDDEN))
      return (
        <OutlinedTextField
          wrapperClass="mb-10px"
          id="firstNameId"
          value={weddingDateData.fullName ?? ''}
          type="text"
          name="fullName"
          onChange={handleChangeInput}
          // onBlur={handleBlurInput}
          label="Your name"
          isError={formError.fullName}
          errorMsg="Your name is required!"
        />
      )
    return (
      <>
        <OutlinedTextField
          wrapperClass="mb-10px"
          id="firstNameId"
          value={weddingDateData.firstName ?? ''}
          type="text"
          name="firstName"
          onChange={handleChangeInput}
          // onBlur={handleBlurInput}
          label="First name"
          isError={formError.firstName}
          errorMsg="First name is required!"
        />
        <OutlinedTextField
          wrapperClass="mb-10px"
          id="lastNameId"
          value={weddingDateData.lastName ?? ''}
          type="text"
          name="lastName"
          onChange={handleChangeInput}
          // onBlur={handleBlurInput}
          label="Last name"
          isError={formError.lastName}
          errorMsg="Last name is required!"
        />
      </>
    )
  }

  return (
    <>
      <QuizQuestionWrapper
        titleStep="Personal Details"
        title="Let’s start with the easy stuff."
        subtitle="You’ll use this email to log back into your proposal."
        nextAction={{
          disabled: nextWeddingDetailsDisabled,
          onAction: handleNext,
        }}
        backAction={{
          disabled: true,
        }}
        customCss={{
          title: 'title-bold',
          content: '',
        }}
        step={1}
        total={5}
      >
        <div className="get-started-section mt-24px mb-30px">
          <div className="quiz-wedding_date">
            {renderYourName()}

            <OutlinedTextField
              wrapperClass="mb-10px"
              inputClass="email"
              id="emailAddressId"
              value={weddingDateData.emailAddress ?? ''}
              type="text"
              name="emailAddress"
              onChange={handleChangeInput}
              onBlur={handleBlurInput}
              label="Email address"
              isError={formError.emailAddress}
              errorMsg="Invalid email!"
            />

            <div className="input-wrapper mb-10px">
              <div className={`floating-label-wrapper ${weddingDateData.phoneNumber ? 'has-value' : ''}`}>
                <label className="label">Phone number</label>
                <PhoneNumber
                  id="phoneNumberId"
                  country="US"
                  value={weddingDateData.phoneNumber}
                  onChange={handleChangePhoneNumber}
                  onBlur={handleBlurPhoneNumber}
                  inputMode="tel"
                  className={`input w-100 ${formError.phoneNumber ? 'border-red' : ''}`}
                  placeholder=" "
                  name="phoneNumber"
                />
              </div>
              {formError.phoneNumber ? (
                <div className="error">Invalid phone number!</div>
              ) : (
                <div className="error">&nbsp;</div>
              )}
            </div>
          </div>
          <div className="quiz-column-wrapper">
            {!experiment.includes(QuizExperience.ATTRIBUTION_HIDDEN) && (
              <>
                <div className="quiz-guest-booked mt-0px">
                  <div className="quiz-question-header">How did you hear about us? </div>
                  <Dropdown
                    className="quiz-social-dropdown"
                    options={REFERRAL_SOURCES}
                    placeholder="Choose one"
                    value={referralSource ?? undefined}
                    handleChange={handleChangeReferralSource}
                  />
                  {formError.referralSource ? (
                    <div className="error">Please select an option.</div>
                  ) : (
                    <div className="error">&nbsp;</div>
                  )}
                </div>
              </>
            )}
          </div>
          <div className="action-box-wrapper mt-12px pb-70px">
            <button
              disabled={nextWeddingDetailsDisabled || loading}
              className={`button ${loading ? 'loading' : ''}`}
              onClick={handleNext}
            >
              Add wedding details
            </button>
          </div>
        </div>
      </QuizQuestionWrapper>
    </>
  )
}
