import React, { useCallback, useEffect, useState } from 'react'

import { postHttp } from 'src/api'
import { PATH_API } from 'src/helpers/path'
import { useAppContext } from 'src/helpers/app'
import './styles.scss'

const GUESTS_COUNT = [
  { id: 'guest-count-50', title: 'Fewer than 50', value: 50 },
  { id: 'guest-count-100', title: '51 - 100', value: 100 },
  { id: 'guest-count-150', title: '101 - 150', value: 150 },
  { id: 'guest-count-200', title: '151 - 200', value: 200 },
  { id: 'guest-count-201', title: '201+', value: 201 },
]

export const GuestCount: React.FC = () => {
  const [guestCount, setGuestCount] = useState<number | undefined>()
  const { sessionID, quizSession } = useAppContext()

  const handleRecordGuestCount = useCallback(
    async (count: number) => {
      setGuestCount(count)
      if (!sessionID) return
      await postHttp(PATH_API.postRecordQuizResponse, {
        sessionId: sessionID,
        quizResponses: [
          {
            key: 'GUEST_COUNT',
            values: [count + ''],
            isUnknown: !count,
          },
        ],
        eventVenues: [],
      })
    },
    [sessionID]
  )

  useEffect(() => {
    setGuestCount(quizSession?.event.guestCount || undefined)
  }, [quizSession?.event.guestCount])

  return (
    <div className="guest-count-section mt-30px">
      <div className="section-title">Guest Count</div>
      <p className="normal-text mt-16px">How many guests are you expecting at your wedding?</p>
      <div className="guest-range">
        {GUESTS_COUNT.map((item) => (
          <div
            key={item.id}
            className={`item ${item.value === guestCount ? 'selected' : ''}`}
            onClick={() => handleRecordGuestCount(item.value)}
          >
            {item.title}
          </div>
        ))}
      </div>
    </div>
  )
}
