import React from 'react'

import { useAppContext } from 'src/helpers/app'
import { StoryImages } from './StoryImages'
import { DEFAULT_COLORS } from 'src/helpers/colors'
import './style.scss'

function renderFlowerText(colors: string[]) {
  if (colors.length === 0) {
    return 'No colors specified for your flowers.'
  }

  let text = 'Your flowers will feature mostly '
  if (colors.length === 1) {
    text += colors[0]
  }
  if (colors.length === 2) {
    text += colors[0] + ' and ' + colors[1] + '.'
  }
  if (colors.length === 3) {
    text += colors[0] + ', ' + colors[1] + ', and ' + colors[2] + '.'
  }
  if (colors.length > 3) {
    text +=
      colors[0] + ', ' + colors[1] + ', and ' + colors[2] + ' with pops of ' + colors.slice(3, 5).join(' and ') + '.'
  }

  return text
}

export const ColorStory: React.FC = () => {
  const { quizSession } = useAppContext()
  const colorPreference = quizSession?.customer.colorPreference || {}

  const colors = Object.keys(colorPreference)
    .filter((color) => colorPreference[color] > 0)
    .sort((a, b) => colorPreference[b] - colorPreference[a])
    .slice(0, 5)
    .map((c) => DEFAULT_COLORS.find((dc) => dc.code === c)?.name.toLowerCase() || c)

  return (
    <div className="color-story-section">
      <div className="container">
        <div className="color-story">
          <div className="color-story-content">
            <div className="section-title">YOUR COLOR STORY</div>
            <div className="section-subtitle mt-15px">{renderFlowerText(colors)}</div>
          </div>
          <StoryImages />
        </div>
      </div>
    </div>
  )
}
