import { useState, useCallback, useEffect } from 'react'
import { useHistory } from 'react-router-dom'

import { PATH_API, PATH_URL, bindLocationToPlace, splitFullName } from 'src/helpers'
import { postHttp } from 'src/api'
import { useAppContext } from 'src/helpers/app'
import { convertBooleanDynamicToArray, convertLocationQuizToEventVenues } from 'src/helpers/convertHelper'
import { gaEventApp, QUESTION_LABELS_GA, ACTION_APP, ga4EventApp } from 'src/helpers/googleAnalyticsService'
import { SegmentAction, segmentTracking } from 'src/helpers/segment'
import { API, EVENT_LOCATION_TYPE, IEventVenuesPayload, IObjectDynamicPrototype, IOption } from 'src/types'
import moment from 'moment'

import {
  QuizQuestionContextData,
  SCREEN_QUIZ_QUESTION,
  quizQuestionContextDefaultValue,
  IQuizData,
  IRecordQuizResponseAPI,
  QuizQuestionData,
  IWeddingDate,
} from './quizQuestionContext'
import { REFERRAL_SOURCES } from 'src/pages/shared'

interface IQuizResponsesPayload {
  key: string
  values?: number | string | string[] | number[] | boolean
  isUnknown?: boolean
}

interface CustomerInfo {
  firstName: string
  lastName: string
  partnerName: string
  emailAddress: string
  phoneNumber?: string
  attributionSource?: string
}

interface IQuizQuestionPayload {
  sessionId: string
  quizResponses: IQuizResponsesPayload[]
  eventVenues?: IEventVenuesPayload[]
  customerInfo?: CustomerInfo
  pinterestBoardLink?: string
  urlParameters?: IObjectDynamicPrototype
}

export enum EVENT_QUIZ {
  STYLE = 'STYLE',
  COLOR = 'COLOR',
  FLOWERS = 'FLOWERS',
  EVENT_DATE = 'EVENT_DATE',
  EVENT_CITY = 'EVENT_CITY',
  EVENT_STATE = 'EVENT_STATE',
  VENUE_STREET_ADDRESS = 'VENUE_STREET_ADDRESS',
  VENUE_NAME = 'VENUE_NAME',
  PRIMARY_CUSTOMER_ATTIRE = 'PRIMARY_CUSTOMER_ATTIRE',
  SECONDARY_CUSTOMER_ATTIRE = 'SECONDARY_CUSTOMER_ATTIRE',
  GUEST_COUNT = 'GUEST_COUNT',
  ADDITIONAL_BOUQUETS_NEEDED = 'ADDITIONAL_BOUQUETS_NEEDED',
  ADDITIONAL_BOUTS_NEEDED = 'ADDITIONAL_BOUTS_NEEDED',
  WEDDING_ATTIRE = 'WEDDING_ATTIRE',
  BUDGET = 'BUDGET',
  BUDGET_FOCUS = 'BUDGET_FOCUS',
  ITEM_TYPES = 'ITEM_TYPES',
  LOCATION_TYPE = 'LOCATION_TYPE',
  TABLE_ARRANGEMENTS = 'TABLE_ARRANGEMENTS',
  TABLE_TYPES = 'TABLE_TYPES',
  PERSONAL_ITEMS = 'PERSONAL_ITEMS',
  DAY_OF_POINT_OF_CONTACT_NAME = 'DAY_OF_POINT_OF_CONTACT_NAME',
  DAY_OF_POINT_OF_CONTACT_PHONE_NUMBER = 'DAY_OF_POINT_OF_CONTACT_PHONE_NUMBER',
  OTHER_INFORMATION = 'OTHER_INFORMATION',
  RITUALS = 'RITUALS',
  BOOKED_VENDORS = 'BOOKED_VENDORS',
  BOUQUET_SHAPE = 'BOUQUET_SHAPE',
  ITEMS = 'ITEMS',
  COVID_IMPACT = 'COVID_IMPACT',
  PLANNING_STAGE = 'PLANNING_STAGE',
  FLORAL_FOCUS = 'FLORAL_FOCUS',
  INTENT = 'INTENT',
  REFERRAL_SOURCE = 'REFERRAL_SOURCE',
}

const recordQuizResponseAPI = (quizData: IQuizQuestionPayload): Promise<API<IRecordQuizResponseAPI>> => {
  return postHttp(PATH_API.postRecordQuizResponse, quizData)
}

export const gaQuestionCompletedEvent = (events: string[]) => {
  events.forEach((event) => {
    gaEventApp(ACTION_APP.QUESTION_COMPLETED, {
      label: event,
    })
    ga4EventApp(ACTION_APP.QUESTION_COMPLETED, {
      label: event,
    })
    segmentTracking(SegmentAction.SQ_QUESTION_COMPLETED, {
      label: event,
    })
  })
}

const handleQuizDataRaw = (sessionId: string, quizData: IQuizData) => {
  const quizResponses: IQuizResponsesPayload[] = []
  let eventVenues: IEventVenuesPayload[] = []
  let pinterestBoardLink = ''
  let gaQuestionEvents: string[] = []
  let customerInfo
  let colorPaletteId
  let quizQuestion
  let colorPreference
  let inspirationPhotos
  let stemPreferences

  if (quizData.weddingDate) {
    if (quizData.weddingDate.weddingDate) {
      quizResponses.push({
        key: EVENT_QUIZ.EVENT_DATE,
        values: moment(quizData.weddingDate.weddingDate).format('YYYY-MM-DD'),
      })
    }
    const [firstName, lastName] = splitFullName(quizData.weddingDate.fullName.trim() || '')
    const emailAddress = quizData.weddingDate.emailAddress?.trim() ?? ''
    customerInfo = {
      firstName: firstName?.trim() ?? '',
      lastName: lastName?.trim() ?? '',
      partnerName: quizData.weddingDate.partnerName.trim() || '',
      emailAddress: emailAddress?.trim() ?? '',
      phoneNumber: quizData.weddingDate.phoneNumber?.trim() ?? '',
      step1: !quizData.weddingDate.weddingDate ? 'step1' : undefined,
    }
    gaQuestionEvents = [...gaQuestionEvents, QUESTION_LABELS_GA.EVENT_DATE]
    quizQuestion = 'CONTACT_INFO'
  }

  if (quizData.planningStage) {
    quizResponses.push(
      {
        key: EVENT_QUIZ.BOOKED_VENDORS,
        values: convertBooleanDynamicToArray(quizData.planningStage.vendors),
      },
      {
        key: EVENT_QUIZ.PLANNING_STAGE,
        values: quizData.planningStage.stage,
      },
      {
        key: EVENT_QUIZ.INTENT,
        values: quizData.planningStage.intent,
      }
    )
    gaQuestionEvents = [...gaQuestionEvents, QUESTION_LABELS_GA.BOOKED_VENDORS, QUESTION_LABELS_GA.PLANNING_STAGE]
  }

  if (quizData.guestCount !== undefined) {
    quizResponses.push({
      key: EVENT_QUIZ.GUEST_COUNT,
      values: [quizData.guestCount + ''],
      isUnknown: quizData.guestCount === 0,
    })
    gaQuestionEvents = [...gaQuestionEvents, QUESTION_LABELS_GA.GUEST_COUNT]
  }

  if (quizData.tableType) {
    quizResponses.push({
      key: EVENT_QUIZ.TABLE_TYPES,
      values: convertBooleanDynamicToArray(quizData.tableType),
    })
    gaQuestionEvents = [...gaQuestionEvents, QUESTION_LABELS_GA.TABLE_TYPES]
  }

  if (quizData.ritual) {
    quizResponses.push({
      key: EVENT_QUIZ.RITUALS,
      values: convertBooleanDynamicToArray(quizData.ritual.type || {}),
    })
    gaQuestionEvents = [...gaQuestionEvents, QUESTION_LABELS_GA.RITUALS]
  }

  if (quizData.flowerWedding) {
    if (quizData.flowerWedding.partner !== 'nothing') {
      quizResponses.push({
        key: EVENT_QUIZ.PERSONAL_ITEMS,
        values: quizData.flowerWedding.partner,
      })
      gaQuestionEvents = [...gaQuestionEvents, QUESTION_LABELS_GA.COUPLE_NEEDS]
    }

    quizResponses.push(
      {
        key: EVENT_QUIZ.ADDITIONAL_BOUQUETS_NEEDED,
        values: [quizData.flowerWedding.bouquets + ''],
      },
      {
        key: EVENT_QUIZ.ADDITIONAL_BOUTS_NEEDED,
        values: [quizData.flowerWedding.boutonnieres + ''],
      }
    )
    gaQuestionEvents = [...gaQuestionEvents, QUESTION_LABELS_GA.BOUQUET_COUNT, QUESTION_LABELS_GA.BOUTONNIERE_COUNT]
  }

  if (quizData.price) {
    quizResponses.push({
      key: EVENT_QUIZ.ITEMS,
      values: convertBooleanDynamicToArray(quizData.price),
    })
    gaQuestionEvents = [...gaQuestionEvents, QUESTION_LABELS_GA.ITEM_NEEDS]
  }

  if (quizData.budget) {
    quizResponses.push({
      key: EVENT_QUIZ.BUDGET,
      values: [quizData.budget + ''],
    })
    gaQuestionEvents = [...gaQuestionEvents, QUESTION_LABELS_GA.BUDGET]
    quizQuestion = 'BUDGET'
  }

  if (quizData.budgetFocus) {
    quizResponses.push({
      key: EVENT_QUIZ.BUDGET_FOCUS,
      values: [quizData.budgetFocus || ''],
    })
  }

  if (quizData.pinterest) {
    pinterestBoardLink = quizData.pinterest
    gaQuestionEvents = [...gaQuestionEvents, QUESTION_LABELS_GA.PINTEREST_BOARD]
  }

  if (quizData.otherInformation) {
    quizResponses.push({
      key: EVENT_QUIZ.OTHER_INFORMATION,
      values: quizData.otherInformation,
    })
    gaQuestionEvents = [...gaQuestionEvents, QUESTION_LABELS_GA.OTHER_NOTES]
  }

  if (quizData.colorPalette) {
    colorPaletteId = quizData.colorPalette.id
    gaQuestionEvents = [...gaQuestionEvents, QUESTION_LABELS_GA.COLOR]
    quizQuestion = 'COLOR_PALETTE'
  }

  if (quizData.colorPreference) {
    colorPreference = quizData.colorPreference
    gaQuestionEvents = [...gaQuestionEvents, QUESTION_LABELS_GA.CUSTOM_PALETTE]
    quizQuestion = 'CUSTOM_PALETTE'
  }

  if (quizData.inspirationPhotos) {
    inspirationPhotos = quizData.inspirationPhotos
    gaQuestionEvents = [...gaQuestionEvents, QUESTION_LABELS_GA.INSPIRATION_PHOTO]
    quizQuestion = 'INSPIRATION'
  }

  if (quizData.stemPreferences) {
    stemPreferences = quizData.stemPreferences
    gaQuestionEvents = [...gaQuestionEvents, QUESTION_LABELS_GA.SAMPLE_FLOWER]
    quizQuestion = 'REVIEW_FLOWER'
  }

  if (quizData.bouquetShape) {
    quizResponses.push({
      key: EVENT_QUIZ.BOUQUET_SHAPE,
      values: [quizData.bouquetShape],
    })
    gaQuestionEvents = [...gaQuestionEvents, QUESTION_LABELS_GA.BOUQUET_SHAPE]
  }

  if (quizData.location) {
    eventVenues = [...eventVenues, ...convertLocationQuizToEventVenues(quizData.location)]
    gaQuestionEvents = [...gaQuestionEvents, QUESTION_LABELS_GA.VENUE_LOCATION]
  }

  if (quizData.floralFocus) {
    quizResponses.push({
      key: EVENT_QUIZ.FLORAL_FOCUS,
      values: [quizData.floralFocus],
    })
    gaQuestionEvents = [...gaQuestionEvents, QUESTION_LABELS_GA.FLORAL_FOCUS]
  }

  if (quizData.referralSource) {
    quizResponses.push({
      key: EVENT_QUIZ.REFERRAL_SOURCE,
      values: quizData.referralSource,
    })
  }

  return {
    quizDataPayload: {
      sessionId,
      quizResponses,
      pinterestBoardLink,
      eventVenues,
      customerInfo,
      colorPaletteId,
      quizQuestion,
      experiment: quizData.experiment,
      quizExperience: quizData.quizExperience,
      colorPreference,
      inspirationPhotos,
      stemPreferences,
      skipServiceability: quizData.skipServiceability || false,
    },
    gaQuestionEvents,
  }
}

interface INewQuizDataRaw {
  quizResponses: { key: string; values: string[] | string }[]
  eventVenues: IEventVenuesPayload[]
  customerInfo: CustomerInfo
  colorPaletteId: number
  quizQuestion?: string
}
export const handleNewQuizDataRaw = (sessionId: string, data: INewQuizDataRaw) => {
  let gaQuestionEvents: string[] = []

  if (data.eventVenues) {
  }

  gaQuestionEvents = [
    ...gaQuestionEvents,
    QUESTION_LABELS_GA.EVENT_DATE,
    QUESTION_LABELS_GA.PLANNING_STAGE,
    QUESTION_LABELS_GA.GUEST_COUNT,
    QUESTION_LABELS_GA.BUDGET,
    QUESTION_LABELS_GA.COLOR,
    QUESTION_LABELS_GA.VENUE_LOCATION,
  ]

  return {
    quizDataPayload: {
      sessionId,
      quizResponses: data.quizResponses || [],
      eventVenues: data.eventVenues,
      customerInfo: data.customerInfo || null,
      colorPaletteId: data.colorPaletteId,
      experiment: [],
      quizExperience: 'LEGACY',
      quizQuestion: data.quizQuestion,
    },
    gaQuestionEvents,
  }
}

export const useQuizQuestionContextValue = (): QuizQuestionContextData => {
  const history = useHistory()
  const { quizExperience } = useAppContext()

  const [screen, setScreen] = useState<SCREEN_QUIZ_QUESTION>()
  const [redirectUrl, setRedirectUrl] = useState<string>()
  const [recordResponse, setRecordResponse] = useState<IRecordQuizResponseAPI>()
  const [data, setData] = useState<IQuizData>({ ...quizQuestionContextDefaultValue.data })
  const [weddingDateData, setWeddingDateData] = useState<IWeddingDate>(
    quizQuestionContextDefaultValue.data?.weddingDate ?? ({} as IWeddingDate)
  )
  const [referralSource, setReferralSource] = useState<IOption | null>(
    quizQuestionContextDefaultValue.data?.referralSource
      ? {
          value: quizQuestionContextDefaultValue.data?.referralSource,
          label:
            REFERRAL_SOURCES.find((ref) => ref.value === quizQuestionContextDefaultValue.data?.referralSource)?.label ||
            '',
        }
      : null
  )
  const [quizQuestionData, setQuizQuestionData] = useState<QuizQuestionData>()
  const { sessionID, storeURLParameters } = useAppContext()
  const [quizProgressPercent, setQuizProgressPercent] = useState(0)

  const handleRecordQuizResponse = async (data: IQuizData) => {
    const { quizDataPayload, gaQuestionEvents } = handleQuizDataRaw(sessionID, data)

    console.log('[handleRecordQuizResponse]', quizDataPayload)
    if (
      !quizDataPayload.quizResponses.length &&
      !quizDataPayload.eventVenues.length &&
      !quizDataPayload.pinterestBoardLink &&
      !quizDataPayload.customerInfo &&
      !quizDataPayload.colorPaletteId &&
      !quizDataPayload.colorPreference &&
      !quizDataPayload.inspirationPhotos &&
      !quizDataPayload.stemPreferences
    )
      return

    try {
      gaQuestionCompletedEvent(gaQuestionEvents)
      return await recordQuizResponseAPI({
        urlParameters: storeURLParameters ?? undefined,
        ...quizDataPayload,
      })
      /* eslint-disable  @typescript-eslint/no-explicit-any */
    } catch (error: any) {
      if (error.data && error.data.code === 'SESSION_DOES_NOT_EXIST') {
        history.push(PATH_URL.SWIPING)
      }

      console.log('[handleRecordQuizResponse]', error)
      return undefined
    }
  }

  const handleNewRecordQuizResponse = async (data: any) => {
    const { quizDataPayload, gaQuestionEvents } = handleNewQuizDataRaw(sessionID, data)
    try {
      gaQuestionCompletedEvent(gaQuestionEvents)
      return await recordQuizResponseAPI({
        urlParameters: storeURLParameters ?? undefined,
        ...quizDataPayload,
      })
      /* eslint-disable  @typescript-eslint/no-explicit-any */
    } catch (error: any) {
      if (error.data && error.data.code === 'SESSION_DOES_NOT_EXIST') {
        history.push(PATH_URL.SWIPING)
      }

      console.log('[handleNewRecordQuizResponse]', error)
      return undefined
    }
  }

  const setQuizScreen = useCallback((screenInput: SCREEN_QUIZ_QUESTION) => {
    window.scrollTo(0, 0)
    setScreen(screenInput)
  }, [])

  const setWeddingDateDataState = useCallback((dataInput: IWeddingDate) => {
    setWeddingDateData(dataInput)
  }, [])

  const setReferralSourceState = useCallback((dataInput: IOption | null) => {
    setReferralSource(dataInput)
  }, [])

  const setQuizData = async (data: IQuizData) => {
    setData((value) => ({ ...value, ...data }))
    const quizResponses = await handleRecordQuizResponse(data)
    if (data.location && quizResponses?.data?.locationDetail) {
      setData((value) => {
        const newValue = { ...value }
        if (newValue.location) {
          const index = newValue.location.findIndex((loc) => loc.type === EVENT_LOCATION_TYPE.CEREMONY)
          if (index > -1) {
            newValue.location[index].venue = quizResponses?.data?.locationDetail?.streetAddress || ''
            newValue.location[index].city = quizResponses?.data?.locationDetail?.city || ''
            newValue.location[index].placeId = quizResponses?.data?.locationDetail?.placeId || ''
            newValue.location[index].state = quizResponses?.data?.locationDetail?.state || ''
            newValue.location[index].streetAddress = quizResponses?.data?.locationDetail?.streetAddress || ''
            newValue.location[index].zipCode = quizResponses?.data?.locationDetail?.zipCode || ''
            newValue.location[index].place = bindLocationToPlace(newValue.location[index]) || ''
          }
        }
        return {
          ...newValue,
        }
      })
    }
    return quizResponses
  }

  const setNewQuizData = async (data: IQuizData) => {
    const quizResponses = await handleNewRecordQuizResponse(data)
    return quizResponses
  }

  useEffect(() => {
    quizExperience === 'CANNY_VALLEY'
      ? setScreen(SCREEN_QUIZ_QUESTION.COUPLE_INFO)
      : setScreen(quizQuestionContextDefaultValue.screen)
  }, [quizExperience])

  return {
    screen,
    data,
    setQuizData,
    setNewQuizData,
    setQuizScreen,
    quizQuestionData,
    setQuizQuestionData,
    setData,
    redirectUrl,
    setRedirectUrl,
    recordResponse,
    setRecordResponse,
    quizProgressPercent,
    setQuizProgressPercent,
    weddingDateData,
    setWeddingDateDataState,
    referralSource,
    setReferralSourceState,
  }
}
