import { useHistory } from 'react-router-dom'

import { PATH_URL } from 'src/helpers'
import { useAppContext } from 'src/helpers/app'
import { IStyleImages, STYLE_IMAGE_TYPE } from 'src/types'

import narrowImg from 'src/assets/images/narrow.png'
import wideImg from 'src/assets/images/wide.png'

const handleStyleImages = (styleImages: IStyleImages[]) => {
  const wideImages: string[] = []
  const narrowImages: string[] = []

  styleImages.forEach((img) => {
    if (img.imageUrl) {
      if (img.type === STYLE_IMAGE_TYPE.NARROW) {
        narrowImages.push(img.imageUrl)
      } else {
        wideImages.push(img.imageUrl)
      }
    }
  })

  narrowImages[0] = narrowImages[0] ?? narrowImg
  narrowImages[1] = narrowImages[1] ?? narrowImg
  wideImages[0] = wideImages[0] ?? wideImg

  return {
    narrow: narrowImages.slice(0, 2),
    wide: wideImages[0],
  }
}

export const useStyleInfo = () => {
  const history = useHistory()
  const { appData } = useAppContext()

  const handleClickProposal = () => {
    history.push(PATH_URL.QUIZ_QUESTION)
  }

  const styleImages = handleStyleImages(appData.styleInfo?.styleImages ?? [])

  return {
    handleClickProposal,
    styleImages,
    customer: appData.customer,
    styleInfo: appData.styleInfo,
  }
}
