import React from 'react'

import wantMoreImg from 'src/assets/images/want_more.png'

export const WantMore = () => {
  return (
    <div id="want_more" className="want_more-wrapper">
      <img className="mobile want_more-img" src={wantMoreImg} alt="want more" />
      <div className="want_more-content">
        <h3>Want More?</h3>
        <div className="">
          Reach out to <a href="mailto:weddings@poppyflowers.com">weddings@poppyflowers.com</a> to customize your
          flowers. Our custom wedding flowers start at $1,000 and go up depending on your level of customization, flower
          preferences, and location.
        </div>
      </div>
    </div>
  )
}
