import React from 'react'

import { IntroduceStyle } from 'src/pages/results/IntroduceStyle'

export const Congratulations = () => {
  return (
    <div className="congratulations-wrapper">
      <IntroduceStyle hideActions={true} />
    </div>
  )
}
