import React from 'react'
import { PropOnlyChildren } from 'src/types'

import { useAssetPreload, imagesPreload } from './useAssetPreload'

export const AssetPreload: React.FC<PropOnlyChildren> = (props: PropOnlyChildren) => {
  const { isShowContent, handleImageLoaded } = useAssetPreload()

  return (
    <>
      {imagesPreload.map((item, index) => (
        <img key={index} className="d-none" src={item} alt="image-preload" onLoad={handleImageLoaded} />
      ))}
      {isShowContent && props.children}
    </>
  )
}
