import styled from 'styled-components'

export const H1 = styled.h1`
  font-size: 64px;
  font-weight: 900;
`

export const H2 = styled.h2`
  font-weight: 900;
`

export const H3 = styled.h3`
  font-size: 40px;
`

export const H4 = styled.h4`
  font-size: 24px;
  font-weight: 900;
`

export const H5 = styled.h5`
  font-size: 16px;
  font-weight: 700;
`

export const H6 = styled.h6`
  font-size: 16px;
  font-weight: 700;
`

export const P = styled.p`
  font-size: 18px;
  font-weight: 400;
`

export const Sub = styled.sub`
  font-size: 36px;
  font-weight: 700;
`

export const Sup = styled.sup`
  font-size: 64px;
  font-weight: 700;
`
