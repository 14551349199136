import axios from 'axios'
import { encodeForm } from 'src/helpers/convertHelper'

export interface IExperienceZapierPayload {
  star_rating: number
  message?: string
  templateId?: number
}

export interface MicroSurveyZapierPayload {
  email?: string
  answer?: string
  colorPalette?: string
}

export const postExperienceZapier = async (payload: IExperienceZapierPayload) => {
  return await axios.post(`https://hooks.zapier.com/hooks/catch/6513596/busuki1/`, encodeForm(payload), {
    headers: { Accept: 'application/json' },
  })
}

export const postMicroSurveyZapier = async (payload: MicroSurveyZapierPayload) => {
  return await axios.post(`https://hooks.zapier.com/hooks/catch/6513596/bag89h8/`, payload, {
    headers: { Accept: 'application/json' },
  })
}
