import React, { PropsWithChildren } from 'react'

import { CheckboxButton, H5 } from 'src/common/components'

import { QuizQuestionWrapper } from './QuizQuestionWrapper'
import { useSeeItemPrices } from './SeeItemPrices.hook'

export type IEachItemBaseProps = PropsWithChildren<{
  title: string
  className?: string
}>

const EachItemBase: React.FC<IEachItemBaseProps> = (props: IEachItemBaseProps) => {
  const { title, children, className } = props
  return (
    <div className={className}>
      <H5 className="mt-0px mb-5px text-transform-upper item-title">{title}</H5>
      <div className="item-price">{children}</div>
    </div>
  )
}

export const SeeItemPrices = () => {
  const { handleNext, handleChange, handleBack, itemPriceList, itemPricesSelected, nextDisabled } = useSeeItemPrices()

  return (
    <>
      <QuizQuestionWrapper
        title="items"
        subtitle="(Select all that apply)"
        nextAction={{
          onAction: handleNext,
          disabled: nextDisabled,
        }}
        backAction={{
          onAction: handleBack,
        }}
        step={5}
        total={8}
      >
        <div className="quiz-guest-item_price">
          <p className="quiz-question-header title-seel-price mw-50-rem">What items are you considering?</p>
          {itemPriceList.map((itemPrice) => {
            return (
              <EachItemBase key={itemPrice.name} title={itemPrice.title} className={itemPrice.className}>
                {itemPrice.data.map((item) => {
                  return (
                    <CheckboxButton
                      key={`${itemPrice.name}-${item.value}`}
                      onChange={handleChange}
                      className="cb-btn-basic pl-0px"
                      name={itemPrice.name}
                      value={item.value}
                      checked={!!itemPricesSelected[item.value]}
                      id={`${itemPrice.name}-${item.value}`}
                    >
                      {item.label}
                    </CheckboxButton>
                  )
                })}
              </EachItemBase>
            )
          })}

          <div className="mt-20px m-center">
            <button disabled={nextDisabled} className="button button-primary mt-14px" onClick={handleNext}>
              CONTINUE TO COLORS
            </button>
          </div>
        </div>
      </QuizQuestionWrapper>
    </>
  )
}
