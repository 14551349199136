export interface ILocationQuiz {
  type: EVENT_LOCATION_TYPE
  place: string
  venue: string
  city: string
  state: string
  streetAddress: string
  zipCode: string
  placeId?: string
  country?: string
}

export enum EVENT_LOCATION_TYPE {
  RECEPTION = 'RECEPTION',
  CEREMONY = 'CEREMONY',
}

export interface IEventVenuesPayload {
  type: EVENT_LOCATION_TYPE
  venueName?: string
  city: string
  state: string
  streetAddress: string
  zipCode: string
}
