import { useContext, useMemo, useState } from 'react'
import { SegmentAction, segmentTracking } from 'src/helpers/segment'

import { IOption } from 'src/types'

import { QuizQuestionContext, SCREEN_QUIZ_QUESTION } from '../../contextAPI'

export const FloralFocusOption: IOption[] = [
  {
    label: 'Bridal Bouquet',
    value: 'bridal_bouquet',
  },
  {
    label: 'Ceremony',
    value: 'ceremony',
  },
  {
    label: 'Reception',
    value: 'reception',
  },
]

export const useFloralFocus = () => {
  const { data, setQuizData, setQuizScreen } = useContext(QuizQuestionContext)
  const [floralFocusSelected, setFloralFocusSelected] = useState<string>(data.floralFocus ?? '')

  const handleNext = () => {
    setQuizData({ floralFocus: floralFocusSelected })
    segmentTracking(SegmentAction.SQ_FLORAL_FOCUS)
    setQuizScreen(SCREEN_QUIZ_QUESTION.YOU_BOOKED)
  }

  const handleBack = () => {
    setQuizScreen(SCREEN_QUIZ_QUESTION.WEDDING_DATE)
  }

  const handleFloralFocus = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target

    setFloralFocusSelected(value)
  }

  const nextDisabled = useMemo(() => floralFocusSelected === '', [floralFocusSelected])

  return {
    nextDisabled,
    floralFocusSelected,
    handleFloralFocus,
    handleBack,
    handleNext,
  }
}
