import React from 'react'

// import logo from 'src/assets/images/logo.png'

import './LoaderFullScreenWithTestimonial.scss'
import star from 'src/assets/images/star.svg'
import testimonial01 from 'src/assets/images/testimonials/Adling_Wedding-testimonial.jpg'
import testimonial02 from 'src/assets/images/testimonials/Nick-Peri-Pattie-testimonial.jpg'
import testimonial03 from 'src/assets/images/testimonials/Laura-Matt-testimonial.jpg'
import testimonial04 from 'src/assets/images/testimonials/Lillian-Leon-testimonial.jpg'
import Slider from 'react-slick'
// import rose from 'src/assets/images/rose-review.jpeg'
// import raquelPaulWedding from 'src/assets/images/raquel_paul_wedding.jpg'

// export const LoaderFullScreenWithTestimonial = () => {
//   const [testimonial, setTestimonial] = useState<number>(1)

//   useEffect(() => {
//     const timer = setTimeout(() => {
//       switch (testimonial) {
//         case 1:
//           setTestimonial(2)
//           break
//         default:
//           setTestimonial(1)
//           break
//       }
//     }, 4000)
//     return () => clearTimeout(timer)
//   }, [testimonial])

//   return (
//     <>
//       <div className="loader_full_screen customer-testimonial-wrapper ">
//         {/* <img src={logo} className="icon-logo" /> */}
//         <div className="loader"></div>
//         <div className="testimonial-content">
//           <div className="testimonial-item">
//             <div className="item-inner">
//               <div className="title">
//                 What Poppy <br />
//                 couples are saying...
//               </div>
//               {testimonial === 1 && (
//                 <>
//                   <img
//                     src={rose}
//                     loading="lazy"
//                     sizes="(max-width: 479px) 100vw, (max-width: 767px) 198.859375px, 175px"
//                     alt=""
//                     className="couple-img"
//                   />
//                   <div className="couple-say-content">
//                     <p className="eyebrow-text"> September 2021</p>
//                     <h1>Rose + Dave</h1>
//                     <div className="stars">
//                       <img src={star} loading="lazy" alt="" />
//                       <img src={star} loading="lazy" alt="" />
//                       <img src={star} loading="lazy" alt="" />
//                       <img src={star} loading="lazy" alt="" />
//                       <img src={star} loading="lazy" alt="" />
//                     </div>
//                     <p className="couple-say">
//                       From planning to my wedding day, the Poppy team made me feel so special, helped me stay under
//                       budget, and handled every little detail so I didn't have to worry.
//                       <br />– Rose
//                     </p>
//                   </div>
//                 </>
//               )}
//               {testimonial === 2 && (
//                 <>
//                   <img
//                     src={raquelPaulWedding}
//                     loading="lazy"
//                     sizes="(max-width: 479px) 100vw, (max-width: 767px) 198.859375px, 175px"
//                     alt=""
//                     className="couple-img"
//                   />
//                   <div className="couple-say-content">
//                     <p className="eyebrow-text">November 2021</p>
//                     <h1>Raquel + Paul</h1>
//                     <div className="stars">
//                       <img src={star} loading="lazy" alt="" />
//                       <img src={star} loading="lazy" alt="" />
//                       <img src={star} loading="lazy" alt="" />
//                       <img src={star} loading="lazy" alt="" />
//                       <img src={star} loading="lazy" alt="" />
//                     </div>
//                     <p className="couple-say">
//                       We loved you guys. Literal tears when the flowers showed up. Y’all were more than amazing.
//                       <br />– Raquel
//                     </p>
//                   </div>
//                 </>
//               )}
//             </div>
//           </div>
//         </div>
//       </div>
//     </>
//   )
// }

interface Testimonials {
  coupleName: string
  imageUrl: string
  stars: number
  comment: string
}

export const LoaderFullScreenWithTestimonial = () => {
  const settings = {
    dots: true,
    infinite: true,
    arrows: false,
    // autoplay: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  }
  const testimonials: Testimonials[] = [
    {
      coupleName: 'Jayme & Bailey',
      imageUrl: testimonial01,
      stars: 5,
      comment:
        'My florist did an absolute amazing job! Our flowers were the exact colors I had requested! Highly recommend!!!',
    },
    {
      coupleName: 'Peri & Nicholas',
      imageUrl: testimonial02,
      stars: 5,
      comment:
        'The flowers that Poppy curated for my wedding truly STOLE THE SHOW! They were stunning and full of life and color. My wedding was beautiful because of the gorgeous flowers. Thank you so much Poppy!',
    },
    {
      coupleName: 'Laura & Matthew',
      imageUrl: testimonial03,
      stars: 5,
      comment:
        'I miss my wedding flowers!  Where can I start with Poppy? If you’re considering using Poppy for your wedding flowers, do it! Poppy ended up being one of the best decisions of our wedding. Before booking Poppy, I reached out to many brides on IG and they all had wonderful things to say so I decided to give them a shot. Poppy’s platform is easy to use and fully customizable, the designers are readily available to answer any questions along the way. However, it is still nerve wracking because we are unable to see the final design before your wedding day, but rest assured, it will be beautiful! I submitted photos of my vision, along with flowers and colors I wanted to avoid and they nailed it. I was nervous because I was wanting a more unique color profile and flower choices, and they created my vision better than I could have imagined. The flowers were fresh and lasted throughout the day and were usable for our morning after brunch. They delivered and set up the flowers on time without an issue, and even dropped off my bouquet at a different location free of charge. In San Francisco, bare minimum wedding flowers were quoted at $12,000+. With Poppy, I ordered as many flower arrangements as I could possibly want, with set up and delivery fees coming out to $6,000. Poppy will save your wedding budget! Thank you so much!',
    },
    {
      coupleName: 'Lillian & Leon',
      imageUrl: testimonial04,
      stars: 5,
      comment: `Poppy exceeded my expectations for the floral arrangements I requested for my wedding day! The staircase flowers, flower clouds for the altar, and bridal/bridesmaids bouquets were ikebana-style just as I requested and were even more beautiful than I imagined they would be. The corsages and boutonnieres looked lovely on everyone, and our bud vases were perfect for the minimal table settings we intended to have. My brother said these were his favorite flowers he's ever seen at any wedding (and we have been to quite a few weddings), which is such high praise. I would totally recommend Poppy to anyone looking for beautiful, convenient, and affordable floral options. Customer service was excellent throughout, and the day-of execution couldn't have gone smoother. Thank you, thank you!!`,
    },
  ]

  return (
    <div className="loader_full_screen customer-testimonial-wrapper">
      <div className="customer-testimonial-content">
        <div className="waiting-text">
          Just a few seconds for some finishing touches
          <span className="loading">
            <span>.</span>
            <span>.</span>
            <span>.</span>
          </span>
        </div>
        <div className="title mt-16px">CUSTOMER TESTIMONIALS</div>
        <Slider {...settings}>
          {testimonials.map((testimonial, index) => (
            <div className="testimonial-item" key={index}>
              <div className="testimonial-image" style={{ backgroundImage: `url('${testimonial.imageUrl}')` }}>
                {/* <img src={testimonial.imageUrl} /> */}
              </div>
              <div className="stars mt-24px">
                {Array(testimonial.stars)
                  .fill(0)
                  .map((n, i) => n + i)
                  .map((value) => (
                    <img src={star} loading="lazy" alt="" key={value} />
                  ))}
              </div>
              <div className="couple-feedback mt-16px">“{testimonial.comment}”</div>
              <div className="couple mt-7px">{testimonial.coupleName}</div>
            </div>
          ))}
        </Slider>
      </div>
    </div>
  )
}
