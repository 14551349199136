import React, { useCallback, useEffect, useState } from 'react'

import { postHttp } from 'src/api'
import { PATH_API } from 'src/helpers'
import { useAppContext } from 'src/helpers/app'
import './styles.scss'

export const RITUALS = [
  {
    id: 'no-specific',
    label: 'No specific tradition',
    value: '',
  },
  {
    id: 'hindu',
    label: 'Hindu',
    value: 'HINDU',
  },
  {
    id: 'muslim',
    label: 'Muslim',
    value: 'MUSLIM',
  },
  {
    id: 'christian',
    label: 'Christian',
    value: 'CHRISTIAN',
  },
  {
    id: 'jewish',
    label: 'Jewish',
    value: 'JEWISH',
  },
  {
    id: 'other',
    label: 'Other',
    value: 'OTHER',
  },
]

export const Rituals: React.FC = () => {
  const [rituals, setRituals] = useState<string>()
  const { sessionID, quizSession } = useAppContext()

  const handleRecordRituals = useCallback(
    async (value: string) => {
      setRituals(value)
      if (!sessionID) return
      await postHttp(PATH_API.postRecordQuizResponse, {
        sessionId: sessionID,
        quizResponses: [
          {
            key: 'RITUALS',
            values: [value],
          },
        ],
        eventVenues: [],
      })
    },
    [sessionID]
  )

  useEffect(() => {
    const need = quizSession?.eventNeeds.find((en) => en.needKey === 'RITUALS')
    if (need) setRituals(need.needValues[0] || '')
  }, [quizSession?.eventNeeds])

  return (
    <div className="rituals-section mt-30px">
      <p className="normal-text">Will you be observing any specific cultural traditions for your wedding day?</p>
      <div className="rituals-choose">
        {RITUALS.map((item) => (
          <div
            key={item.id}
            className={`item ${item.value === rituals ? 'selected' : ''}`}
            onClick={() => handleRecordRituals(item.value)}
          >
            {item.label}
          </div>
        ))}
      </div>
    </div>
  )
}
