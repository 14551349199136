import React from 'react'
import { CannyValleyLayout } from 'src/common/components/Layout'

import { WelcomeHeader } from './components/WelcomeHeader'
import { ColorStory } from './components/ColorStory'
import { FlowerPreference } from './components/FlowerPreference'
import { ConnectUs } from './components/ConnectUs'
import { useMoodboard } from './useMoodboard'
import './styles.scss'

export const MoodBoard: React.FC = () => {
  useMoodboard()

  return (
    <CannyValleyLayout className="moodboard-page">
      <WelcomeHeader />
      <ColorStory />
      <FlowerPreference />
      <ConnectUs />
    </CannyValleyLayout>
  )
}
