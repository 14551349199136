import React from 'react'
import { useResultsPage } from './ResultsPage.hook'

import './ResultsPage.scss'
// import { GalleryDesktop, GalleryMobile } from 'src/common/components/gallery'
// import { AsSeenIn } from './AsSeenIn'
import { GorgiasChat } from 'src/common/components/embed'
// import { IntroduceStyle } from './IntroduceStyle'

// import logoWhiteImg from 'src/assets/images/logoWhite.png'
// import leftSectionImg from 'src/assets/images/left_section_image.png'
import { Header } from './Header'
import { Footer } from './Footer'
// import { TopShoppingWedding } from './TopShoppingWedding'
// import { Expect } from './Expect'
import FAQs from './FAQs'
// import { WeddingPlanning } from './components/WeddingPlanning'
import { Introduce } from './components/Introduce'
import { FlowerGallery } from './components/FlowerGallery'
import { FlexiblePricing } from './components/FlexiblePricing'
import { MicroSurvey } from './components/MicroSurvey'
import { ProposalHeader } from './components/ProposalHeader'
// import { ExperiencePopup } from 'src/pages/shared'
// import { useAppContext } from 'src/helpers/app'

export const ResultsPage: React.FC = () => {
  const { infoProposal } = useResultsPage()
  // const { appData } = useAppContext()

  // const ctaConfigs = customerFullInfo?.ctaConfigs || ['START_CONVERSATION']
  // const isConversationPrimary = ctaConfigs[0] === 'START_CONVERSATION'

  // const scheduleButtonCss = isConversationPrimary ? 'button button-basic' : 'button button-secondary'

  if (!infoProposal) {
    return <></>
  }

  // const { templateConfig, urgencyText } = infoProposal

  return (
    <div className="results-page">
      {/* <TopShoppingWedding /> */}
      <Header />
      <ProposalHeader />
      {/* <WeddingPlanning /> */}
      <Introduce />
      <FlowerGallery />
      <FlexiblePricing />
      {/* <IntroduceStyle
        urgencyHeader={(paramsUrgencyProps) => (
          <div className="container-33 w-container">
            <div className="div-block-191">
              <img src={logoWhiteImg} loading="lazy" alt="" className="image-107" />
              <div id="urgencyText" className="text-block-99">
                {urgencyText}
              </div>
              <BookNowButton
                className="link-9"
                urgencyHeader={paramsUrgencyProps}
                booknowUrl={customerFullInfo?.booknow.enable ? customerFullInfo.booknow.url : ''}
              />
            </div>
          </div>
        )}
      /> */}
      {/* <Expect /> */}
      {/* <div className="section-37">
        <div className="section-wrapper container mt-0px-auto">
          <div className="left-section">
            <img className="img-left" src={leftSectionImg} alt="left image" />
          </div>
          <div className="right-section">
            <h2 className="heading-78">Bring your flowers to life with your Proposal Designer</h2>
            <div className="section-content-1">
              <p className="text-para">
                1. You partner with the Proposal Designer to curate your floral style and budget.
              </p>
              <p className="text-para">
                2. Your Poppy Point of Contact works behind the scenes to get everything ready for your big day.
              </p>
              <p className="text-para">
                3. Your local Day-Of Florist assembles your arrangements and installs everything on the day of your
                wedding.
              </p>

              {ctaConfigs.includes('SCHEDULE_CALL') ? (
                <a
                  onClick={handleScheduleACall}
                  className={`${scheduleButtonCss} left-align hidden-on-mobile w-button ml-0px`}
                >
                  schedule a call
                </a>
              ) : (
                <div className="only-space"></div>
              )}
            </div>
          </div>
        </div>
      </div> */}

      {/* <div id="rating-popup-trigger" /> */}

      {/* <div className="section-39">
        <div className="sneak-peek-desktop w-container">
          <GalleryDesktop imageFirework={templateConfig}></GalleryDesktop>
          <div className="div-block-152 right-aligned">
            <h1>Sneak Peek of your Custom Proposal</h1>
            <div>
              A Poppy Specialist is ready to design beautiful, custom wedding florals for you. Whether you already have
              a mission or don't know where to begin, you'll end up with flawless florals on a budget that works for
              you.
              <br />
            </div>
            <button
              onClick={isConversationPrimary ? handleGetInTouch : handleScheduleACall}
              type="button"
              className="button button-basic button-schedule-a-call left-align w-button"
            >
              {isConversationPrimary ? 'Start a conversation' : 'schedule a call'}
            </button>
          </div>
        </div>
      </div>

      <div className="section-38">
        <div className="container-29 w-container">
          <h2 className="nunito-heading">Sneak&nbsp;Peek</h2>
          <div id="styleText" className="text-block-93">
            of your custom proposal.
            <br />
          </div>
          <div id="mobilePriceRangeText" className="text-block-91">
            Based on your selections, you can expect to spend between $1,050 and $4,505.
          </div>
          <button
            onClick={isConversationPrimary ? handleGetInTouch : handleScheduleACall}
            type="button"
            className="button button-basic button-schedule-a-call left-align w-button"
          >
            Learn more
          </button>
          <GalleryMobile imageFirework={templateConfig}></GalleryMobile>
          <button
            onClick={isConversationPrimary ? handleGetInTouch : handleScheduleACall}
            type="button"
            className="button button-basic button-schedule-a-call left-align w-button"
          >
            {isConversationPrimary ? 'Start a conversation' : 'schedule a call'}
          </button>
        </div>
      </div> */}
      <FAQs />
      {/* <AsSeenIn /> */}
      <Footer />
      <GorgiasChat />
      <MicroSurvey />
      {/* <ExperiencePopup triggerElmId="rating-popup-trigger" templateId={appData.customerFullInfo?.templateId} /> */}
    </div>
  )
}
