import React, { useState } from 'react'
import './FlexiblePricing.scss'

import lush1 from 'src/assets/images/Stephanie-Zach-1.png'
import lush2 from 'src/assets/images/lush2.png'
import lush3 from 'src/assets/images/lush3.png'
import lush4 from 'src/assets/images/lush4.png'
import simple1 from 'src/assets/images/simple1.png'
import simple2 from 'src/assets/images/simple2.png'
import simple3 from 'src/assets/images/simple3.png'
import simple4 from 'src/assets/images/simple4.png'
import premium1 from 'src/assets/images/premium1.png'
import premium2 from 'src/assets/images/premium2.png'
import premium3 from 'src/assets/images/premium3.png'
import premium4 from 'src/assets/images/premium4.png'
import { ImageGallery } from 'src/common/components/ImageGallery'
// import { BookNowButton } from '../../IntroduceStyle'
import { useNormalResult } from '../../ResultsPage.hook'
import Slider from 'react-slick'
import { SegmentAction, segmentTracking } from 'src/helpers/segment'

type PackageType = 'PACKAGE_SIMPLE' | 'PACKAGE_LUSH' | 'PACKAGE_PREMIUM'

interface IPackage {
  key: PackageType
  title: string
  subtitle: string
  price: string
  description: string
  packages: string[]
  packagesTitle: string
  images: string[]
}

const PACKAGES: IPackage[] = [
  {
    key: 'PACKAGE_SIMPLE',
    title: 'Simple',
    subtitle: 'For couples looking for flowers to accent their wedding',
    price: '$1,000',
    description: 'Simple is elegant. Poppy can deliver on your floral vision without the sky-high minimums.',
    packages: ['Bridal Bouquet', "Groom's Boutonniere", "Bridesmaid's Bouquets"],
    packagesTitle: 'SAMPLE PACKAGE',
    images: [simple1, simple2, simple3, simple4],
  },
  {
    key: 'PACKAGE_LUSH',
    title: 'Lush',
    subtitle: 'Our most popular package',
    price: '$2,500',
    description:
      'Find on-trend flowers for your whole wedding, from bridal bouquets to the perfect floral arch. Customize your Poppy proposal to match your style and color palette and one of our vetted floral designers will bring the designs to life.',
    packages: ['Bridal Bouquet', "Groom's Boutonniere", "Bridesmaid's Bouquets", 'Aisle Decor', 'Reception Tables'],
    packagesTitle: 'SAMPLE PACKAGE',
    images: [lush1, lush2, lush3, lush4],
  },
  {
    key: 'PACKAGE_PREMIUM',
    title: 'Premium',
    subtitle: 'Bouquets for Days',
    price: '$3,500',
    description:
      'Work with our team to design your custom wedding floral experience. You bring your big ideas and we bring our farm fresh stems and deep floral experience to make your dream a reality',
    packages: [
      'Bridal Bouquet',
      "Groom's Boutonniere",
      "Bridesmaid's Bouquets",
      'Altar Decor',
      'Reception Tables',
      'Flower Arch',
    ],
    packagesTitle: 'Custom Order Including',
    images: [premium1, premium2, premium3, premium4],
  },
]

export const FlexiblePricing: React.FC = () => {
  const { customerFullInfo, handleScheduleACall, handleGetInTouch, isCustomer1000 } = useNormalResult()
  const packageImages = customerFullInfo?.colorPalette?.packageImages
  if (packageImages) {
    PACKAGES.forEach((p: IPackage) => {
      const pImage = packageImages[p.key]
      if (pImage) {
        p.images = [pImage.mainImageUrl, ...pImage.additionalImageUrls]
      }
    })
  }

  const [tabContent, setTabContent] = useState<IPackage>(PACKAGES[isCustomer1000 ? 0 : 1])

  const ctaConfigs = customerFullInfo?.ctaConfigs || ['START_CONVERSATION']
  const isAvailableScheduleACall = ctaConfigs.includes('SCHEDULE_CALL')

  const handleSecondaryBtn = () => {
    if (isAvailableScheduleACall) {
      handleScheduleACall({ ctaLocation: tabContent.key })
    } else {
      handleGetInTouch({ ctaLocation: tabContent.key })
    }
  }
  const handleSeeProposal = () => {
    if (!customerFullInfo?.proposalUrl) return
    segmentTracking(SegmentAction.SQ_SEE_PROPOSAL_CLICKED)
    window.location.href = customerFullInfo?.proposalUrl
  }

  return (
    <div id="packages" className="flexible-pricing mt-30px">
      <div className="container mt-0px-auto pricing-header">
        <div className="header-content">
          <h3 className="title">Pricing & Packages</h3>
          <p className="subtitle mt-20px">
            Our flexible proposals are completely customizable to fit your needs. We offer three tiers of floral
            support, to accomodate whatever you need.
          </p>
        </div>
      </div>
      <div className="pricing-content">
        <div className="container mt-0px-auto tab-header">
          <div className="header-content">
            {PACKAGES.map((item) => (
              <>
                <div
                  key={item.key}
                  className={`tab-header-item ${item.key === tabContent.key ? 'active' : ''}`}
                  onClick={() => setTabContent(item)}
                >
                  <h3 className="title">{item.title}</h3>
                  <p className="subtitle">{item.subtitle}</p>
                </div>
              </>
            ))}
          </div>
          <p className="subtitle mobile">{tabContent.subtitle}</p>
        </div>
        <div className="tab-content">
          <div className="package-content">
            <div className="container mt-0px-auto">
              <div className="desktop">
                <div className="left-content">
                  <div className="tab-content-header">
                    <h3 className="title">{tabContent.title}</h3>
                    <label className="starting-at">Starting at</label>
                    <h3 className="price">{tabContent.price}</h3>
                  </div>
                  <div className="tab-content-description">
                    <div className="description-wrapper">
                      <p className="description">{tabContent.description}</p>
                      <p className="final-price mt-32px">
                        *Final prices will be determined based off of the products and quantities you choose for your
                        wedding.
                      </p>
                    </div>
                    <div className="packages-wrapper">
                      <label className="sample-package">{tabContent.packagesTitle}</label>
                      <ul className="packages">
                        {tabContent.packages.map((i) => (
                          <li key={i}>{i}</li>
                        ))}
                      </ul>
                    </div>
                  </div>
                  <div className="tab-content-action mt-32px">
                    {isCustomer1000 ? (
                      <button onClick={handleSeeProposal} className={`button button-basic w-button`}>
                        Start Customizing
                      </button>
                    ) : customerFullInfo?.primaryCTAType === 'SEE_PROPOSAL' ? (
                      <>
                        <button onClick={handleSeeProposal} className={`button button-basic w-button`}>
                          Start Customizing
                        </button>
                        <button onClick={handleSecondaryBtn} className={`button button-secondary w-button`}>
                          {isAvailableScheduleACall ? 'schedule a call' : 'start a conversation'}
                        </button>
                      </>
                    ) : (
                      <button onClick={handleSecondaryBtn} className={`button button-basic w-button`}>
                        {isAvailableScheduleACall ? 'schedule a call' : 'start a conversation'}
                      </button>
                    )}
                  </div>
                </div>
                <div className="right-content">
                  <ImageGallery images={tabContent.images} showImgNumber={4} disablePreview hideOverlay />
                </div>
              </div>
              <div className="mobile">
                <div>
                  <div className="package-description">
                    <div>
                      <h3 className="title">{tabContent.title}</h3>
                      <label className="starting-at">Starting at</label>
                    </div>
                    <div className="right-content">
                      <h3 className="price">{tabContent.price}</h3>
                    </div>
                  </div>
                </div>
                <div className="mt-5px">
                  <Slider centerMode={false} slidesToShow={1} infinite arrows={false} swipe dots speed={500}>
                    {tabContent.images.map((image) => (
                      <div key={image} className="flower-card">
                        <img className="flower" src={image} />
                      </div>
                    ))}
                  </Slider>
                </div>
                <p className="description mt-45px">{tabContent.description}</p>
                <div className="mt-16px">
                  <label className="sample-package">SAMPLE PACKAGE</label>
                  <ul className="packages">
                    {tabContent.packages.map((i) => (
                      <li key={i}>{i}</li>
                    ))}
                  </ul>
                </div>
                <div className="mt-32px">
                  {/* <BookNowButton className={`button button-basic w-button`} />
                <button onClick={handleSecondaryBtn} className={`button button-secondary w-button mt-16px`}>
                  {isAvailableScheduleACall ? 'schedule a call' : 'start a conversation'}
                </button> */}
                  {isCustomer1000 ? (
                    <button onClick={handleSeeProposal} className={`button button-basic w-button`}>
                      Start Customizing
                    </button>
                  ) : customerFullInfo?.primaryCTAType === 'SEE_PROPOSAL' ? (
                    <>
                      <button onClick={handleSeeProposal} className={`button button-basic w-button`}>
                        Start Customizing
                      </button>
                      <button onClick={handleSecondaryBtn} className={`button button-secondary w-button`}>
                        {isAvailableScheduleACall ? 'schedule a call' : 'start a conversation'}
                      </button>
                    </>
                  ) : (
                    <button onClick={handleSecondaryBtn} className={`button button-basic w-button`}>
                      {isAvailableScheduleACall ? 'schedule a call' : 'start a conversation'}
                    </button>
                  )}
                </div>
                <p className="final-price mt-13px">
                  *Final prices will be determined based off of the products and quantities you choose for your wedding.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
