declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    VWO: any[]
  }
}

export const vwoEvent = () => {
  window.VWO = window.VWO || []
  window.VWO[0].event =
    window.VWO[0].event ||
    function () {
      // eslint-disable-next-line prefer-rest-params
      window.VWO.push(['event'].concat([].slice.call(arguments)))
    }

  window.VWO[0].event('quizSubmitted')
}
