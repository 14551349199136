import React from 'react'

import { CheckboxButton, QuantitySelectorBase, Radio } from 'src/common/components'

import { useFlowersWedding } from './FlowersWedding.hook'

import { QuizQuestionWrapper } from './QuizQuestionWrapper'

export const FlowersWedding = () => {
  const {
    nextDisabled,
    bouquets,
    partner,
    ritualsSelected,
    boutonnieres,
    partnerList,
    ritualList,
    handleNext,
    handleBack,
    handleChangeBouquets,
    handleChangeBoutonnieres,
    handleChangePartner,
    handleSelect,
  } = useFlowersWedding()

  return (
    <>
      <QuizQuestionWrapper
        title="flowers"
        nextAction={{
          disabled: nextDisabled,
          onAction: handleNext,
        }}
        backAction={{
          onAction: handleBack,
        }}
        step={4}
        total={4}
      >
        <div className="quiz-guest-flowers_wedding">
          <p className="quiz-question-header">Is there a specific tradition your flowers should reflect?</p>
          <div className="flowers_wedding-partner">
            {ritualList &&
              ritualList.map((item) => (
                <CheckboxButton
                  key={item.value}
                  value={item.value}
                  className="cb-btn-basic mb-10px ml-0px pl-0px mr-10px"
                  id={item.value}
                  checked={ritualsSelected[item.value] ?? false}
                  onChange={handleSelect}
                >
                  {item.label}
                </CheckboxButton>
              ))}
          </div>
          <p className="quiz-question-header title-partner mw-50-rem">What flowers do you and your partner need?</p>
          <div className="flowers_wedding-partner">
            {partnerList.map((item) => {
              return (
                <Radio
                  key={item.value}
                  id={item.value}
                  value={item.value}
                  name="partner"
                  className="cb-btn-poppy mr-20px pr-20px"
                  classNames={{ label: 'basic' }}
                  onChange={handleChangePartner}
                  checked={item.value === partner}
                >
                  {item.label}
                </Radio>
              )
            })}
          </div>
          <p className="quiz-question-header title-partner mw-50-rem">
            How many bouquets or boutonnieres will you need for your wedding party?
          </p>
          <div className="quiz-guest-partner mt-0px">
            <div className="partner-item">
              <span className="partner-item-label">bouquets</span>
              <QuantitySelectorBase onChange={handleChangeBouquets} value={bouquets} />
            </div>
            <div className="partner-item">
              <span className="partner-item-label">boutonnières</span>
              <QuantitySelectorBase onChange={handleChangeBoutonnieres} value={boutonnieres} />
            </div>
          </div>
          <div className="mt-20px m-center">
            <button
              disabled={nextDisabled}
              className="button button-primary mt-14px white-space-wrap"
              onClick={handleNext}
            >
              CONTINUE TO ITEMS
            </button>
          </div>
        </div>
      </QuizQuestionWrapper>
    </>
  )
}
