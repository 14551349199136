import React, { useState } from 'react'

import textLogo from 'src/assets/images/text_logo.png'
import { REDIRECT_PATH } from 'src/helpers'
import { Button } from 'src/common/components'

import './style.scss'

export const Header: React.FC = () => {
  const [open, setOpen] = useState<boolean>(false)

  return (
    <header className="poppy-header-wrapper">
      <div className="poppy-header">
        <a className="poppy-logo-wrapper" href="/">
          <div className="poppy-logo">
            <img className="text-logo" src={textLogo} alt="text logo" />
          </div>
        </a>
        <div className="main-menu-wrapper desktop">
          <div className="main-menu">
            <a href={REDIRECT_PATH.WEDDINGS} className="menu-item menu-text">
              Why Poppy?
            </a>
            <a href={REDIRECT_PATH.REAL_WEDDINGS} className="menu-item menu-text">
              Real Weddings
            </a>
            <a href={REDIRECT_PATH.BlOG} className="menu-item menu-text">
              Blog
            </a>
            <a href={REDIRECT_PATH.ABOUT} className="menu-item menu-text">
              About
            </a>
          </div>
          <div className="actions">
            <Button className="button-stem">find my flowers</Button>
          </div>
        </div>

        <div className="main-menu-wrapper mobile">
          <div className="btn-menu mobile" onClick={() => setOpen((pre) => !pre)}>
            MENU
          </div>
        </div>
      </div>

      <div className={`poppy-header-overlay ${open ? '--show' : ''}`}>
        <div className="main-menu">
          <div className="menu-item">
            <a href={REDIRECT_PATH.WEDDINGS} className="menu-text">
              Why Poppy?
            </a>
          </div>
          <div className="menu-item">
            <a href={REDIRECT_PATH.REAL_WEDDINGS} className="menu-text">
              Real Weddings
            </a>
          </div>
          <div className="menu-item">
            <a href={REDIRECT_PATH.BlOG} className="menu-text">
              Blog
            </a>
          </div>
          <div className="menu-item">
            <a href={REDIRECT_PATH.ABOUT} className="menu-text">
              About
            </a>
          </div>
          <div className="menu-item">
            <Button className="button-stem">find my flowers</Button>
          </div>
        </div>
      </div>
    </header>
  )
}
