/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { PropsWithChildren } from 'react'
import Slider, { Settings } from 'react-slick'

import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import { NextArrowIcon, PrevArrowIcon } from 'src/common'

function SampleNextArrow({ className, onClick }: any) {
  return (
    <div className={className} onClick={onClick}>
      <NextArrowIcon />
    </div>
  )
}

function SamplePrevArrow({ className, onClick }: any) {
  return (
    <div className={className} onClick={onClick}>
      <PrevArrowIcon />
    </div>
  )
}

interface ICenterModeCarouselProps {
  className?: string
  onChange: (currentIndex: number) => void
}

export const CenterModeCarousel = (props: PropsWithChildren<ICenterModeCarouselProps>) => {
  const { onChange, children } = props

  const settings: Settings = {
    className: 'carousel-center',
    centerMode: true,
    centerPadding: '50px',
    adaptiveHeight: false,
    infinite: true,
    arrows: true,
    slidesToShow: 1,
    speed: 500,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    afterChange: onChange,
  }

  return <Slider {...settings}>{children}</Slider>
}
