import React from 'react'
import { useAppContext } from 'src/helpers/app'

import './storyImages.scss'

export const StoryImages: React.FC = () => {
  const { quizSession } = useAppContext()
  const colorPreference = quizSession?.customer.colorPreference || {}

  const colors = Object.keys(colorPreference)
    .filter((color) => colorPreference[color] > 0)
    .sort((a, b) => colorPreference[b] - colorPreference[a])
    .slice(0, 5)
  const inspirations = (quizSession?.inspirations || []).slice(0, 6)

  return (
    <div className="story-images-wrapper">
      <div className={`story-images max-item-${Math.max(colors.length, inspirations.length)}`}>
        {inspirations.map((ins, index) => (
          <div className={`item image-item --${index + 1}`} key={ins.id}>
            <img src={ins.imageUrl} className="image-cover" />
          </div>
        ))}
        {colors.map((color, index) => (
          <div
            key={color}
            style={{ background: color, transform: `scale(${colorPreference[color]})` }}
            className={`item color-item --${index + 1}`}
          />
        ))}
      </div>
    </div>
  )
}
