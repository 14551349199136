import React from 'react'
import { DayPickerText } from 'src/common/components/picker'
import { useWeddingDate } from './WeddingDate.hook'
import { QuizQuestionWrapper } from './QuizQuestionWrapper'
import { isMobile } from 'src/helpers'
import { Location } from './Location'
import { OutlinedTextField, Radio } from 'src/common/components'
import { QuizExperience, STAGES } from 'src/pages/shared'
import calendarIcon from 'src/assets/icons/calendar-icon.svg'
import { WeddingDetailTooltip } from './WeddingDetailTooltip'

const disabledDays = new Date()
disabledDays.setDate(disabledDays.getDate() + 1)

export const WeddingDetailsComponent = () => {
  const {
    weddingDateData,
    nextDisabled,
    location,
    formError,
    handleNext,
    handleBack,
    handleDayChangeBigDay,
    handleChangeInput,
    stage,
    handleChangeStage,
    loading,
    experiment,
  } = useWeddingDate()

  const { handleGGPlaceSelected, handleChange, locationSelected, hasValue: locationHasValue } = location

  const renderPartnerName = () => {
    if (experiment.includes(QuizExperience.LAST_NAME_HIDDEN))
      return (
        <OutlinedTextField
          wrapperClass="mb-10px"
          id="firstNameId"
          value={weddingDateData.partnerName ?? ''}
          type="text"
          name="partnerName"
          onChange={handleChangeInput}
          // onBlur={handleBlurInput}
          label="Your partner's name"
          isError={formError.partnerName}
          errorMsg="Your partner's name is required!"
        />
      )
    return (
      <>
        <OutlinedTextField
          wrapperClass="mb-10px"
          id="partnerFirstNameId"
          value={weddingDateData.partnerFirstName ?? ''}
          type="text"
          name="partnerFirstName"
          onChange={handleChangeInput}
          // onBlur={handleBlurInput}
          label="Partner’s first name"
          isError={formError.partnerFirstName}
          errorMsg="Partner first name is required!"
        />

        <OutlinedTextField
          wrapperClass="mb-10px"
          id="partnerLastNameId"
          value={weddingDateData.partnerLastName ?? ''}
          type="text"
          name="partnerLastName"
          onChange={handleChangeInput}
          // onBlur={handleBlurInput}
          label="Partner’s last name"
          isError={formError.partnerLastName}
          errorMsg="Partner last name is required!"
        />
      </>
    )
  }

  return (
    <>
      <QuizQuestionWrapper
        titleStep="Wedding Details"
        title="What are the wedding details?"
        subtitle="Let us know when and where you’re getting married so we can check our availability."
        nextAction={{
          disabled: nextDisabled,
          onAction: handleNext,
        }}
        backAction={{
          onAction: handleBack,
        }}
        customCss={{
          title: 'title-bold',
          content: '',
        }}
        step={2}
        total={5}
      >
        <div className="get-started-section mt-24px mb-30px">
          <div className="quiz-wedding_date">
            {renderPartnerName()}

            <div className="input-wrapper mb-10px">
              <div className="wedding-date-box">
                <div
                  className={`floating-label-wrapper wedding_date-date ${
                    weddingDateData?.weddingDate ? 'has-value' : ''
                  }`}
                >
                  <label className="label">Wedding date</label>
                  <DayPickerText
                    placeholder=""
                    inputProps={{
                      inputMode: 'none',
                      readOnly: isMobile,
                      // onBlur: handleBlurInput,
                      name: 'weddingDate',
                      className: `input w-100 ${formError.weddingDate ? 'border-red' : ''}`,
                      icon: <img src={calendarIcon} />,
                    }}
                    value={weddingDateData?.weddingDate}
                    onDayChange={handleDayChangeBigDay}
                    dayPickerProps={{
                      disabledDays: {
                        before: disabledDays,
                      },
                    }}
                  />
                </div>
                <WeddingDetailTooltip
                  title="Wedding Date Details"
                  text="We use your wedding date to find the right Poppy Designer for you!"
                />
              </div>
              {formError.weddingDate ? (
                <div className="error">Invalid wedding date!</div>
              ) : (
                <div className="error">&nbsp;</div>
              )}
            </div>

            <div className="input-wrapper mb-10px">
              <div className="wedding-location-box">
                <div className={`floating-label-wrapper ${locationHasValue ? 'has-value' : ''}`}>
                  <label className="label">Wedding location</label>
                  <Location
                    handleChange={handleChange}
                    locationSelected={locationSelected}
                    handleGGPlaceSelected={handleGGPlaceSelected}
                    inputProps={{ showLabel: false }}
                  />
                </div>
                <WeddingDetailTooltip
                  title="Wedding Location Details"
                  text="We use your wedding location to find the best Poppy Designer in your area!"
                />
              </div>
              {formError.location ? (
                <div className="error">Location is required!</div>
              ) : (
                <div className="error">&nbsp;</div>
              )}
            </div>
          </div>
          <div className="quiz-column-wrapper">
            <div className="quiz-guest-booked custom-wedding-planning mt-0px">
              <div className="quiz-question-header">Where are you at in your wedding planning process?</div>
              <div className="stage-wrapper">
                {STAGES.map((item) => (
                  <Radio
                    key={item.value}
                    id={item.value}
                    value={item.value}
                    name="planning"
                    classNames={{ container: 'ml-0px mr-16px mb-16px', label: 'basic' }}
                    checked={stage === item.value}
                    onChange={handleChangeStage}
                  >
                    {item.label}
                  </Radio>
                ))}
              </div>
            </div>
          </div>
          <div className="action-box-wrapper mt-12px pb-70px">
            <button
              disabled={nextDisabled || loading}
              className={`button ${loading ? 'loading' : ''}`}
              onClick={handleNext}
            >
              Confirm your date
            </button>
          </div>
        </div>
      </QuizQuestionWrapper>
    </>
  )
}
