import { useEffect, useRef, useState } from 'react'
import { IExperienceZapierPayload, postExperienceZapier } from 'src/api/zapier'

export enum EXPERIENCE_POPUP_TYPE {
  RATING = 'rating-popup',
  MESSAGES = 'message-popup',
  RESULT = 'result-popup',
}

export interface IPayloadExperience {
  star_rating: number
  message: string
}

export interface IUseExperiencePopupProps {
  triggerElmId: string
  templateId?: number
}

export const useExperiencePopup = (props: IUseExperiencePopupProps) => {
  const [popupType, setPopupType] = useState<EXPERIENCE_POPUP_TYPE>(EXPERIENCE_POPUP_TYPE.RATING)
  const [showExperiencePopup, setShowExperiencePopup] = useState<boolean>()
  const [payload, setPayload] = useState<IPayloadExperience>()

  const messageRef = useRef<HTMLTextAreaElement>(null)

  useEffect(() => {
    const handleScroll = () => {
      const triggerElement = document.getElementById(props.triggerElmId)

      let showFeedbackForm = false

      if (triggerElement) {
        // element is visible
        showFeedbackForm = window.innerHeight + window.scrollY >= triggerElement.offsetTop + triggerElement.offsetHeight
      } else {
        // the bottom
        showFeedbackForm = window.innerHeight + window.scrollY + 1 >= document.body.offsetHeight
      }

      if (showFeedbackForm) {
        // you're at the bottom of the page
        setShowExperiencePopup((value) => {
          // show only once
          if (value === undefined) {
            return true
          } else {
            return value
          }
        })
      }
    }

    document.addEventListener('scroll', handleScroll)

    return () => {
      document.removeEventListener('scroll', handleScroll)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleClosePopup = () => {
    setShowExperiencePopup(false)
  }

  const handleSelectRating = (value: number) => {
    if (value >= 4) {
      handleSubmit({
        star_rating: value,
        message: undefined,
        templateId: props.templateId,
      })
    } else {
      setPayload({ star_rating: value, message: '' })
      setPopupType(EXPERIENCE_POPUP_TYPE.MESSAGES)
    }
  }

  const handleSubmit = async (payloadInput?: IExperienceZapierPayload) => {
    // call api
    try {
      const bodyPayload = payloadInput ?? {
        star_rating: payload?.star_rating ?? 0,
        message: messageRef.current?.value ?? '',
        templateId: props.templateId,
      }

      postExperienceZapier(bodyPayload)
    } catch (error) {
      console.error('error', error)
    }

    setPopupType(EXPERIENCE_POPUP_TYPE.RESULT)

    setTimeout(() => {
      handleClosePopup()
    }, 2000)
  }

  return {
    showExperiencePopup,
    popupType,
    messageRef,
    handleSubmit,
    handleSelectRating,
    handleClosePopup,
  }
}
